import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import agent from '../../agent'

import { DELETE_PRODUCT } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  icon: {
    'color': 'rgba(255, 255, 255, 0.7)',
    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)', color: 'white' },
  },
}))

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (slug) =>
    dispatch({
      type: DELETE_PRODUCT,
      payload: agent.Products.delete(slug),
      snackbar: { message: 'Product deleted', variant: 'success' },
    }),
})

const DeleteProductAlert = ({ currentUser, product, onDelete }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    setOpen(false)
    onDelete(product.slug)
  }

  return (
    <>
      {currentUser.role === 'teacher' && (
        <ActionButton
          action='Delete product'
          onClick={handleClickOpen}
          className={classes.icon}
        >
          <Delete />
        </ActionButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(ev) => ev.stopPropagation()}
        onFocus={(ev) => ev.stopPropagation()}
      >
        <DialogTitle>Delete Product</DialogTitle>
        <DialogContent>
          <DialogContentText component='div'>
            Are you sure you want to delete <b>{product.name}</b>? This action{' '}
            <b>cannot be undone</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color='secondary'
            variant='contained'
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleClose} variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProductAlert)
