import {
  ADD_FINE,
  DELETE_FINE,
  LOAD_FINES,
  UNLOAD_FINES,
  UPDATE_FINE,
} from '../constants/actionTypes'

const defaultState = {
  fines: [],
  errors: null,
}

const sortByDescription = (a, b) => a.description.localeCompare(b.description)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_FINE:
      return {
        ...state,
        fines: action.error
          ? state.fines
          : [...state.fines, action.payload.fine].sort((a, b) =>
              sortByDescription(a, b)
            ),
        errors: action.error ? action.payload.errors : null,
      }
    case DELETE_FINE:
      return {
        ...state,
        fines: action.error
          ? state.fines
          : state.fines.filter((fine) => fine._id !== action.payload.fine._id),
        errors: action.error ? action.payload.errors : null,
      }
    case LOAD_FINES:
      return {
        ...state,
        fines: action.error ? state.fines : action.payload.fines,
        errors: action.error ? action.payload.errors : null,
      }
    case UNLOAD_FINES:
      return defaultState
    case UPDATE_FINE:
      return {
        ...state,
        fines: action.error
          ? state.fines
          : state.fines
              .map((fine) =>
                fine._id === action.payload.fine._id
                  ? action.payload.fine
                  : fine
              )
              .sort((a, b) => sortByDescription(a, b)),
        errors: action.error ? action.payload.errors : null,
      }
    default:
      return state
  }
}
