import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Dialog, Fab, makeStyles } from '@material-ui/core'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { numericFormatter } from 'react-number-format'

import Alert from '../Alert'
import FabBadge from '../FabBadge'
import DialogAppBar from '../DialogAppBar'
import ListErrors from '../ListErrors'
import PageHeader from '../PageHeader'
import PageWrapper from '../PageWrapper'
import Points from '../Points'
import PurchaseDetailsList from './PurchaseDetailsList'
import Spinner from '../Spinner'

import agent from '../../agent'
import emailer from '../../emailer'
import { convertGoogleLink } from '../../converter'
import { SlideUpTransition } from '../../transitions'

import {
  DELETE_CART_PRODUCT,
  STUDENT_CART_LOADED,
  STUDENT_CART_UNLOADED,
  SUBMIT_PURCHASE,
  UPDATE_CART_PRODUCT_QUANTITY,
} from '../../constants/actionTypes'
import { useGetInvoice } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  cartIcon: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  ...state.common.currentUser,
  ...state.common.settings,
  ...state.purchases,
  ...state.scores.total,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (productId) => dispatch({ type: DELETE_CART_PRODUCT, productId }),
  onLoad: (username) =>
    dispatch({
      type: STUDENT_CART_LOADED,
      payload: agent.Scores.getStudentScores(username),
    }),
  onSubmit: (purchase) =>
    dispatch({
      type: SUBMIT_PURCHASE,
      payload: agent.Purchases.add(purchase),
      snackbar: { message: 'Order submitted', variant: 'success' },
    }),
  onUnload: () => dispatch({ type: STUDENT_CART_UNLOADED }),
  onUpdate: (productId, qty) =>
    dispatch({ type: UPDATE_CART_PRODUCT_QUANTITY, productId, qty }),
})

const ShoppingCart = ({
  allowPurchasesWithInsufficientFunds,
  balance,
  cart,
  errors,
  firstName,
  hasPrime,
  inProgress,
  lastName,
  notifications,
  onDelete,
  onLoad,
  onSubmit,
  onUnload,
  onUpdate,
  prime,
  taxRate,
  username,
}) => {
  const [open, setOpen] = useState(false)
  const { invoice } = useGetInvoice(
    cart.products,
    cart.quantities,
    taxRate,
    prime.enabled && hasPrime ? prime.storePurchaseDiscount : 0
  )

  const classes = useStyles()

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const handleSubmitClick = () => {
    onSubmit({
      products: cart.products,
      quantities: cart.quantities,
      status: 'pending',
    })

    const getPoints = (value) => {
      return numericFormatter(Math.round(value).toString(), {
        suffix: ' pts',
        thousandSeparator: true,
      })
    }

    // Send an email notification to teacher account
    if (notifications.enabled.forStore) {
      const orderDetails = cart.products.map(
        (product, index) =>
          `<tr style="border-bottom:2px solid rgb(139,195,74);border-top: 2px solid rgb(139,195,74);"><td style="padding:8px 0;"><img width="100px" src="${convertGoogleLink(
            product.photoUrl
          )}" style="display:block;margin:0 auto;"></td><td style="text-align:left;">${
            product.name
          }</td><td>${cart.quantities[product._id]}</td><td>${getPoints(
            cart.quantities[product._id] * product.cost
          )}</td></tr>`
      )
      orderDetails.push(
        `<tr><td colspan="2" rowspan="${
          prime.enabled && hasPrime ? '4' : '3'
        }"></td><td style="padding:8px 0;text-align:left;">Subtotal</td><td style="text-align:right;">${getPoints(
          invoice.subtotal
        )}</td></tr>`
      )
      if (prime.enabled && hasPrime) {
        orderDetails.push(
          `<tr><td style="padding:8px 0;text-align:left;">Prime Discount</td><td style="text-align:right;">-${getPoints(
            invoice.prime
          )}</td></tr>`
        )
      }
      orderDetails.push(
        `<tr><td style="padding:8px 0;text-align:left;">Taxes & Fees</td><td style="text-align:right;">+${getPoints(
          invoice.taxes
        )}</td></tr>`,
        `<tr style="border-top:3px solid rgb(255,196,0);color:rgb(139,195,74);"><td style="padding:8px 0;text-align:left;"><b>Total</b></td><td style="text-align:right;"><b>${getPoints(
          invoice.total
        )}</b></td></tr>`
      )

      emailer(
        {
          student_name: `${firstName} ${lastName}`,
          email: notifications.email,
          order_details: orderDetails.join(''),
        },
        'store'
      )
    }

    handleClose()
  }

  const getTotalItemsInCart = Object.values(cart.quantities).reduce(
    (prev, cur) => prev + cur,
    0
  )

  const hasInsufficientFunds =
    !allowPurchasesWithInsufficientFunds && invoice.total > balance

  useEffect(() => {
    onLoad(username)

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <Fab
        color='primary'
        disabled={cart.products.length === 0}
        onClick={handleClickOpen}
        variant='extended'
      >
        <FabBadge value={getTotalItemsInCart}>
          <ShoppingCartIcon className={classes.cartIcon} />
          Checkout
        </FabBadge>
      </Fab>
      <Dialog
        fullScreen
        open={open}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        TransitionComponent={SlideUpTransition}
      >
        <DialogAppBar onClose={handleClose} title='Store' />
        <PageWrapper>
          <Container>
            <PageHeader title='Complete Your Order'>
              <Fab
                color='primary'
                disabled={cart.products.length === 0 || hasInsufficientFunds}
                onClick={handleSubmitClick}
                variant='extended'
              >
                <ShoppingBasketIcon className={classes.cartIcon} />
                Place Your Order
              </Fab>
            </PageHeader>
            <ListErrors errors={errors} />
            {(hasInsufficientFunds || invoice.total > balance) && (
              <Alert disableClose={true} severity='warning'>
                Insufficient funds! You have {<Points value={balance} />}
              </Alert>
            )}
            {inProgress ? (
              <Spinner message='Loading...' />
            ) : (
              <PurchaseDetailsList
                actions={{
                  delete: onDelete,
                  update: onUpdate,
                }}
                products={cart.products}
                quantities={cart.quantities}
              />
            )}
          </Container>
        </PageWrapper>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)
