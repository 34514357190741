import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Visibility } from '@material-ui/icons'

import CreatePlaylistDialog from './CreatePlaylistDialog'
import RemovePlaylistConfirmationDialog from './RemovePlaylistConfirmationDialog'

import { GET_PLAYLIST } from '../../constants/actionTypes'

import agent from '../../agent'

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
  },
  card: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: 140,
  },
  content: {
    display: 'flex',
  },
  controls: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  image: {
    height: 140,
    width: 140,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
  },
  tracks: {
    color: theme.palette.primary.main,
  },
}))

const mapStateToProps = (state) => ({
  playlists: state.jukebox.playlists,
  tokens: state.common.settings.spotifyTokens,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: (id, tokens) =>
    dispatch({
      type: GET_PLAYLIST,
      payload: agent.Spotify.getPlaylist(id, tokens),
    }),
})

const CoursePlaylistCard = ({
  course,
  onAddPlaylistClick,
  onLoad,
  onRemovePlaylistClick,
  playlists,
  tokens,
}) => {
  const classes = useStyles()

  const playlist = playlists.find((pl) => pl.id === course.playlistId)

  useEffect(() => {
    if (course.playlistId) {
      onLoad(course.playlistId, tokens)
    }
  }, [])

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Avatar className={classes.avatar}>{course.period}</Avatar>
          <div className={classes.info}>
            <Typography variant='h5' component='h2' className={classes.title}>
              {course.name}
            </Typography>
            {playlist ? (
              <Typography className={classes.tracks} variant='subtitle2'>
                {playlist.tracks.total} track
                {playlist.tracks.total === 1 ? '' : 's'}
              </Typography>
            ) : (
              <Typography className={classes.tracks} variant='subtitle2'>
                No playlist created
              </Typography>
            )}
          </div>
        </CardContent>
        <div className={classes.controls}>
          <CreatePlaylistDialog
            course={course}
            disabled={!!course.playlistId}
            onSubmit={(name) => onAddPlaylistClick(name, course)}
          />
          {playlist && (
            <Tooltip title='View playlist' arrow>
              <span>
                <IconButton
                  disabled={!course.playlistId}
                  href={playlist.external_urls.spotify}
                  target='_blank'
                >
                  <Visibility />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <RemovePlaylistConfirmationDialog
            disabled={!course.playlistId}
            onSubmit={() => onRemovePlaylistClick(course)}
          />
        </div>
      </div>
      {playlist && playlist.images && playlist.images.length > 0 && (
        <CardMedia
          className={classes.image}
          image={playlist.images[0].url}
          title={`Period ${course.period} playlist cover art`}
        />
      )}
    </Card>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursePlaylistCard)
