import React, { useState } from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Slider,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { Chart } from 'react-google-charts'
import { CirclePicker } from 'react-color'

const TaskDataChart = ({ course, scores, task }) => {
  const [bucketSize, setBucketSize] = useState(1)
  const [color, setColor] = useState('#4caf50')

  const getAverage = () =>
    Math.round(
      (scores.reduce((sum, score) => sum + score.value, 0) /
        scores.length /
        task.totalPoints || 1) * 100
    )

  const handleColorChange = (color) => {
    setColor(color.hex)
  }

  const handleSliderChange = (event, newValue) => {
    setBucketSize(newValue)
  }

  const data = [
    ['Student', 'Score'],
    ...scores.map((score) => [
      `${score.student.firstName} ${score.student.lastName}`,
      score.value,
    ]),
  ]

  const options = {
    title: `Period ${course.period} | ${task.title} | ${getAverage()}% avg.`,
    hAxis: { title: 'Score' },
    vAxis: { title: 'Number of Students', minorGridlines: { count: 0 } },
    legend: { position: 'none' },
    colors: [color],
    histogram: {
      bucketSize: bucketSize,
      minValue: 0,
      maxValue: task.totalPoints,
    },
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Chart
          chartType='Histogram'
          width='100%'
          height='400px'
          data={data}
          options={options}
        />
      </Grid>
      <Grid item xs={12}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography variant='h6'>Histogram Settings</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom>Bucket Size</Typography>
                <Slider
                  defaultValue={1}
                  step={1}
                  marks
                  min={1}
                  max={task.totalPoints}
                  onChange={handleSliderChange}
                  valueLabelDisplay='auto'
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>Bar Color</Typography>
                <CirclePicker color={color} onChange={handleColorChange} />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  )
}

export default TaskDataChart
