import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import MaterialTable from 'material-table'
import agent from '../../agent'

import {
  ADD_BONUS,
  DELETE_BONUS,
  UPDATE_BONUS,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.bonuses,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (bonus) =>
    dispatch({
      payload: agent.Bonuses.add(bonus),
      snackbar: { message: 'Bonus added', variant: 'success' },
      type: ADD_BONUS,
    }),
  onDelete: (bonus) =>
    dispatch({
      payload: agent.Bonuses.delete(bonus),
      snackbar: { message: 'Bonus deleted', variant: 'success' },
      type: DELETE_BONUS,
    }),
  onUpdate: (bonus) =>
    dispatch({
      payload: agent.Bonuses.update(bonus),
      snackbar: { message: 'Bonus updated', variant: 'success' },
      type: UPDATE_BONUS,
    }),
})

const ManageBonusesTable = ({ bonuses, onAdd, onDelete, onUpdate }) => {
  const [data, setData] = useState([])

  const [columns] = useState([
    { title: 'Description', field: 'description' },
    { title: 'Amount', field: 'amount' },
  ])

  useEffect(() => {
    setData(bonuses)
  }, [bonuses])

  return (
    <MaterialTable
      title='Bonuses'
      columns={columns}
      data={data}
      localization={{
        body: {
          emptyDataSourceMessage: 'No bonuses have been added',
          editRow: {
            deleteText: 'Delete this bonus?',
          },
        },
      }}
      options={{
        actionsColumnIndex: -1,
        addRowPosition: 'first',
        paging: false,
      }}
      editable={{
        onRowAdd: (bonus) =>
          new Promise((resolve) => {
            onAdd(bonus)
            resolve()
          }),
        onRowUpdate: (bonus) =>
          new Promise((resolve) => {
            onUpdate(bonus)
            resolve()
          }),
        onRowDelete: (bonus) =>
          new Promise((resolve) => {
            onDelete(bonus)
            resolve()
          }),
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBonusesTable)
