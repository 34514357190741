import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Paper, TextField, makeStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import agent from '../../agent'

import {
  STUDENT_SEARCH_LOADED,
  STUDENT_SEARCH_UNLOADED,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  search: {
    padding: theme.spacing(2),
  },
}))

const mapStateToProps = (state) => ({
  courses: state.courses.courses,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({ type: STUDENT_SEARCH_LOADED, payload: agent.Courses.getAll() }),
  onUnload: () => dispatch({ type: STUDENT_SEARCH_UNLOADED }),
})

const StudentSearch = ({ courses, onChange, onLoad, onUnload }) => {
  const [mappedData, setMappedData] = useState([])

  const classes = useStyles()

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  useEffect(() => {
    const mapped = courses
      .map((course) =>
        course.students.map((student) => ({
          ...student,
          course: course.name,
          period: course.period,
          name: `${student.firstName} ${student.lastName}`,
        }))
      )
      .flat()
    setMappedData(mapped)
  }, [courses])

  return (
    <Paper className={classes.search}>
      <Autocomplete
        autoHighlight
        getOptionLabel={(option) => option.name}
        groupBy={(option) => `Period ${option.period} | ${option.course}`}
        noOptionsText='No students have been added'
        onChange={(ev, value) => onChange(value)}
        options={mappedData}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Student'
            placeholder='Type or select a name'
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue)
          const parts = parse(option.name, matches)

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          )
        }}
      />
    </Paper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentSearch)
