import React from 'react'
import { NumericFormat } from 'react-number-format'

const Points = ({ prefix, suffix, value }) => (
  <NumericFormat
    displayType='text'
    prefix={prefix || ''}
    suffix={suffix || ' pts'}
    thousandSeparator={true}
    value={Math.round(value)}
  />
)

export default Points
