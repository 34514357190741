import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import ListErrors from '../ListErrors'
import ManageFinesTable from './ManageFinesTable'
import agent from '../../agent'

import { LOAD_FINES, UNLOAD_FINES } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& > .MuiDialog-container > .MuiPaper-root': {
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(100),
      },
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.fines,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: LOAD_FINES,
      payload: agent.Fines.getAll(),
    }),
  onUnload: () => dispatch({ type: UNLOAD_FINES }),
})

const ManageFinesDialog = ({ errors, isOpen, onClose, onLoad, onUnload }) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  // Allow for external component control of displaying the dialog
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Dialog
      className={classes.dialog}
      fullScreen={fullScreen}
      onClick={(ev) => ev.stopPropagation()}
      onClose={handleClose}
      onFocus={(ev) => ev.stopPropagation()}
      open={open}
    >
      <DialogTitle>Manage Fines</DialogTitle>
      <DialogContent>
        <ListErrors errors={errors} />
        <ManageFinesTable />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageFinesDialog)
