import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { Chip } from '@material-ui/core'

import Points from '../Points'
import PrimeBadge from '../Prime/PrimeBadge'

const EconomySummaryTable = ({ accounts, settings }) => {
  const [data, setData] = useState([])

  const mappedData = accounts.map((account) => ({
    accountTotal: account.totals.account,
    assessmentTotal: account.totals.assessments,
    bonusTotal: account.totals.bonuses,
    course: account.course,
    fineTotal: account.totals.fines,
    firstName: account.firstName,
    hasPrime: account.hasPrime,
    id: account.id,
    lastName: account.lastName,
    period: account.period,
    purchaseTotal: account.totals.purchases,
    songTotal: account.totals.songs,
    passTotal: account.totals.passes,
    jukeboxTotal: account.totals.jukebox,
  }))

  const [columns] = useState([
    { title: 'First Name', field: 'firstName' },
    { title: 'Last Name', field: 'lastName' },
    {
      title: 'Prime',
      field: 'hasPrime',
      hidden: !settings.prime.enabled,
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          rowData.hasPrime ? (
            <PrimeBadge />
          ) : (
            ''
          )
        ) : rowData ? (
          <PrimeBadge />
        ) : (
          'No Prime'
        ),
    },
    {
      title: 'Period',
      field: 'period',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Chip label={rowData.period} color='secondary' />
        ) : (
          <Chip label={rowData} color='secondary' />
        ),
    },
    {
      title: 'Account Total',
      field: 'accountTotal',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Points suffix=' ' value={rowData.accountTotal} />
        ) : (
          <Points value={rowData} />
        ),
    },
    {
      title: 'Hallpass Total',
      field: 'passTotal',
      hidden: !settings.hallpass.enabled,
    },
    {
      title: 'Song Total',
      field: 'songTotal',
      hidden: !settings.songRequestEnabled,
    },
    {
      title: 'Jukebox Total',
      field: 'jukeboxTotal',
      hidden: !settings.jukebox.enabled || !settings.enableBetaFeatures,
    },
    {
      title: 'Assessment Total',
      field: 'assessmentTotal',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Points suffix=' ' value={rowData.assessmentTotal} />
        ) : (
          <Points value={rowData} />
        ),
    },
    {
      title: 'Bonus Total',
      field: 'bonusTotal',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Points suffix=' ' value={rowData.bonusTotal} />
        ) : (
          <Points value={rowData} />
        ),
    },
    {
      title: 'Fine Total',
      field: 'fineTotal',
      hidden: !settings.fines.enabled,
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Points suffix=' ' value={rowData.fineTotal} />
        ) : (
          <Points value={rowData} />
        ),
    },
    {
      title: 'Purchase Total',
      field: 'purchaseTotal',
      hidden: !settings.storeEnabled,
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Points suffix=' ' value={rowData.purchaseTotal} />
        ) : (
          <Points value={rowData} />
        ),
    },
  ])

  useEffect(() => {
    setData(mappedData)
  }, [accounts])

  return (
    <MaterialTable
      columns={columns}
      data={data}
      options={{ grouping: true, paging: false, toolbar: false }}
      style={{ boxShadow: 'none' }}
      title='Account Summary'
    />
  )
}

export default EconomySummaryTable
