import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MaterialTable, { MTableAction } from 'material-table'
import { EditAttributes } from '@material-ui/icons'

import Points from '../Points'
import ProductDialog from './ProductDialog'
import agent from '../../agent'

import { DELETE_PRODUCT, UPDATE_PRODUCT } from '../../constants/actionTypes'

const mapDispatchToProps = (dispatch) => ({
  onDelete: (product) =>
    dispatch({
      type: DELETE_PRODUCT,
      payload: agent.Products.delete(product.slug),
      snackbar: { message: 'Product deleted', variant: 'success' },
    }),
  onUpdate: (product) =>
    dispatch({ type: UPDATE_PRODUCT, payload: agent.Products.update(product) }),
})

const ProductsTable = ({ onDelete, onUpdate, products }) => {
  const [data, setData] = useState([])

  const [columns] = useState([
    { title: 'Product Name', field: 'name' },
    {
      title: 'Cost',
      field: 'cost',
      render: (rowData) => <Points value={rowData.cost} />,
    },
    { title: 'Max Purchase Quantity', field: 'maxPurchaseQuantity' },
    { title: 'On-hand Quantity', field: 'quantity' },
  ])

  const handleInventoryChangeClick = (product, type) =>
    onUpdate({
      ...product,
      quantity: type === 'add' ? product.quantity + 1 : product.quantity - 1,
    })

  useEffect(() => {
    setData(products)
  }, [products])

  return (
    <MaterialTable
      actions={[
        {
          icon: 'add',
          onClick: (e, data) => handleInventoryChangeClick(data, 'add'),
          position: 'row',
          tooltip: 'Add inventory',
        },
        {
          icon: 'remove',
          onClick: (e, data) => handleInventoryChangeClick(data, 'remove'),
          position: 'row',
          tooltip: 'Remove inventory',
        },
        {
          component: 'ProductDialog',
          icon: 'edit',
          position: 'row',
          tooltip: 'Edit product',
        },
      ]}
      columns={columns}
      components={{
        Action: (props) => {
          if (props.action.component === 'ProductDialog')
            return <ProductDialog product={props.data} />
          return <MTableAction {...props} />
        },
      }}
      data={data}
      editable={{
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const newData = [...data]
            const index = oldData.tableData.id
            newData.splice(index, 1)
            setData([...newData])
            onDelete(oldData)
            resolve()
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data]
            const index = oldData.tableData.id
            dataUpdate[index] = { ...newData }
            setData([...dataUpdate])
            onUpdate(newData)
            resolve()
          }),
      }}
      icons={{ Edit: () => <EditAttributes /> }}
      localization={{
        body: {
          deleteTooltip: 'Delete product',
          editTooltip: 'Quick edit product',
          editRow: {
            deleteText: 'Delete product?',
            saveTooltip: 'Confirm',
          },
          emptyDataSourceMessage: 'No products to display',
        },
      }}
      options={{ actionsColumnIndex: -1, paging: false, showTitle: false }}
      style={{
        boxShadow: 'none',
        width: '100%',
      }}
    />
  )
}

export default connect(() => ({}), mapDispatchToProps)(ProductsTable)
