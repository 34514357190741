import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Container, Dialog, Grid, Typography } from '@material-ui/core'

import CoursePlaylistCard from './CoursePlaylistCard'
import DialogAppBar from '../DialogAppBar'
import ListErrors from '../ListErrors'
import PageHeader from '../PageHeader'
import PageWrapper from '../PageWrapper'
import { SlideUpTransition } from '../../transitions'

import agent from '../../agent'

import {
  CREATE_PLAYLIST,
  PLAYLIST_MANAGER_DIALOG_LOADED,
  PLAYLIST_MANAGER_DIALOG_UNLOADED,
  REMOVE_PLAYLIST,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  courses: state.courses.courses,
  errors: state.jukebox.errors,
  tokens: state.common.settings.spotifyTokens,
})

const mapDispatchToProps = (dispatch) => ({
  onCreatePlaylist: (name, slug, tokens) =>
    dispatch({
      type: CREATE_PLAYLIST,
      payload: agent.Spotify.createPlaylist(name, slug, tokens),
      snackbar: { message: 'Playlist created', variant: 'success' },
    }),
  onLoad: () =>
    dispatch({
      type: PLAYLIST_MANAGER_DIALOG_LOADED,
      payload: agent.Courses.getAll(),
    }),
  onRemovePlaylist: (slug) =>
    dispatch({
      type: REMOVE_PLAYLIST,
      payload: agent.Spotify.removePlaylist(slug),
      snackbar: { message: 'Playlist removed', variant: 'success' },
    }),
  onUnload: () => dispatch({ type: PLAYLIST_MANAGER_DIALOG_UNLOADED }),
})

const PlaylistManagerDialog = ({
  courses,
  errors,
  onCreatePlaylist,
  onLoad,
  onRemovePlaylist,
  onUnload,
  tokens,
}) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
    onLoad()
  }

  const handleClose = () => {
    setOpen(false)
    onUnload()
  }

  const handleAddPlaylistClick = (name, course) => {
    onCreatePlaylist(name, course.slug, tokens)
  }

  const handleRemovePlaylistClick = (course) => {
    onRemovePlaylist(course.slug)
  }

  return (
    <>
      <Button color='secondary' onClick={handleClickOpen} variant='outlined'>
        Manage Playlists
      </Button>

      <Dialog
        fullScreen
        open={open}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        TransitionComponent={SlideUpTransition}
      >
        <DialogAppBar onClose={handleClose} title='Jukebox' />
        <PageWrapper>
          <Container>
            <PageHeader title='Manage Playlists' />
            {errors && (
              <Grid item xs={12}>
                <ListErrors errors={errors} />
              </Grid>
            )}
            {!errors && (
              <Grid container spacing={2}>
                {courses.length > 0 ? (
                  courses.map((course) => (
                    <Grid item xs={12} md={6} key={course.slug}>
                      <CoursePlaylistCard
                        course={course}
                        onAddPlaylistClick={handleAddPlaylistClick}
                        onRemovePlaylistClick={handleRemovePlaylistClick}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography variant='body1'>You have no courses</Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </Container>
        </PageWrapper>
      </Dialog>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaylistManagerDialog)
