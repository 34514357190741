import React from 'react'
import { connect } from 'react-redux'

import AccountView from '../AccountView'

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
})

const StudentAccount = ({ currentUser }) => {
  return <AccountView student={currentUser} />
}

export default connect(mapStateToProps, () => ({}))(StudentAccount)
