import React from 'react'
import { Badge as MuiBadge, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  badge: {
    left: '-6%',
    top: '-30%',
  },
}))

const FabBadge = ({ children, value }) => {
  const classes = useStyles()

  return (
    <MuiBadge
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      badgeContent={value}
      classes={{ badge: classes.badge }}
      color='secondary'
      overlap='circle'
    >
      {children}
    </MuiBadge>
  )
}

export default FabBadge
