import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { Switch, TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import ListErrors from '../ListErrors'
import agent from '../../agent'

import {
  ADD_TEACHER,
  TEACHER_DIALOG_UNLOADED,
  UPDATE_TEACHER,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  ...state.teachers,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (user) =>
    dispatch({
      type: ADD_TEACHER,
      payload: agent.Auth.register(user),
      snackbar: { message: 'Teacher added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: TEACHER_DIALOG_UNLOADED }),
  onUpdate: (user) =>
    dispatch({
      type: UPDATE_TEACHER,
      payload: agent.Teachers.update(user),
      snackbar: { message: 'Teacher updated', variant: 'success' },
    }),
})

const TeacherDialog = ({
  errors,
  inProgress,
  onAdd,
  onExit,
  onUpdate,
  teacher,
}) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({
      ...values,
      permissions: { jukebox: values.permissionsJukebox },
      role: 'teacher',
    })
  }

  const handleSubmitUpdate = (values) => {
    onUpdate({
      ...teacher,
      ...values,
      permissions: { jukebox: values.permissionsJukebox },
    })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      {teacher ? (
        <ActionButton action='Update teacher' onClick={handleClickOpen}>
          <Edit />
        </ActionButton>
      ) : (
        <Fab color='primary' onClick={handleClickOpen} variant='extended'>
          <Add className={classes.icon} />
          Add teacher
        </Fab>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        onClick={(ev) => ev.stopPropagation()}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
      >
        <Formik
          initialValues={{
            firstName: teacher?.firstName || '',
            lastName: teacher?.lastName || '',
            username: teacher?.username || '',
            password: '',
            permissionsJukebox: teacher?.permissions?.jukebox || false,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            username: Yup.string().required('Required'),
            password: Yup.string().required(!teacher ? 'Required' : ''),
            permissionsJukebox: Yup.boolean(),
          })}
          onSubmit={async (values) => {
            teacher ? handleSubmitUpdate(values) : handleSubmitAdd(values)
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>
                {teacher ? 'Edit Teacher' : 'Add Teacher'}
              </DialogTitle>
              <DialogContent>
                <ListErrors errors={errors} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      autoFocus={teacher ? false : true}
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='First Name'
                      name='firstName'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Last Name'
                      name='lastName'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={!!teacher || inProgress}
                      label='Username'
                      name='username'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Password'
                      name='password'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label>
                      <Field
                        component={Switch}
                        disabled={inProgress}
                        label='Jukebox'
                        name='permissionsJukebox'
                        type='checkbox'
                      />
                      Jukebox is{' '}
                      {formik.values.permissionsJukebox
                        ? 'enabled'
                        : 'disabled'}
                    </label>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  disabled={inProgress}
                  type='submit'
                  variant='contained'
                >
                  {teacher
                    ? inProgress
                      ? 'Updating...'
                      : 'Update'
                    : inProgress
                    ? 'Adding...'
                    : 'Add'}
                </Button>
                <Button
                  disabled={inProgress}
                  onClick={handleClose}
                  variant='contained'
                >
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDialog)
