import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
} from '@material-ui/core'
import {
  LocalAtm,
  People,
  QueueMusic,
  Settings,
  Store,
  SupervisorAccount,
  Wc,
} from '@material-ui/icons'

import {
  NAV_TAB_CHANGE,
  NAV_LOADED,
  NAV_UNLOADED,
} from '../constants/actionTypes'

const useStyles = makeStyles({
  nav: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: '1000',
  },
})

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  navTabValue: state.nav.navTabValue,
  path: state.router.location.pathname,
  settings: state.common.settings,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: (tabValue, redirectTo) =>
    dispatch({ type: NAV_LOADED, value: tabValue, redirectTo: redirectTo }),
  onTabChange: (tabValue, redirectTo) =>
    dispatch({ type: NAV_TAB_CHANGE, value: tabValue, redirectTo: redirectTo }),
  onUnload: () => dispatch({ type: NAV_UNLOADED }),
})

const Navigation = (props) => {
  const classes = useStyles()
  const userRole = props.currentUser.role
  const enabled = {
    beta: props.settings.enableBetaFeatures,
    passes: props.settings.hallpass.enabled,
    products: props.settings.productsEnabled,
    songs: props.settings.songRequestEnabled,
    store: props.settings.storeEnabled,
  }

  const handleTabChange = (tabValue) => {
    props.onTabChange(tabValue, `/${userRole}/${tabValue}`)
  }

  useEffect(() => {
    const tabFromPath = props.path.split('/')[2]
    const tabValue =
      tabFromPath ??
      (userRole === 'student'
        ? 'account'
        : userRole === 'admin'
        ? 'teachers'
        : 'accounts')
    const redirectTo = `/${userRole}/${tabValue}`
    if (enabled.store && tabValue === 'products') {
      props.onLoad('store', redirectTo)
    } else {
      props.onLoad(tabValue, redirectTo)
    }

    return () => {
      props.onUnload()
    }
  }, [])

  if (userRole === 'student') {
    return (
      <BottomNavigation
        className={classes.nav}
        onChange={(event, newValue) => handleTabChange(newValue)}
        showLabels
        value={props.navTabValue}
      >
        <BottomNavigationAction
          label='Account'
          icon={<LocalAtm />}
          value='account'
        />
        {enabled.passes && (
          <BottomNavigationAction label='Passes' icon={<Wc />} value='passes' />
        )}
        {enabled.songs && (
          <BottomNavigationAction
            label='Songs'
            icon={<QueueMusic />}
            value='songs'
          />
        )}
        {(enabled.products || enabled.store) && (
          <BottomNavigationAction
            label={enabled.store ? 'Store' : 'Products'}
            icon={<Store />}
            value='store'
          />
        )}
        <BottomNavigationAction
          label='Settings'
          icon={<Settings />}
          value='settings'
        />
      </BottomNavigation>
    )
  }

  if (userRole === 'admin') {
    return (
      <BottomNavigation
        className={classes.nav}
        onChange={(event, newValue) => handleTabChange(newValue)}
        showLabels
        value={props.navTabValue}
      >
        <BottomNavigationAction
          label='Teachers'
          icon={<SupervisorAccount />}
          value='teachers'
        />
        <BottomNavigationAction
          label='Settings'
          icon={<Settings />}
          value='settings'
        />
      </BottomNavigation>
    )
  }

  return (
    <BottomNavigation
      className={classes.nav}
      onChange={(event, newValue) => handleTabChange(newValue)}
      showLabels
      value={props.navTabValue}
    >
      <BottomNavigationAction
        label='Accounts'
        icon={<LocalAtm />}
        value='accounts'
      />
      {enabled.songs && (
        <BottomNavigationAction
          label='Songs'
          icon={<QueueMusic />}
          value='songs'
        />
      )}
      {(enabled.products || enabled.store) && (
        <BottomNavigationAction
          label={enabled.store ? 'Store' : 'Products'}
          icon={<Store />}
          value={enabled.store ? 'store' : 'products'}
        />
      )}
      <BottomNavigationAction
        label='Courses'
        icon={<People />}
        value='courses'
      />
      <BottomNavigationAction
        label='Settings'
        icon={<Settings />}
        value='settings'
      />
    </BottomNavigation>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
