import React, { useEffect } from 'react'
import { Field, useField, useFormikContext } from 'formik'
import { Switch } from 'formik-material-ui'

// @todo: this component throws an unresolved warning: 'Warning: property type=checkbox is missing from field productsEnabled, this can caused unexpected behaviour'
const ControlledSwitch = ({ controlledBy, label, name, prime }) => {
  const { values, setFieldValue } = useFormikContext()
  const [field] = useField({ name, type: 'checkbox' })

  const getDisabled = () => {
    if (prime !== undefined) {
      return prime && values[controlledBy]
    }
    return values[controlledBy]
  }

  useEffect(() => {
    if (values[controlledBy] === true) {
      setFieldValue(name, true)
    }
  }, [values[controlledBy], setFieldValue, name, prime])

  return (
    <Field
      component={Switch}
      disabled={getDisabled()}
      label={label}
      {...field}
    />
  )
}

export default ControlledSwitch
