import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import { ExpandMore, GridOn, TableChart } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import PageHeader from '../PageHeader'
import PrimeBadge from '../Prime/PrimeBadge'
import PrimeDialog from '../Prime/PrimeDialog'
import ProductDialog from './ProductDialog'
import ProductsGallery from './ProductsGallery'
import ProductsTable from './ProductsTable'
import ShoppingCart from '../Purchases/ShoppingCart'
import Spinner from '../Spinner'
import StudentPurchasesDialog from '../Purchases/StudentPurchasesDialog'
import agent from '../../agent'

import {
  ADD_TO_CART,
  NAV_TAB_CHANGE,
  PRODUCTS_PAGE_LOADED,
  PRODUCTS_PAGE_UNLOADED,
  UPDATE_PRODUCT,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  settings: state.common.settings,
  ...state.common.currentUser,
  ...state.products,
  ...state.purchases,
})

const mapDispatchToProps = (dispatch) => ({
  onAddToCart: (product) => dispatch({ type: ADD_TO_CART, product }),
  onLoad: () =>
    dispatch({ type: PRODUCTS_PAGE_LOADED, payload: agent.Products.getAll() }),
  onPageChange: (value, redirectTo) =>
    dispatch({ type: NAV_TAB_CHANGE, value, redirectTo }),
  onUnload: () => dispatch({ type: PRODUCTS_PAGE_UNLOADED }),
  onUpdateInventory: (product) =>
    dispatch({
      type: UPDATE_PRODUCT,
      payload: agent.Products.update(product),
    }),
})

const Products = ({
  cart,
  onAddToCart,
  onLoad,
  onPageChange,
  onUnload,
  onUpdateInventory,
  pageLoaded,
  products,
  role,
  settings,
}) => {
  const [expanded, setExpanded] = useState('inStock')
  const [gridOn, setGridOn] = useState(false)

  const isTeacher = role === 'teacher'

  const inStockProducts = products.filter((product) => product.quantity > 0)

  const outOfStockProducts = products.filter((product) => product.quantity <= 0)

  const handlePageChange = () => onPageChange('store', `/${role}/store`)

  const handleAddToCart = (product) => onAddToCart(product)

  const handleInventoryChange = (product) => onUpdateInventory(product)

  const handleExpandedChange = (panel) => (ev, isExpanded) =>
    setExpanded(isExpanded ? panel : false)

  const handleViewClick = () => setGridOn(!gridOn)

  const primeMessage =
    'The banner below is visible to students at the top of this page when Prime is enabled'

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title={isTeacher ? 'Manage Products' : 'View Products'}>
        {isTeacher ? (
          <>
            <ActionButton
              action={`Switch to ${gridOn ? 'Table view' : 'Grid view'}`}
              onClick={handleViewClick}
            >
              {gridOn ? <TableChart /> : <GridOn />}
            </ActionButton>
            {settings.storeEnabled && (
              <Button onClick={handlePageChange} size='small'>
                Manage purchases
              </Button>
            )}
            <ProductDialog />
          </>
        ) : (
          settings.storeEnabled && (
            <>
              <PrimeDialog trigger='button' />
              <StudentPurchasesDialog />
              <ShoppingCart />
            </>
          )
        )}
      </PageHeader>
      {role === 'student' && <PrimeDialog trigger='banner' />}
      {pageLoaded ? (
        isTeacher ? (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <ExpansionPanel
                expanded={expanded === 'inStock'}
                onChange={handleExpandedChange('inStock')}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                  <Typography variant='h5' gutterBottom>
                    In Stock
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {gridOn ? (
                    <ProductsGallery
                      onInventoryChange={handleInventoryChange}
                      products={inStockProducts}
                      userRole={role}
                    />
                  ) : (
                    <ProductsTable products={inStockProducts} />
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                expanded={expanded === 'outOfStock'}
                onChange={handleExpandedChange('outOfStock')}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                  <Typography variant='h5' gutterBottom>
                    Out of Stock
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {gridOn ? (
                    <ProductsGallery
                      onInventoryChange={handleInventoryChange}
                      products={outOfStockProducts}
                      userRole={role}
                    />
                  ) : (
                    <ProductsTable products={outOfStockProducts} />
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            {settings.prime.enabled && (
              <Grid item xs={12}>
                <Typography variant='h5' gutterBottom>
                  Prime <PrimeBadge title={primeMessage} />
                </Typography>
                <PrimeDialog trigger='banner' />
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <ProductsGallery
                cart={cart}
                products={inStockProducts}
                onAddToCart={handleAddToCart}
                storeEnabled={settings.storeEnabled}
                userRole={role}
              />
            </Grid>
          </Grid>
        )
      ) : (
        <Spinner message='Loading...' />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Products)
