import React, { useState } from 'react'
import {
  Divider,
  Fab,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import {
  ConfirmationNumber,
  Gavel,
  Grade,
  LocalActivity,
  Money,
  Publish,
  ShoppingCart,
  Wc,
} from '@material-ui/icons'

import BulkTransactionsDialog from './BulkTransactionsDialog'
import ImportScoresDialog from './ImportScoresDialog'
import ManageBonusesDialog from './ManageBonusesDialog'
import ManageFinesDialog from '../Fines/ManageFinesDialog'
import ManagePassesDialog from '../Passes/ManagePassesDialog'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const UpdateAccountsMenu = ({ curStudent, settings }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [menuSelection, setMenuSelection] = useState(null)

  const classes = useStyles()

  const handleMenuOpen = (ev) => {
    setMenuAnchorEl(ev.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleMenuSelect = (ev) => {
    setMenuSelection(ev.currentTarget.dataset.type)
    setMenuAnchorEl(null)
  }

  const handleDialogClose = () => {
    setMenuSelection(null)
  }

  return (
    <>
      <Fab color='primary' onClick={handleMenuOpen} variant='extended'>
        <Money className={classes.icon} />
        Update Accounts
      </Fab>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem data-type='scores' onClick={handleMenuSelect}>
          <ListItemIcon>
            <Publish />
          </ListItemIcon>
          <ListItemText>Import scores</ListItemText>
        </MenuItem>
        <MenuItem data-type='bonuses' onClick={handleMenuSelect}>
          <ListItemIcon>
            <Grade />
          </ListItemIcon>
          <ListItemText>Add Bonuses</ListItemText>
        </MenuItem>
        {settings.fines.enabled && (
          <MenuItem data-type='fines' onClick={handleMenuSelect}>
            <ListItemIcon>
              <Gavel />
            </ListItemIcon>
            <ListItemText>Add Fines</ListItemText>
          </MenuItem>
        )}
        <MenuItem data-type='purchases' onClick={handleMenuSelect}>
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText>Add Purchases</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem data-type='manageBonuses' onClick={handleMenuSelect}>
          <ListItemIcon>
            <LocalActivity />
          </ListItemIcon>
          <ListItemText>Manage Bonuses</ListItemText>
        </MenuItem>
        {settings.fines.enabled && (
          <MenuItem data-type='manageFines' onClick={handleMenuSelect}>
            <ListItemIcon>
              <ConfirmationNumber />
            </ListItemIcon>
            <ListItemText>Manage Fines</ListItemText>
          </MenuItem>
        )}
        {settings.hallpass.enabled && (
          <MenuItem data-type='managePasses' onClick={handleMenuSelect}>
            <ListItemIcon>
              <Wc />
            </ListItemIcon>
            <ListItemText>Manage Hallpasses</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <ImportScoresDialog
        curStudent={curStudent}
        isClosed={handleDialogClose}
        isOpen={menuSelection === 'scores'}
      />
      <BulkTransactionsDialog
        curStudent={curStudent}
        isClosed={handleDialogClose}
        isOpen={menuSelection === 'bonuses'}
        type='Bonuses'
      />
      <BulkTransactionsDialog
        curStudent={curStudent}
        isClosed={handleDialogClose}
        isOpen={menuSelection === 'fines'}
        type='Fines'
      />
      <BulkTransactionsDialog
        curStudent={curStudent}
        isClosed={handleDialogClose}
        isOpen={menuSelection === 'purchases'}
        type='Purchases'
      />
      <ManageBonusesDialog
        isOpen={menuSelection === 'manageBonuses'}
        onClose={handleDialogClose}
      />
      <ManageFinesDialog
        isOpen={menuSelection === 'manageFines'}
        onClose={handleDialogClose}
      />
      <ManagePassesDialog
        isOpen={menuSelection === 'managePasses'}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default UpdateAccountsMenu
