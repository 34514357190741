import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import agent from '../../agent'

import { DELETE_STUDENT } from '../../constants/actionTypes'

const mapDispatchToProps = (dispatch) => ({
  onDelete: (user) =>
    dispatch({
      type: DELETE_STUDENT,
      payload: agent.Students.delete(user),
      snackbar: { message: 'Student deleted', variant: 'success' },
    }),
})

const DeleteStudentAlert = ({ course, onDelete, student }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    setOpen(false)
    onDelete(student)
  }

  return (
    <>
      <ActionButton
        action='Delete student'
        onClick={handleClickOpen}
        size='small'
      >
        <Delete fontSize='inherit' />
      </ActionButton>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(ev) => ev.stopPropagation()}
        onFocus={(ev) => ev.stopPropagation()}
      >
        <DialogTitle>Delete Student</DialogTitle>
        <DialogContent>
          <DialogContentText component='div'>
            Are you sure you want to delete{' '}
            <b>
              {student.firstName} {student.lastName}
            </b>
            ? This action will permanently delete:
            <ul>
              <li>the student's account, and</li>
              <li>all song requests</li>
              <li>all transactions</li>
              <li>all hallpasses</li>
              <li>all purchases</li>
              <li>all scores</li>
            </ul>
            associated with this student. This action <b>cannot be undone</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color='secondary'
            variant='contained'
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleClose} variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(() => ({}), mapDispatchToProps)(DeleteStudentAlert)
