import React from 'react'
import { Chip, Typography, makeStyles } from '@material-ui/core'

import DeleteStudentAlert from './DeleteStudentAlert'
import DroppedChip from '../DroppedChip'
import PrimeBadge from '../Prime/PrimeBadge'
import ResetStudentPasswordAlert from './ResetStudentPasswordAlert'
import StudentDialog from './StudentDialog'
import StudentTransferDialog from './StudentTransferDialog'

const useStyles = makeStyles((theme) => ({
  row: {
    'alignItems': 'center',
    'display': 'flex',
    'height': theme.spacing(6),
    'justifyContent': 'space-between',
    'padding': theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  rowHeader: {
    alignItems: 'center',
    display: 'flex',
  },
  studentUsername: {
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(12),
  },
}))

const StudentListRow = ({ course, student }) => {
  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div className={classes.rowHeader}>
        <Chip
          color='primary'
          label={student.username}
          className={classes.studentUsername}
        />
        <Typography>
          {student.lastName}, {student.firstName}
        </Typography>
        {student.hasPrime && <PrimeBadge />}
        {student.dropped && <DroppedChip />}
      </div>
      <div>
        <ResetStudentPasswordAlert student={student} />
        <StudentTransferDialog course={course} student={student} />
        <StudentDialog course={course} student={student} />
        <DeleteStudentAlert course={course} student={student} />
      </div>
    </div>
  )
}

const CourseStudentList = ({ course }) => {
  return (
    <>
      <Typography variant='h6'>Students</Typography>{' '}
      {course.students && course.students.length > 0 ? (
        course.students.map((student) => {
          return (
            <StudentListRow
              key={student._id}
              course={course}
              student={student}
            />
          )
        })
      ) : (
        <div>
          <Typography>No students have been added to this course</Typography>
        </div>
      )}
    </>
  )
}

export default CourseStudentList
