import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  alert: {
    'width': '100%',
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(1),
    },
  },
  alertList: {
    marginBottom: theme.spacing(2),
  },
}))

const ListErrors = ({ errors }) => {
  const classes = useStyles()

  if (errors) {
    if (typeof errors === 'object') {
      return (
        <div className={classes.alertList}>
          {Object.keys(errors).map((key) => {
            const readableKey = key
              .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
              .toLowerCase()
            return (
              <Alert className={classes.alert} severity='error' key={key}>
                {readableKey} {errors[key]}
              </Alert>
            )
          })}
        </div>
      )
    } else if (typeof errors === 'string') {
      return (
        <div className={classes.alertList}>
          <Alert className={classes.alert} severity='error'>
            {errors}
          </Alert>
        </div>
      )
    }
  }

  return null
}

export default ListErrors
