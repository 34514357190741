import {
  ASYNC_START,
  DELETE_TASK,
  LOAD_TASK_SCORES,
  TASKS_DIALOG_LOADED,
  TASKS_DIALOG_UNLOADED,
  UNLOAD_TASK_SCORES,
  UPDATE_TASK,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  inProgress: false,
  scores: [],
  tasks: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        action.subtype === TASKS_DIALOG_LOADED ||
        action.subtype === LOAD_TASK_SCORES
      ) {
        return { ...state, inProgress: true }
      }
      break
    case DELETE_TASK:
      return {
        ...state,
        tasks: action.error
          ? state.tasks
          : state.tasks.filter(
              (task) => task.slug !== action.payload.task.slug
            ),
      }
    case LOAD_TASK_SCORES:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        scores: action.error ? [] : action.payload.scores,
      }
    case TASKS_DIALOG_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        tasks: action.error ? [] : action.payload.tasks,
      }
    case TASKS_DIALOG_UNLOADED:
      return defaultState
    case UNLOAD_TASK_SCORES:
      return { ...state, scores: [] }
    case UPDATE_TASK:
      return {
        ...state,
        tasks: action.error
          ? state.tasks
          : state.tasks.map((task) =>
              task.slug === action.payload.task.slug
                ? action.payload.task
                : task
            ),
      }
    default:
      return state
  }

  return state
}
