import React from 'react'
import {
  Card,
  InputAdornment,
  IconButton,
  OutlinedInput,
  makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  card: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  input: {
    textAlign: 'center',
  },
}))

const ProductsToolbar = ({ onChange, value }) => {
  const classes = useStyles()

  const handleChange = (ev) => {
    onChange(ev.target.value)
  }

  const handleClearClick = () => {
    onChange('')
  }

  return (
    <Card className={classes.card}>
      <OutlinedInput
        classes={{ input: classes.input }}
        color='secondary'
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='clear search text'
              onClick={handleClearClick}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        }
        onChange={handleChange}
        placeholder='Search for products'
        value={value}
        variant='outlined'
        fullWidth
      />
    </Card>
  )
}

export default ProductsToolbar
