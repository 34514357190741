import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  Fab,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { QueueMusic } from '@material-ui/icons'

import ListErrors from '../ListErrors'
import agent from '../../agent'
import emailer from '../../emailer'
import background from './../../images/songs-background.jpg'

import { SUBMIT_SONG_REQUEST } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dialog: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center bottom',
    backgroundSize: 'cover',
    color: 'white',
    padding: theme.spacing(4),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  input: {
    'backgroundColor': 'rgba(0,65,91,0.8)',
    'borderRadius': theme.spacing(1),
    'height': 78,
    '& input': {
      color: theme.palette.secondary.main,
    },
  },
  inputLabel: {
    color: 'white',
  },
}))

const mapStateToProps = (state) => ({
  ...state.songs,
  currentUser: state.common.currentUser,
  settings: state.common.settings,
})

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (song) =>
    dispatch({
      type: SUBMIT_SONG_REQUEST,
      payload: agent.Songs.request(song),
      snackbar: { message: 'Song requested', variant: 'success' },
    }),
})

const SongRequestDialog = ({
  courseSongs,
  currentUser,
  onSubmit,
  settings,
}) => {
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setError(null)
    setOpen(false)
  }

  const handleSubmit = (values) => {
    setError(null)

    if (settings.songs.preventDuplicateSong) {
      const duplicates = courseSongs.filter(
        (song) =>
          song.artist.toLowerCase() === values.artist.toLowerCase() &&
          song.title.toLowerCase() === values.title.toLowerCase()
      )
      if (duplicates.length > 0) {
        setError('This song has already been requested')
      } else {
        onSubmit(values)
        setOpen(false)
      }
    } else {
      onSubmit(values)
      setOpen(false)
    }

    if (settings.notifications.enabled.forSongs) {
      emailer(
        {
          student_name: `${currentUser.firstName} ${currentUser.lastName}`,
          email: settings.notifications.email,
          song_title: values.title,
          song_artist: values.artist,
        },
        'song'
      )
    }
  }

  const getSongRequestPurchaseAmount = () =>
    settings.prime.enabled && currentUser.hasPrime
      ? (1 - settings.prime.songRequestPurchaseDiscount / 100) *
        settings.songRequestPurchaseAmount
      : settings.songRequestPurchaseAmount

  return (
    <>
      <Fab color='primary' onClick={handleClickOpen} variant='extended'>
        <QueueMusic className={classes.icon} />
        Buy a song
      </Fab>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <Typography variant='h3' align='center' color='secondary' gutterBottom>
          Purchase a Song
        </Typography>
        <ListErrors errors={error} />
        <Typography variant='body1' gutterBottom>
          Please provide the song and artist title for your request. If
          approved, {getSongRequestPurchaseAmount()} points will be charged to
          your account.
        </Typography>
        <Formik
          initialValues={{
            artist: '',
            title: '',
          }}
          validationSchema={Yup.object({
            artist: Yup.string().required('Required'),
            title: Yup.string().required('Required'),
          })}
          onSubmit={async (values) => {
            handleSubmit(values)
          }}
        >
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Field
                  autoFocus
                  className={classes.input}
                  color='primary'
                  component={TextField}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                    },
                  }}
                  label='Song Title'
                  name='title'
                  type='text'
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  className={classes.input}
                  color='primary'
                  component={TextField}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                    },
                  }}
                  label='Artist'
                  name='artist'
                  type='text'
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  color='primary'
                  type='submit'
                  variant='contained'
                >
                  Buy
                </Button>
                <Button
                  className={classes.button}
                  onClick={handleClose}
                  variant='contained'
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SongRequestDialog)
