import React from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleHalfStroke,
  faFlask,
  faMoneyBillTrendUp,
  faMoneyBillWave,
  faMusic,
  faTag,
  faTicket,
  faTruck,
  faVolumeXmark,
} from '@fortawesome/free-solid-svg-icons'
import { faTurntable } from '@fortawesome/pro-solid-svg-icons'

import BigButton from '../BigButton'
import Logo from '../Logo'
import Points from '../Points'
import primeDarkModeAd from '../../images/prime-darkmode.png'
import primeJukeboxAd from '../../images/prime-jukebox.png'
import primeSameDayAd from '../../images/prime-sameday.png'
import primeSongRemovalAd from '../../images/prime-songremoval.png'
import primeSongsAd from '../../images/prime-songs.png'
import primeStoreAd from '../../images/prime-purchases.png'

const getGradient = (angle, color1, color2) =>
  `linear-gradient(${angle}, ${color1} 0%, ${color2} 100%)`

const useStyles = makeStyles((theme) => ({
  cost: {
    border: `8px solid ${theme.palette.common.white}`,
    borderRadius: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  list: {
    'marginBottom': 0,
    'marginTop': -theme.spacing(1),
    '& li': {
      marginTop: theme.spacing(2),
    },
  },
  logo: {
    'display': 'flex',
    'justifyContent': 'center',
    '& > img': {
      width: '75%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  },
  padLeft: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: `${theme.spacing(4)}px !important`,
    },
  },
  padRight: {
    [theme.breakpoints.up('md')]: {
      paddingRight: `${theme.spacing(4)}px !important`,
    },
  },
  section: {
    'color': theme.palette.common.white,
    'fontFamily': '"Carter One", serif',
    'overflow': 'hidden',
    'padding': `${theme.spacing(6)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
    '& h1': {
      fontSize: theme.typography.h2.fontSize,
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.h1.fontSize,
      },
    },
    '& h2': {
      fontSize: theme.typography.h3.fontSize,
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.h2.fontSize,
      },
    },
    '& h4': {
      fontSize: theme.typography.h5.fontSize,
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.h4.fontSize,
      },
    },
    '& svg': {
      display: 'block',
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        display: 'inline-block',
      },
    },
  },
  sectionImage: {
    width: '100%',
  },
  songRemovalAd: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: '-140px',
      top: '15px',
      width: '120%',
    },
  },
  songsAd: {
    [theme.breakpoints.up('md')]: {
      left: '-100px',
      position: 'absolute',
      top: '-10px',
      width: '120%',
    },
  },
  storeAd: {
    [theme.breakpoints.up('md')]: {
      left: '-50px',
      position: 'absolute',
      top: '-30px',
      width: '110%',
    },
  },
  text: {
    fontFamily: '"Carter One", serif',
  },
}))

const PrimeSignup = ({ balance, enabled, onSubmit, prime }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  return (
    <>
      <Grid
        container
        alignItems='center'
        className={classes.section}
        spacing={2}
        style={{
          background: getGradient(
            '90deg',
            theme.palette.primary.main,
            theme.palette.secondary.main
          ),
        }}
      >
        <Grid item className={classes.logo} xs={12} md={4}>
          <Logo prime={true} />
        </Grid>
        {enabled.any && (
          <Grid item xs={12} md={8}>
            <Typography className={classes.text} color='inherit' variant='h5'>
              With a Chem Cash Prime membership you get...
              <ul className={`fa-ul ${classes.list}`}>
                {enabled.darkMode && (
                  <li>
                    <FontAwesomeIcon icon={faCircleHalfStroke} listItem />
                    access to dark mode
                  </li>
                )}
                {enabled.discounts.songs && (
                  <li>
                    <FontAwesomeIcon icon={faMusic} listItem />
                    discounts on songs
                  </li>
                )}
                {enabled.songRemoval && (
                  <li>
                    <FontAwesomeIcon icon={faVolumeXmark} listItem />
                    ability to remove songs
                  </li>
                )}
                {enabled.jukebox && (
                  <li>
                    <FontAwesomeIcon icon={faTurntable} listItem />
                    access to Jukebox to play your songs now
                  </li>
                )}
                {enabled.discounts.store && (
                  <li>
                    <FontAwesomeIcon icon={faTag} listItem />
                    discounts on store purchases
                  </li>
                )}
                {enabled.sameDay && (
                  <li>
                    <FontAwesomeIcon icon={faTruck} listItem />
                    same-day pickup for online purchases
                  </li>
                )}
                {enabled.discounts.hallpass && (
                  <li>
                    <FontAwesomeIcon icon={faTicket} listItem />
                    discounts on hallpasses
                  </li>
                )}
              </ul>
            </Typography>
          </Grid>
        )}
      </Grid>

      {enabled.darkMode && (
        <Grid
          container
          className={classes.section}
          spacing={2}
          style={{ backgroundColor: theme.palette.common.black }}
        >
          <Grid item xs={12} md={8} className={classes.padRight}>
            <Typography align='center' variant='h1' gutterBottom>
              <FontAwesomeIcon icon={faCircleHalfStroke} /> Dark Mode
            </Typography>
            <Typography align='right' variant='h2' gutterBottom>
              Someone turn on a light, it's dark in here
            </Typography>
            <Typography align='right' variant='h4'>
              As a Prime member <br />
              you get access to the <br />
              dark mode toggle <br />
              on the settings page
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <img className={classes.sectionImage} src={primeDarkModeAd} />
          </Grid>
        </Grid>
      )}

      {enabled.discounts.songs && (
        <Grid
          container
          className={classes.section}
          spacing={2}
          style={{
            background: getGradient(
              '180deg',
              theme.palette.primary.dark,
              theme.palette.primary.light
            ),
          }}
        >
          <Grid item xs={12}>
            <Typography align='center' variant='h1' gutterBottom>
              <FontAwesomeIcon icon={faMusic} /> Song Discounts
            </Typography>
          </Grid>
          <Grid
            container
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={2}
            wrap='nowrap'
          >
            <Grid item xs={12} md={7} style={{ position: 'relative' }}>
              <img
                className={`${classes.sectionImage} ${classes.songsAd}`}
                src={primeSongsAd}
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.padLeft}>
              <Typography align='left' variant='h2' gutterBottom>
                Hear more of the music you love
              </Typography>
              <Typography align='left' variant='h4'>
                With discounts on all song purchases, you can buy more of the
                music you enjoy
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {enabled.songRemoval && (
        <Grid
          container
          className={classes.section}
          spacing={2}
          style={{
            background: getGradient(
              '180deg',
              theme.palette.error.dark,
              theme.palette.error.light
            ),
          }}
        >
          <Grid item xs={12}>
            <Typography align='center' variant='h1' gutterBottom>
              <FontAwesomeIcon icon={faVolumeXmark} /> Remove Songs
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.padLeft}>
            <Typography align='right' variant='h2' gutterBottom>
              Hear less music you don't like
            </Typography>
            <Typography align='right' variant='h4'>
              Prime members have the ability to remove any songs and earn bonus
              points if their songs get removed
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} style={{ position: 'relative' }}>
            <img
              className={`${classes.sectionImage} ${classes.songRemovalAd}`}
              src={primeSongRemovalAd}
            />
          </Grid>
        </Grid>
      )}

      {enabled.jukebox && (
        <Grid
          container
          className={classes.section}
          spacing={2}
          style={{
            background: getGradient(
              '180deg',
              theme.palette.grey[700],
              theme.palette.grey[500]
            ),
          }}
        >
          <Grid item xs={12}>
            <Typography align='center' variant='h1' gutterBottom>
              <FontAwesomeIcon icon={faTurntable} /> Jukebox
            </Typography>
          </Grid>
          <Grid
            container
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={2}
            wrap='nowrap'
          >
            <Grid item xs={12} md={7} style={{ position: 'relative' }}>
              <img
                className={`${classes.sectionImage} ${classes.songsAd}`}
                src={primeJukeboxAd}
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.padLeft}>
              <Typography align='left' variant='h2' gutterBottom>
                Queue your song to play next
              </Typography>
              <Typography align='left' variant='h4'>
                Get acces to Jukebox, which allows you to preview songs on the
                class playlist and add songs to the queue to play next
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {enabled.discounts.store && (
        <Grid
          container
          className={classes.section}
          spacing={2}
          style={{
            background: getGradient(
              '180deg',
              theme.palette.secondary.dark,
              theme.palette.secondary.light
            ),
          }}
        >
          <Grid item xs={12}>
            <Typography align='center' variant='h1' gutterBottom>
              <FontAwesomeIcon icon={faTag} /> Store Discounts
            </Typography>
          </Grid>
          <Grid
            container
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={2}
            wrap='nowrap'
          >
            <Grid item xs={12} md={7} style={{ position: 'relative' }}>
              <img
                className={`${classes.sectionImage} ${classes.storeAd}`}
                src={primeStoreAd}
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.padLeft}>
              <Typography align='left' variant='h2' gutterBottom>
                Get more,
                <br />
                spend less
              </Typography>
              <Typography align='left' variant='h4'>
                With discounts on all store purchases, you can buy more of the
                stuff you want
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {enabled.sameDay && (
        <Grid
          container
          className={classes.section}
          spacing={2}
          style={{
            background: getGradient(
              '180deg',
              theme.palette.info.dark,
              theme.palette.info.light
            ),
          }}
        >
          <Grid item xs={12}>
            <Typography align='center' variant='h1' gutterBottom>
              <FontAwesomeIcon icon={faTruck} /> Same-Day Pickup
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} className={classes.padLeft}>
            <Typography align='right' variant='h2' gutterBottom>
              Get your stuff...
              <br />
              now
            </Typography>
            <Typography align='right' variant='h4'>
              Only Prime members can pickup their purchases the same day they
              ordered them, while non-members will have to wait until the end of
              the week
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <img className={classes.sectionImage} src={primeSameDayAd} />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        className={classes.section}
        spacing={2}
        style={{
          background: getGradient(
            '90deg',
            theme.palette.secondary.main,
            theme.palette.primary.main
          ),
        }}
      >
        <Grid item xs={12}>
          <Typography align='center' variant='h1' gutterBottom>
            <FontAwesomeIcon icon={faFlask} /> Join Today
          </Typography>
        </Grid>
        {enabled.any && (
          <Grid item xs={12} md={6}>
            <Typography align='center' variant='h5'>
              Full Membership Benefits
            </Typography>
            <Typography color='inherit' variant='h6'>
              <ul className='fa-ul'>
                {enabled.darkMode && (
                  <li>
                    <FontAwesomeIcon icon={faCircleHalfStroke} listItem />
                    access to both light and dark modes
                  </li>
                )}
                {enabled.discounts.songs && (
                  <li>
                    <FontAwesomeIcon icon={faMusic} listItem />a{' '}
                    {prime.songRequestPurchaseDiscount}% discount on song
                    requests
                  </li>
                )}
                {enabled.songRemoval && (
                  <li>
                    <FontAwesomeIcon icon={faVolumeXmark} listItem />
                    ability to remove any songs from the class playlist for a
                    fee
                  </li>
                )}
                {enabled.songRemoval && (
                  <li>
                    <FontAwesomeIcon icon={faMoneyBillTrendUp} listItem />
                    earn bonus points when one of your songs is removed
                  </li>
                )}
                {enabled.jukebox && (
                  <li>
                    <FontAwesomeIcon icon={faTurntable} listItem />
                    access to Jukebox to preview songs on the class playlist,
                    plus add songs to the queue to play next for a fee
                  </li>
                )}
                {enabled.discounts.store && (
                  <li>
                    <FontAwesomeIcon icon={faTag} listItem />a{' '}
                    {prime.storePurchaseDiscount}% discount on all store
                    purchases
                  </li>
                )}
                {enabled.sameDay && (
                  <li>
                    <FontAwesomeIcon icon={faTruck} listItem />
                    same-day pickup for online purchases rather than at the end
                    of the week
                  </li>
                )}
                {enabled.discounts.hallpass && (
                  <li>
                    <FontAwesomeIcon icon={faTicket} listItem />a{' '}
                    {prime.hallpassDiscount}% discount on all hallpass purchases
                  </li>
                )}
                <li>
                  <FontAwesomeIcon icon={faMoneyBillWave} listItem />
                  one-time fee for the whole school year
                </li>
              </ul>
            </Typography>
          </Grid>
        )}
        <Grid container item alignItems='center' xs={12} md={6}>
          <Grid item xs={12} className={classes.cost}>
            <Typography align='center' variant='h4'>
              Cost
            </Typography>
            <Typography align='center' className={classes.text} variant='h3'>
              <Points value={prime.purchaseAmount} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {balance >= prime.purchaseAmount ? (
              <BigButton onClick={onSubmit}>Click here to buy Prime</BigButton>
            ) : (
              <BigButton>Insufficient balance</BigButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PrimeSignup
