import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import agent from '../../agent'

import {
  PRODUCT_SELECTOR_LOADED,
  PRODUCT_SELECTOR_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.products,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: PRODUCT_SELECTOR_LOADED,
      payload: agent.Products.getAll(),
    }),
  onUnload: () => dispatch({ type: PRODUCT_SELECTOR_UNLOADED }),
})

const ProductSelector = ({
  allowAny,
  onChange,
  onLoad,
  onUnload,
  products,
}) => {
  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  // Add stock status and sort products by stock status
  const options = products
    .map((product) => ({
      ...product,
      status: product.quantity > 0 ? 'In Stock' : 'Out of Stock',
    }))
    .sort((a, b) => -b.status.localeCompare(a.status))

  return (
    <Autocomplete
      autoHighlight
      freeSolo={allowAny ?? true}
      getOptionLabel={(product) => product.name}
      getOptionSelected={(opt, val) => opt._id === val._id}
      groupBy={(product) => product.status}
      noOptionsText='No products have been added'
      onInputChange={(ev, value) => {
        onChange(value)
      }}
      onChange={(ev, value) => {
        onChange(value)
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Description'
          placeholder='Type or select a product'
          required
        />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.name, inputValue)
        const parts = parse(option.name, matches)

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
              >
                {part.text}
              </span>
            ))}
          </div>
        )
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelector)
