import React from 'react'
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.white,
    margin: '0 auto',
  },
}))

const DialogAppBar = ({ onClose, title }) => {
  const classes = useStyles()

  return (
    <AppBar position='relative'>
      <Toolbar>
        <IconButton edge='start' onClick={onClose}>
          <Close />
        </IconButton>
        <Typography variant='h5' className={classes.title}>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default DialogAppBar
