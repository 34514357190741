import emailjs from '@emailjs/browser'
import {
  EMAILJS_PUBLIC_KEY,
  EMAILJS_PURCHASE_TEMPLATE_ID,
  EMAILJS_SONG_TEMPLATE_ID,
  EMAILJS_SERVICE_ID,
} from './constants/emailjs'

const emailer = (params, type) => {
  let template = null
  switch (type) {
    case 'song':
      template = EMAILJS_SONG_TEMPLATE_ID
      break
    case 'store':
      template = EMAILJS_PURCHASE_TEMPLATE_ID
      break
    default:
      break
  }

  emailjs.send(EMAILJS_SERVICE_ID, template, params, EMAILJS_PUBLIC_KEY).then(
    (response) => {
      // console.log('SUCCESS!', response.status, response.text) // for debugging only
    },
    (error) => {
      // console.log('Notification failed to send:', error.text) // for debugging only
    }
  )
}

export default emailer
