import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { Button } from '@material-ui/core'

import { SNACKBAR_CLOSED } from '../constants/actionTypes'

const mapStateToProps = (state) => ({
  snackbar: state.notifier.snackbar,
})

const mapDispatchToProps = (dispatch) => ({
  onSnackbarClose: () => dispatch({ type: SNACKBAR_CLOSED }),
})

const Notification = (props) => {
  const { snackbar } = props
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (snackbar) {
      enqueueSnackbar(snackbar.message, { variant: snackbar.variant })
    }
  }, [snackbar])

  return props.children
}

const Notifier = (props) => {
  const notistackRef = React.createRef()
  const onSnackbarDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      action={(key) => (
        <Button onClick={onSnackbarDismiss(key)}>Dismiss</Button>
      )}
      onClose={() => {
        props.onSnackbarClose()
      }}
    >
      <Notification snackbar={props.snackbar}>{props.children}</Notification>
    </SnackbarProvider>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifier)
