export const useGetInvoice = (products, quantities, taxRate, primeRate = 0) => {
  if (products && quantities) {
    const items = products.map((product) => ({
      desc: product.name,
      imgUrl: product.photoUrl,
      max: product.maxPurchaseQuantity,
      onHand: product.quantity,
      price: quantities[product._id] * product.cost,
      qty: quantities[product._id],
      unit: product.cost,
      _id: product._id,
    }))

    const subtotal = items
      .map(({ price }) => price)
      .reduce((sum, i) => sum + i, 0)

    const prime = (primeRate / 100) * subtotal

    const taxes =
      primeRate > 0
        ? (taxRate / 100) * (subtotal - prime)
        : (taxRate / 100) * subtotal

    const total = primeRate > 0 ? subtotal - prime + taxes : subtotal + taxes

    const invoice = { subtotal, prime, taxes, total }

    return { items, invoice }
  } else {
    return {}
  }
}
