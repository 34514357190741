import { createMuiTheme } from '@material-ui/core/styles'
import { amber, lightGreen } from '@material-ui/core/colors'

const ChemCashTheme = {
  breakpoints: {
    values: {
      md: 768,
      lg: 1024,
    },
  },
  palette: {
    primary: lightGreen,
    secondary: amber,
  },
  overrides: {
    MuiDialogActions: {
      root: {
        padding: '16px 24px',
      },
    },
    MuiTableCell: {
      root: {
        '& > div': {
          justifyContent: 'flex-end', // fix for button alignment on <MaterialTable/> when deleting a row
        },
      },
    },
  },
}

export const ChemCashDarkTheme = createMuiTheme({
  ...ChemCashTheme,
  palette: { ...ChemCashTheme.palette, type: 'dark' },
})

export const ChemCashLightTheme = createMuiTheme({
  ...ChemCashTheme,
  palette: { ...ChemCashTheme.palette, type: 'light' },
})
