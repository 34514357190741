import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import moment from 'moment'

const StudentPassesTable = ({ passes }) => {
  const [data, setData] = useState([])

  const mappedData = passes.map((pass) => ({
    createdAt: moment(pass.createdAt),
    createdBy: pass.createdBy,
    destination: pass.destination,
    duration: pass.duration,
  }))

  const [columns] = useState([
    {
      title: 'Date',
      field: 'createdAt',
      render: (rowData) => rowData.createdAt.format('ddd, MMM D, YYYY'),
    },
    {
      title: 'Time',
      field: 'createdAt',
      render: (rowData) => rowData.createdAt.format('h:mm A'),
    },
    { title: 'Duration (min)', field: 'duration' },
    { title: 'Destination', field: 'destination' },
    { title: 'Created by', field: 'createdBy' },
  ])

  useEffect(() => {
    setData(mappedData)
  }, [passes])

  return (
    <MaterialTable
      columns={columns}
      data={data}
      localization={{
        body: { emptyDataSourceMessage: 'No passes have been created' },
      }}
      options={{
        paging: false,
        toolbar: false,
      }}
    />
  )
}

export default StudentPassesTable
