import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import BonusSelector from './BonusSelector'
import CourseSelector from './CourseSelector'
import FineSelector from '../Fines/FineSelector'
import ListErrors from '../ListErrors'
import ProductSelector from '../Products/ProductSelector'
import StudentsTable from './StudentsTable'
import agent from '../../agent'

import {
  ADD_TRANSACTIONS,
  TRANSACTIONS_DIALOG_UNLOADED,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& > .MuiDialog-container > .MuiPaper-root': {
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(80),
      },
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.scores,
  bonuses: state.bonuses.bonuses,
  fines: state.fines.fines,
  primeEnabled: state.common.settings.prime.enabled,
  taxRate: state.common.settings.taxRate,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (transactions, curStudent) =>
    dispatch({
      type: ADD_TRANSACTIONS,
      payload: agent.Transactions.addMany(transactions),
      curStudent,
      snackbar: {
        message: `${transactions.typeTitle} added`,
        variant: 'success',
      },
    }),
  onExit: () => dispatch({ type: TRANSACTIONS_DIALOG_UNLOADED }),
})

const BulkTransactionsDialog = ({
  bonuses,
  curStudent,
  errors,
  fines,
  inProgress,
  isClosed,
  isOpen,
  onAdd,
  onExit,
  primeEnabled,
  taxRate,
  type,
}) => {
  const [open, setOpen] = useState(false)
  const [course, setCourse] = useState(null)
  const [students, setStudents] = useState([])

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const getCost = (amt) => Math.round(amt * (taxRate / 100 + 1))

  const handleClose = () => {
    setOpen(false)
    setCourse(null)
    isClosed()
  }

  const handleSubmitAdd = (values) => {
    onAdd(
      {
        ...values,
        students: students.map((student) => student._id),
        type:
          type === 'Bonuses' ? 'bonus' : type === 'Fines' ? 'fine' : 'purchase',
        typeTitle: type,
      },
      curStudent
    )
  }

  const handleCourseSelect = (slug) => {
    setCourse(slug)
  }

  const handleStudentSelect = (rows) => {
    setStudents(rows)
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) handleClose()
  }, [inProgress])

  // Allow for external component control of displaying the dialog
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Dialog
      className={classes.dialog}
      fullScreen={fullScreen}
      onClick={(ev) => ev.stopPropagation()}
      onClose={handleClose}
      onExit={onExit}
      onFocus={(ev) => ev.stopPropagation()}
      open={open}
    >
      <Formik
        initialValues={{
          amount: '',
          description: '',
          productId: '',
        }}
        validationSchema={Yup.object({
          amount: Yup.number().typeError('Numbers only').required('Required'),
          description: Yup.string().required('Required'),
          productId: Yup.string(),
        })}
        onSubmit={async (values) => {
          handleSubmitAdd(values)
        }}
      >
        {(formik) => (
          <Form>
            <DialogTitle>Add {type}</DialogTitle>
            <DialogContent>
              <ListErrors errors={errors} />
              {!course && <CourseSelector onSelect={handleCourseSelect} />}
              {course && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {type === 'Bonuses' ? (
                      <BonusSelector
                        bonuses={bonuses}
                        onChange={(bonus) => {
                          if (typeof bonus === 'string') {
                            formik.setFieldValue('description', bonus)
                          } else if (bonus && typeof bonus === 'object') {
                            formik.setFieldValue('amount', bonus.amount)
                            formik.setFieldValue(
                              'description',
                              bonus.description
                            )
                          } else {
                            formik.setFieldValue('amount', '')
                            formik.setFieldValue('description', '')
                          }
                        }}
                      />
                    ) : type === 'Fines' ? (
                      <FineSelector
                        fines={fines}
                        onChange={(fine) => {
                          if (typeof fine === 'string') {
                            formik.setFieldValue('description', fine)
                          } else if (fine && typeof fine === 'object') {
                            formik.setFieldValue('amount', fine.amount)
                            formik.setFieldValue(
                              'description',
                              fine.description
                            )
                          } else {
                            formik.setFieldValue('amount', '')
                            formik.setFieldValue('description', '')
                          }
                        }}
                      />
                    ) : (
                      <ProductSelector
                        onChange={(product) => {
                          if (typeof product === 'string') {
                            formik.setFieldValue('description', product)
                          } else if (product && typeof product === 'object') {
                            formik.setFieldValue(
                              'amount',
                              getCost(product.cost)
                            )
                            formik.setFieldValue('description', product.name)
                            formik.setFieldValue('productId', product._id)
                          } else {
                            formik.setFieldValue('amount', '')
                            formik.setFieldValue('description', '')
                          }
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Amount'
                      name='amount'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StudentsTable
                      course={course}
                      onSelect={handleStudentSelect}
                      primeEnabled={primeEnabled}
                    />
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={inProgress || students.length === 0}
                color='primary'
                type='submit'
                variant='contained'
              >
                {inProgress ? 'Adding...' : 'Add'}
              </Button>
              <Button
                disabled={inProgress}
                onClick={handleClose}
                variant='contained'
              >
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkTransactionsDialog)
