import {
  NOTIFICATION_DIALOG_LOADED,
  NOTIFICATION_DIALOG_UNLOADED,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  notifications: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case NOTIFICATION_DIALOG_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        notifications: action.error
          ? state.notifications
          : action.payload.notifications,
      }
    case NOTIFICATION_DIALOG_UNLOADED:
      return defaultState
    default:
      return state
  }
}
