import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const ConfirmationDialog = ({
  isOpen,
  label,
  message,
  onCancel,
  onSubmit,
  title,
}) => {
  return (
    <Dialog fullWidth maxWidth='md' open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color='primary' onClick={onSubmit}>
          {label}
        </Button>
        <Button color='primary' onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
