import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'

import PrimeBadge from '../Prime/PrimeBadge'

const StudentsTable = ({ course, onSelect, primeEnabled }) => {
  const [data, setData] = useState([])
  const [sort, setSort] = useState('last')

  const getNextSort = () => (sort === 'last' ? 'first' : 'last')

  const handleSortButtonClick = () => setSort(getNextSort())

  const mappedData = course.students
    .filter((student) => !student.dropped)
    .map((student) => ({
      username: student.username,
      firstName: student.firstName,
      lastName: student.lastName,
      hasPrime: student.hasPrime,
      fullName:
        sort === 'last'
          ? `${student.lastName}, ${student.firstName}`
          : `${student.firstName} ${student.lastName}`,
      role: student.role,
      _id: student._id,
    }))
    .sort((a, b) => a[`${sort}Name`].localeCompare(b[`${sort}Name`]))

  const [columns, setColumns] = useState([
    { title: 'Students', field: 'fullName' },
  ])

  useEffect(() => {
    if (primeEnabled) {
      setColumns([
        ...columns,
        {
          title: 'Prime',
          field: 'hasPrime',
          render: (rowData) => (rowData.hasPrime ? <PrimeBadge /> : ''),
        },
      ])
    }
  }, [primeEnabled])

  useEffect(() => {
    setData(mappedData)
  }, [course, sort])

  return (
    <MaterialTable
      columns={columns}
      data={data}
      localization={{
        body: {
          emptyDataSourceMessage: 'No students found for this course',
        },
        toolbar: {
          nRowsSelected: '{0} student(s) selected',
        },
      }}
      onSelectionChange={(rows) => onSelect(rows)}
      options={{ paging: false, selection: true, toolbar: true }}
      title={`${course.name} | Period ${course.period}`}
      actions={[
        {
          icon: 'sort',
          tooltip: `Sort by ${getNextSort()} name`,
          isFreeAction: true,
          onClick: () => handleSortButtonClick(),
        },
      ]}
    />
  )
}

export default StudentsTable
