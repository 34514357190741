import React, { Suspense, useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { push } from 'connected-react-router'
import { CssBaseline, ThemeProvider } from '@material-ui/core'

import { ChemCashDarkTheme, ChemCashLightTheme } from './Theme'
import Home from './Home'
import Loading from './Loading'
import Login from './Login'
import Notifier from './Notifier'
import WhatsNewDialog from './WhatsNewDialog'
import agent from '../agent'
import { store } from '../store'

import { APP_LOAD, REDIRECT } from '../constants/actionTypes'

const mapStateToProps = (state) => {
  return {
    appLoaded: state.common.appLoaded,
    redirectTo: state.common.redirectTo,
    darkMode: state.common?.currentUser?.darkMode,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT }),
})

const App = (props) => {
  const { appLoaded, redirectTo, darkMode } = props

  useEffect(() => {
    if (redirectTo) {
      store.dispatch(push(redirectTo))
      props.onRedirect()
    }
  }, [redirectTo])

  useEffect(() => {
    const token = window.localStorage.getItem('jwt')
    if (token) {
      agent.setToken(token)
    }

    props.onLoad(token ? agent.Auth.current() : null, token)
  }, [])

  if (appLoaded) {
    return (
      <ThemeProvider theme={darkMode ? ChemCashDarkTheme : ChemCashLightTheme}>
        <CssBaseline />
        <Notifier>
          <Suspense fallback={<p>Loading...</p>}>
            <Switch>
              <Route exact path='/login' component={Login} />
              <Route exact path='/about' component={Login} />
              <Route path='/' component={Home} />
            </Switch>
          </Suspense>
        </Notifier>
        <WhatsNewDialog />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={darkMode ? ChemCashDarkTheme : ChemCashLightTheme}>
      <CssBaseline />
      <Loading />
    </ThemeProvider>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
