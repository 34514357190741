import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Typography, makeStyles } from '@material-ui/core'

import Points from './Points'
import agent from '../agent'

import {
  ACCOUNT_BALANCE_LOADED,
  ACCOUNT_BALANCE_UNLOADED,
} from '../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  insufficient: {
    backgroundColor: theme.palette.error.light,
  },
  sufficient: {
    backgroundColor: theme.palette.success.light,
  },
  root: {
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textAlign: 'center',
  },
}))

const mapStateToProps = (state) => ({
  ...state.scores.balance,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: (username) =>
    dispatch({
      type: ACCOUNT_BALANCE_LOADED,
      payload: agent.Scores.getStudentScores(username),
    }),
  onUnload: () => dispatch({ type: ACCOUNT_BALANCE_UNLOADED }),
})

const AccountBalance = ({
  balance,
  cost,
  count,
  onLoad,
  onUnload,
  username,
}) => {
  const classes = useStyles()

  useEffect(() => {
    onLoad(username)

    return () => {
      onUnload()
    }
  }, [])

  useEffect(() => {
    onLoad(username)
  }, [count, username])

  return (
    <div
      className={`${classes.root} ${
        balance >= cost ? classes.sufficient : classes.insufficient
      }`}
    >
      <Typography variant='h5'>
        <Points value={balance} />
      </Typography>
      <Typography variant='subtitle2'>
        <em>Account Balance</em>
      </Typography>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountBalance)
