import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const mapStateToProps = (state) => ({ currentUser: state.common.currentUser })

const ProtectedRoute = (props) => {
  if (!props.currentUser) {
    return <Redirect to='/' />
  }

  return <Route exact path={props.path} component={props.component} />
}

export default connect(mapStateToProps, () => ({}))(ProtectedRoute)
