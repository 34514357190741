import React, { createContext, useContext, useEffect, useState } from 'react'
import { subscribe, unsubscribe } from '../events'
import { UNMOUNT_AUDIO_PLAYER } from '../constants/eventTypes'

export const AudioContext = createContext()

export const useAudio = () => {
  const context = useContext(AudioContext)
  if (!context && typeof window !== 'undefined') {
    throw new Error(`useAudio must be used within a AudioContext `)
  }
  return context
}

export const AudioProvider = ({ children }) => {
  const [song, setSong] = useState(null)
  const [playing, setPlaying] = useState(false)

  const isPlaying = (src) => playing && song && song.src === src

  const pauseSong = () => {
    if (song) {
      song.pause()
      setPlaying(false)
    }
  }

  const controls = {
    pause: (src) => {
      if (isPlaying(src)) {
        song.pause()
        setPlaying(false)
      }
    },
    play: (src) => {
      if (song && song.src === src) {
        song.play()
      } else {
        if (song) song.pause()
        const newSong = new Audio(src)
        newSong.play()
        setSong(newSong)
      }
      setPlaying(true)
    },
  }

  const toggle = (src) => {
    if (isPlaying(src)) {
      controls.pause(src)
    } else {
      controls.play(src)
    }
  }

  // Handle song ending on its own and pause music when audio player component unmounts
  useEffect(() => {
    if (song) song.addEventListener('ended', () => setPlaying(false))
    subscribe(UNMOUNT_AUDIO_PLAYER, () => pauseSong())

    return () => {
      if (song) song.removeEventListener('ended', () => setPlaying(false))
      unsubscribe(UNMOUNT_AUDIO_PLAYER, () => {})
    }
  }, [song])

  return (
    <AudioContext.Provider value={{ controls, isPlaying, song, toggle }}>
      {children}
    </AudioContext.Provider>
  )
}
