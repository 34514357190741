import React from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const AutocompleteSelector = ({
  allowAny,
  defaultValue,
  disabled,
  label,
  onChange,
  options,
}) => (
  <Autocomplete
    autoHighlight
    defaultValue={defaultValue || null}
    disabled={disabled || false}
    freeSolo={allowAny ?? false}
    onInputChange={(ev, value) => {
      onChange(value)
    }}
    onChange={(ev, value) => {
      onChange(value)
    }}
    options={options}
    renderInput={(params) => <TextField {...params} label={label} />}
  />
)

export default AutocompleteSelector
