import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Fab, Grid, Link, Typography, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify } from '@fortawesome/free-brands-svg-icons'

import agent from '../../agent'

import {
  SPOTIFY_LINKER_LOADED,
  SPOTIFY_LINKER_UNLOADED,
  SPOTIFY_REMOVE_AUTH,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  link: {
    cursor: 'pointer',
  },
  root: {
    textAlign: 'center',
  },
}))

const mapStateToProps = (state) => ({
  spotifyAuthUrl: state.settings.spotifyAuthUrl,
  spotifyTokens: state.common.settings.spotifyTokens,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: SPOTIFY_LINKER_LOADED,
      payload: agent.Spotify.getAuthUrl(),
    }),
  onRemoveAuth: () =>
    dispatch({
      type: SPOTIFY_REMOVE_AUTH,
      payload: agent.Spotify.removeAuth(),
    }),
  onUnload: () => dispatch({ type: SPOTIFY_LINKER_UNLOADED }),
})

const SpotifyLinker = ({
  onRemoveAuth,
  onLoad,
  onUnload,
  spotifyAuthUrl,
  spotifyTokens,
}) => {
  const classes = useStyles()

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Fab
          color='primary'
          disabled={spotifyTokens && true}
          href={spotifyAuthUrl}
          variant='extended'
        >
          <FontAwesomeIcon
            className={classes.icon}
            icon={faSpotify}
            size='2x'
          />
          {spotifyTokens ? 'Connected to Spotify' : 'Connect to Spotify'}
        </Fab>
      </Grid>
      <Grid item xs={12}>
        {spotifyTokens ? (
          <Typography>
            <Link className={classes.link} onClick={onRemoveAuth}>
              disconnect
            </Link>
          </Typography>
        ) : (
          <Typography variant='subtitle2'>
            Jukebox requires a connection
            <br />
            to your Spotify account
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SpotifyLinker)
