import React, { useState } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { HighlightOff } from '@material-ui/icons'

import ConfirmationDialog from '../ConfirmationDialog'

const RemovePlaylistConfirmationDialog = ({ disabled, onSubmit }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    onSubmit()
    setOpen(false)
  }

  const message =
    'Do you want to remove this playlist from Chem Cash? ' +
    'This will not delete the playlist from your Spotify account and ' +
    'you will not be able to relink this playlist to Chem Cash.'

  return (
    <>
      <Tooltip title='Remove playlist' arrow>
        <span>
          <IconButton disabled={disabled} onClick={handleClickOpen}>
            <HighlightOff />
          </IconButton>
        </span>
      </Tooltip>
      <ConfirmationDialog
        isOpen={open}
        label='Remove'
        message={message}
        onCancel={handleClose}
        onSubmit={handleSubmit}
        title='Remove playlist?'
      />
    </>
  )
}

export default RemovePlaylistConfirmationDialog
