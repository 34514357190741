import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Alert from '../Alert'
import CourseDialog from './CourseDialog'
import CourseList from './CourseList'
import PageHeader from '../PageHeader'
import Spinner from '../Spinner'
import agent from '../../agent'

import {
  COURSES_PAGE_LOADED,
  COURSES_PAGE_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.courses,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({ type: COURSES_PAGE_LOADED, payload: agent.Courses.getAll() }),
  onUnload: () => dispatch({ type: COURSES_PAGE_UNLOADED }),
})

const TeacherCourses = ({
  courses,
  importCount,
  importFailed,
  onLoad,
  onUnload,
  pageLoaded,
}) => {
  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title='Manage Courses'>
        <CourseDialog />
      </PageHeader>
      {importCount !== null && (
        <Alert>
          {importCount} students imported
          <br />
          {importFailed > 0 &&
            `${importFailed} students not imported (already have accounts)`}
        </Alert>
      )}
      {pageLoaded ? (
        <Spinner message='Loading...' />
      ) : (
        <CourseList courses={courses} />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherCourses)
