import React, { useEffect, useState } from 'react'
import {
  Badge,
  Chip,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Add, AddShoppingCart, Remove } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import DeleteProductDialog from './DeleteProductDialog'
import Points from '../Points'
import ProductDialog from './ProductDialog'
import ProductsToolbar from './ProductsToolbar'
import { convertGoogleLink } from '../../converter'

const useStyles = makeStyles((theme) => ({
  actionIcon: { display: 'flex' },
  cost: {
    color: 'white',
  },
  description: {
    marginBottom: '-1.5rem',
    opacity: 0,
    transition: 'opacity 0s ease-in-out',
  },
  icon: {
    'color': 'rgba(255, 255, 255, 0.7)',
    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)', color: 'white' },
  },
  name: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(0.5),
    whiteSpace: 'nowrap',
  },
  quantity: {
    marginLeft: theme.spacing(2),
  },
  tile: {
    '&:hover .MuiGridListTileBar-root': {
      'height': '100%',
      '& #description': {
        marginBottom: 0,
        opacity: 1,
        transition: 'margin-bottom 0.35s ease-in-out, opacity 0.5s ease-in-out',
      },
    },
  },
  tileBar: {
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    transition: 'height 0.35s ease-in-out',
  },
  title: {
    whiteSpace: 'normal',
  },
}))

const ProductsGallery = ({
  onAddToCart,
  onInventoryChange,
  cart,
  products,
  storeEnabled,
  userRole,
}) => {
  const classes = useStyles()

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const cols = isSmall ? 1 : isMedium || userRole === 'teacher' ? 2 : 3

  const handleAddToCartClick = (product) => onAddToCart(product)

  const handleInventoryChangeClick = (product, type) =>
    onInventoryChange({
      ...product,
      quantity: type === 'add' ? product.quantity + 1 : product.quantity - 1,
    })

  // Search feature
  const [data, setData] = useState(products)
  const [search, setSearch] = useState('')
  const handleSearchChange = (value) => {
    setSearch(value)
  }
  useEffect(() => {
    setData(
      products.filter((product) =>
        product.name.toLowerCase().includes(search.toLowerCase())
      )
    )
  }, [search])
  // END Search Feature

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ProductsToolbar onChange={handleSearchChange} value={search} />
      </Grid>
      <Grid item xs={12}>
        <GridList cols={cols} spacing={8}>
          {data?.length > 0 ? (
            data.map((product) => {
              const max =
                product.maxPurchaseQuantity < product.quantity &&
                product.maxPurchaseQuantity !== 0
                  ? product.maxPurchaseQuantity
                  : product.quantity
              const maxQuantityReached =
                max > 0 && cart?.quantities[product._id] >= max
              return (
                <GridListTile key={product.slug} className={classes.tile}>
                  <img
                    src={convertGoogleLink(product.photoUrl)}
                    alt={product.name}
                  />

                  <GridListTileBar
                    classes={{
                      actionIcon: classes.actionIcon,
                      title: classes.title,
                    }}
                    className={classes.tileBar}
                    title={
                      <div>
                        <div className={classes.description} id='description'>
                          {product.description}{' '}
                          {product.maxPurchaseQuantity > 0 && (
                            <em>(Limit {product.maxPurchaseQuantity})</em>
                          )}
                        </div>
                        <Tooltip
                          arrow
                          enterTouchDelay={0}
                          placement='bottom-start'
                          title={product.name}
                        >
                          <div className={classes.name}>{product.name}</div>
                        </Tooltip>
                      </div>
                    }
                    subtitle={
                      <div>
                        <Chip
                          className={classes.cost}
                          color='primary'
                          label={<Points value={product.cost} />}
                          size='small'
                        />
                        {(userRole === 'teacher' || product.quantity < 5) && (
                          <Chip
                            className={classes.quantity}
                            color='secondary'
                            label={
                              userRole === 'teacher'
                                ? `${product.quantity} available`
                                : `only ${product.quantity} left`
                            }
                            size='small'
                            variant='outlined'
                          />
                        )}
                      </div>
                    }
                    actionIcon={
                      userRole === 'teacher' ? (
                        <>
                          <ActionButton
                            action='Remove inventory'
                            className={classes.icon}
                            onClick={() =>
                              handleInventoryChangeClick(product, 'remove')
                            }
                          >
                            <Remove />
                          </ActionButton>
                          <ActionButton
                            action='Add inventory'
                            className={classes.icon}
                            onClick={() =>
                              handleInventoryChangeClick(product, 'add')
                            }
                          >
                            <Add />
                          </ActionButton>
                          <ProductDialog product={product} brightHover />
                          <DeleteProductDialog product={product} />
                        </>
                      ) : (
                        storeEnabled && (
                          <ActionButton
                            action={
                              !maxQuantityReached
                                ? 'Add to cart'
                                : 'Max limit reached'
                            }
                            disabled={maxQuantityReached}
                            onClick={() => handleAddToCartClick(product)}
                            className={classes.icon}
                          >
                            <Badge
                              badgeContent={cart.quantities[product._id]}
                              color='secondary'
                            >
                              <AddShoppingCart />
                            </Badge>
                          </ActionButton>
                        )
                      )
                    }
                  />
                </GridListTile>
              )
            })
          ) : (
            <Typography>
              {search !== ''
                ? 'No products matched your search'
                : userRole === 'teacher'
                ? 'You have no products'
                : 'Your teacher has not added any products'}
            </Typography>
          )}
        </GridList>
      </Grid>
    </Grid>
  )
}

export default ProductsGallery
