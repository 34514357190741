import {
  NAV_LOADED,
  NAV_UNLOADED,
  NAV_TAB_CHANGE,
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case NAV_LOADED:
    case NAV_TAB_CHANGE:
      return {
        ...state,
        navTabValue: action.value,
      }
    case NAV_UNLOADED:
      return {}
    default:
      return state
  }
}
