import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './reducers/auth'
import bonuses from './reducers/bonuses'
import common from './reducers/common'
import courses from './reducers/courses'
import economy from './reducers/economy'
import fines from './reducers/fines'
import jukebox from './reducers/jukebox'
import nav from './reducers/nav'
import notifications from './reducers/notifications'
import notifier from './reducers/notifier'
import passes from './reducers/passes'
import products from './reducers/products'
import purchases from './reducers/purchases'
import scores from './reducers/scores'
import settings from './reducers/settings'
import tasks from './reducers/tasks'
import teachers from './reducers/teachers'
import songs from './reducers/songs'

export default (history) =>
  combineReducers({
    auth,
    bonuses,
    common,
    courses,
    economy,
    fines,
    jukebox,
    nav,
    notifications,
    notifier,
    passes,
    products,
    purchases,
    scores,
    settings,
    tasks,
    teachers,
    songs,
    router: connectRouter(history),
  })
