import React from 'react'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGauge,
  faPercent,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons'
import { faHundredPoints } from '@fortawesome/pro-solid-svg-icons'

import StatCard from '../StatCard'

const TaskDataDashboard = ({ scores, task }) => {
  const getAverage = () =>
    scores.reduce((sum, score) => sum + score.value, 0) / scores.length

  const getGrades = () => {
    const A = scores.filter(
      (score) => score.value >= task.totalPoints * 0.9
    ).length
    const B = scores.filter(
      (score) =>
        score.value < task.totalPoints * 0.9 &&
        score.value >= task.totalPoints * 0.8
    ).length
    const C = scores.filter(
      (score) =>
        score.value < task.totalPoints * 0.8 &&
        score.value >= task.totalPoints * 0.7
    ).length
    const D = scores.filter(
      (score) =>
        score.value < task.totalPoints * 0.7 &&
        score.value >= task.totalPoints * 0.6
    ).length
    const F = scores.filter(
      (score) => score.value < task.totalPoints * 0.6
    ).length
    return { A, B, C, D, F }
  }

  const getPassFailRates = () => {
    const passTotal = scores.filter(
      (score) => score.value >= task.totalPoints * 0.6
    ).length
    const failTotal = scores.filter(
      (score) => score.value < task.totalPoints * 0.6
    ).length
    return { pass: passTotal, fail: failTotal }
  }

  const getPercent = (value, total) => (value / (total || 10)) * 100

  const data = {
    average: {
      score: getAverage(),
      percent: getPercent(getAverage(), task.totalPoints),
    },
    grades: getGrades(),
    passFail: {
      fail: {
        count: getPassFailRates().fail,
        percent: Math.round(getPercent(getPassFailRates().fail, scores.length)),
      },
      pass: {
        count: getPassFailRates().pass,
        percent: Math.round(getPercent(getPassFailRates().pass, scores.length)),
      },
    },
  }

  const GradeStatCard = ({ grade }) => (
    <StatCard
      icon={<FontAwesomeIcon icon={faHundredPoints} size='lg' />}
      lg={3}
      title={grade}
    >
      {Math.round(getPercent(data.grades[grade], scores.length))}% (
      {data.grades[grade]})
    </StatCard>
  )

  return (
    <Grid container spacing={3}>
      <StatCard
        icon={<FontAwesomeIcon icon={faGauge} size='lg' />}
        lg={6}
        precision={1}
        round={true}
        title='Average Score'
        value={data.average.score}
      />
      <StatCard
        icon={<FontAwesomeIcon icon={faPercent} size='lg' />}
        lg={6}
        precision={1}
        round={true}
        title='Average Percent'
        value={data.average.percent}
        percent
      />
      <StatCard
        icon={<FontAwesomeIcon icon={faThumbsUp} size='lg' />}
        lg={6}
        title='Pass Rate'
      >
        {data.passFail.pass.percent}% ({data.passFail.pass.count})
      </StatCard>
      <StatCard
        icon={<FontAwesomeIcon icon={faThumbsDown} size='lg' />}
        lg={6}
        title='Fail Rate'
      >
        {data.passFail.fail.percent}% ({data.passFail.fail.count})
      </StatCard>
      <GradeStatCard grade='A' />
      <GradeStatCard grade='B' />
      <GradeStatCard grade='C' />
      <GradeStatCard grade='D' />
    </Grid>
  )
}

export default TaskDataDashboard
