import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import ImagePreview from '../ImagePreview'
import ListErrors from '../ListErrors'
import TextFieldLimit from '../TextFieldLimit'
import agent from '../../agent'

import {
  ADD_PRODUCT,
  PRODUCT_DIALOG_UNLOADED,
  UPDATE_PRODUCT,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  addIcon: {
    marginRight: theme.spacing(1),
  },
  updateIcon: {
    color: theme.palette.text.primary,
  },
  updateIconBrightHover: {
    'color': 'rgba(255, 255, 255, 0.7)',
    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)', color: 'white' },
  },
}))

const mapStateToProps = (state) => ({
  ...state.products,
  currentUser: state.common.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (product) =>
    dispatch({
      type: ADD_PRODUCT,
      payload: agent.Products.add(product),
      snackbar: { message: 'Product added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: PRODUCT_DIALOG_UNLOADED }),
  onUpdate: (product) =>
    dispatch({
      type: UPDATE_PRODUCT,
      payload: agent.Products.update(product),
      snackbar: { message: 'Product updated', variant: 'success' },
    }),
})

const ProductDialog = ({
  brightHover,
  currentUser,
  product,
  errors,
  inProgress,
  onAdd,
  onExit,
  onUpdate,
}) => {
  const CHAR_LIMITS = { name: 60, description: 140 }

  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values })
  }

  const handleSubmitUpdate = (values) => {
    onUpdate({ ...product, ...values })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      {product ? (
        currentUser.role === 'teacher' && (
          <ActionButton
            action='Edit product'
            className={
              brightHover ? classes.updateIconBrightHover : classes.updateIcon
            }
            onClick={handleClickOpen}
          >
            <Edit />
          </ActionButton>
        )
      ) : (
        <Fab color='primary' onClick={handleClickOpen} variant='extended'>
          <Add className={classes.addIcon} />
          Add product
        </Fab>
      )}

      <Dialog
        fullScreen={fullScreen}
        maxWidth='lg'
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <Formik
          initialValues={{
            name: product?.name || '',
            description: product?.description || '',
            photoUrl: product?.photoUrl || '',
            cost: product?.cost || 0,
            maxPurchaseQuantity: product?.maxPurchaseQuantity || 0,
            quantity: product?.quantity || 0,
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .max(
                CHAR_LIMITS.name,
                `Cannot be longer than ${CHAR_LIMITS.name} characters`
              )
              .required('Required'),
            description: Yup.string().max(
              CHAR_LIMITS.description,
              `Cannot be longer than ${CHAR_LIMITS.description} characters`
            ),
            photoUrl: Yup.string().url('Not a valid url').required('Required'),
            cost: Yup.number().typeError('Numbers only').required('Required'),
            maxPurchaseQuantity: Yup.number()
              .typeError('Numbers only')
              .required('Required'),
            quantity: Yup.number()
              .typeError('Numbers only')
              .required('Required'),
          })}
          onSubmit={async (values) => {
            product ? handleSubmitUpdate(values) : handleSubmitAdd(values)
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>
                {product ? 'Edit product' : 'Add product'}
              </DialogTitle>
              <DialogContent>
                <ListErrors errors={errors} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextFieldLimit}
                      autoFocus={product ? false : true}
                      color='primary'
                      disabled={inProgress}
                      label='Product Name'
                      name='name'
                      type='text'
                      limit={CHAR_LIMITS.name}
                      errors={formik.errors.name}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextFieldLimit}
                      color='primary'
                      disabled={inProgress}
                      label='Product Description'
                      name='description'
                      type='text'
                      limit={CHAR_LIMITS.description}
                      errors={formik.errors.description}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Photo Url'
                      name='photoUrl'
                      type='url'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Cost'
                      name='cost'
                      type='number'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Quantity'
                      name='quantity'
                      type='number'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Max purchase quantity'
                      name='maxPurchaseQuantity'
                      type='number'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ImagePreview src={formik.values.photoUrl} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={inProgress}
                  color='primary'
                  type='submit'
                  variant='contained'
                >
                  {product
                    ? inProgress
                      ? 'Updating...'
                      : 'Update'
                    : inProgress
                    ? 'Adding...'
                    : 'Add'}
                </Button>
                <Button
                  disabled={inProgress}
                  onClick={handleClose}
                  variant='contained'
                >
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDialog)
