import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  withStyles,
} from '@material-ui/core'

import PageHeader from '../PageHeader'
import PassDialog from '../Passes/PassDialog'
import StudentPassesTable from './StudentPassesTable'
import agent from '../../agent'

import {
  LOAD_STUDENT_PASSES,
  STUDENT_PASSES_LOADED,
  STUDENT_PASSES_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  passes: state.passes.passes,
  settings: state.common.settings.hallpass,
  student: state.common.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  getPasses: () =>
    dispatch({ type: LOAD_STUDENT_PASSES, payload: agent.Passes.getAll() }),
  onLoad: (username) =>
    dispatch({
      type: STUDENT_PASSES_LOADED,
      payload: agent.Scores.getStudentScores(username),
    }),
  onUnload: () => dispatch({ type: STUDENT_PASSES_UNLOADED }),
})

const ProgressBar = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress)

const StudentPasses = ({
  getPasses,
  onLoad,
  onUnload,
  passes,
  settings,
  student,
}) => {
  const plural = (value) => `pass${value === 1 ? '' : 'es'}`

  const pass = {
    count: passes.length,
    max: settings.limit,
    remain: passes.length > settings.limit ? 0 : settings.limit - passes.length,
  }

  const passHeader =
    pass.max > 0
      ? `Used ${pass.count} ${plural(pass.count)} of ${pass.max} (${
          pass.remain
        } ${plural(pass.remain)} remaining)`
      : `Used ${pass.count} ${plural(pass.count)} of unlimited`

  const passUsage = pass.count > pass.max ? 100 : (pass.count * 100) / pass.max

  useEffect(() => {
    onLoad(student.username)
    getPasses()

    return () => {
      onUnload()
    }
  }, [student])

  return (
    <>
      <PageHeader title={`Passes for ${student.firstName}`}>
        <PassDialog buttonType='fab' student={student} />
      </PageHeader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={passHeader} />
            {pass.max > 0 && (
              <CardContent>
                <ProgressBar value={passUsage} variant='determinate' />
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <StudentPassesTable passes={passes} />
        </Grid>
      </Grid>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentPasses)
