import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { Loop } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import ListErrors from '../ListErrors'
import agent from '../../agent'

import { MOVE_STUDENT } from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.courses,
})

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (courseOut, courseIn) =>
    dispatch({
      type: MOVE_STUDENT,
      payload: Promise.all([
        agent.Courses.unenroll(courseOut),
        agent.Courses.enroll(courseIn),
      ]),
      snackbar: { message: 'Student moved', variant: 'success' },
    }),
})

const StudentTransferDialog = ({
  course,
  courses,
  errors,
  inProgress,
  onSubmit,
  student,
}) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (values) => {
    if (course.slug !== values.course) {
      const courseOut = { usernames: [student.username], slug: course.slug }
      const courseIn = { usernames: [student.username], slug: values.course }
      onSubmit(courseOut, courseIn)
    }
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      <ActionButton
        action='Move student'
        onClick={handleClickOpen}
        size='small'
      >
        <Loop fontSize='inherit' />
      </ActionButton>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(ev) => ev.stopPropagation()}
        onFocus={(ev) => ev.stopPropagation()}
      >
        <Formik
          initialValues={{
            course: course.slug || '',
          }}
          validationSchema={Yup.object({
            course: Yup.string().required('Required'),
          })}
          onSubmit={async (values) => {
            handleSubmit(values)
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>Move Student</DialogTitle>
              <DialogContent>
                <ListErrors errors={errors} />
                <Grid container spacing={2}>
                  <Grid item ds={12}>
                    Move{' '}
                    <b>
                      {student.firstName} {student.lastName}
                    </b>{' '}
                    to...
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      id='course'
                      label='Course'
                      name='course'
                      type='select'
                      select
                      fullWidth
                    >
                      {courses.map((course) => (
                        <MenuItem key={course.slug} value={course.slug}>
                          {`Period ${course.period} | ${course.name}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  disabled={inProgress || formik.values.course === course.slug}
                  type='submit'
                  variant='contained'
                >
                  {inProgress ? 'Moving...' : 'Move'}
                </Button>
                <Button onClick={handleClose} variant='contained'>
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentTransferDialog)
