import React from 'react'
import { Chip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(1),
  },
}))

const DroppedChip = (props) => {
  const classes = useStyles()

  return (
    <Chip
      className={classes.root}
      color='secondary'
      label='dropped'
      size='small'
      variant='outlined'
      {...props}
    />
  )
}

export default DroppedChip
