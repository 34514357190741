import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'

const InputBox = ({ label, name, onChange, value }) => {
  const [inputValue, setInputValue] = useState(value)

  const handleInputChange = (ev) => {
    const value = ev.target.value
    onChange(value)
    setInputValue(value)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      onChange={handleInputChange}
      value={inputValue}
    />
  )
}

export default InputBox
