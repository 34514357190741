import React from 'react'
import { NumericFormat } from 'react-number-format'
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  statAvatar: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: theme.spacing(6),
  },
  statCard: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
  },
}))

const StatCard = ({
  children,
  icon,
  lg,
  md,
  percent,
  precision,
  round,
  title,
  value,
  xs,
}) => {
  const classes = useStyles()

  const getRounded = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
  }

  return (
    <Grid item xs={xs || 12} md={md || 6} lg={lg || 4}>
      <Card variant='outlined'>
        <CardContent className={classes.statCard}>
          <Avatar className={classes.statAvatar}>{icon}</Avatar>
          <Typography variant='h5'>{title}</Typography>
          <Typography color='secondary' variant='h6'>
            <NumericFormat
              displayType='text'
              thousandSeparator={true}
              value={round ? getRounded(value, precision) : value}
            />
            {percent && '%'}
          </Typography>
          <Typography color='secondary' variant='h6'>
            {children}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default StatCard
