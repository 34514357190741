import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MaterialTable from 'material-table'
import moment from 'moment'
import { Chip, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

import JukeboxDialog from '../Jukebox/JukeboxDialog'
import PageHeader from '../PageHeader'
import PrimeBadge from '../Prime/PrimeBadge'
import ProcessSongRequestsDialog from './ProcessSongRequestsDialog'
import agent from '../../agent'

import {
  DELETE_SONG_REQUEST,
  SONGS_PAGE_UNLOADED,
  TEACHER_SONGS_PAGE_LOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({ ...state.songs })

const mapDispatchToProps = (dispatch) => ({
  onDelete: (id) =>
    dispatch({
      type: DELETE_SONG_REQUEST,
      payload: agent.Songs.delete(id),
      snackbar: { message: 'Song request deleted', variant: 'success' },
    }),
  onLoad: () =>
    dispatch({
      type: TEACHER_SONGS_PAGE_LOADED,
      payload: agent.Songs.forTeacher(),
    }),
  onUnload: () => dispatch({ type: SONGS_PAGE_UNLOADED }),
})

const TeacherSongs = ({ courses, onDelete, onLoad, onUnload }) => {
  const [data, setData] = useState([])

  const mappedData = courses
    .map((course) =>
      course.songs.map((song) => ({
        artist: song.artist,
        createdAt: moment(song.createdAt),
        dropped: song.student.dropped,
        hasPrime: song.student.hasPrime,
        period: course.period,
        purchasedBy: `${song.student.firstName} ${song.student.lastName} ${
          song.student.dropped ? '(dropped)' : ''
        }`,
        status: song.status,
        statusDescription: song.statusDescription,
        title: song.title,
        _id: song._id,
      }))
    )
    .flat()

  const [columns] = useState([
    {
      title: 'Request Date',
      field: 'createdAt',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Tooltip
            arrow
            color='primary'
            enterTouchDelay={0}
            title={rowData.createdAt.format('h:mm:ss a')}
          >
            <span>{rowData.createdAt.format('ddd, MMM D, YYYY')}</span>
          </Tooltip>
        ) : (
          rowData.format('ddd, MMM D, YYYY, h:mm:ss a')
        ),
    },
    { title: 'Song Title', field: 'title' },
    { title: 'Song Artist', field: 'artist' },
    { title: 'Purchased by', field: 'purchasedBy', editable: 'never' },
    {
      title: 'Prime',
      field: 'hasPrime',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          rowData.hasPrime ? (
            <PrimeBadge />
          ) : (
            ''
          )
        ) : rowData ? (
          <PrimeBadge />
        ) : (
          'No Prime'
        ),
    },
    {
      title: 'Period',
      field: 'period',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Chip label={rowData.period} color='secondary' />
        ) : (
          <Chip label={rowData} color='secondary' />
        ),
    },
    {
      title: 'Status',
      field: 'status',
      lookup: {
        'approved': 'approved',
        'pending approval': 'pending approval',
        'pending removal': 'pending removal',
        'rejected': 'rejected',
        'removed': 'removed',
      },
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <span style={{ alignItems: 'center', display: 'flex' }}>
            {rowData.statusDescription ? (
              <>
                {rowData.status}
                <Tooltip
                  arrow
                  color='primary'
                  enterTouchDelay={0}
                  title={rowData.statusDescription}
                >
                  <InfoOutlined style={{ marginLeft: '8px' }} />
                </Tooltip>
              </>
            ) : (
              rowData.status
            )}
          </span>
        ) : (
          <span>{rowData}</span>
        ),
    },
  ])

  useEffect(() => {
    onLoad()

    return () => {
      return onUnload()
    }
  }, [])

  useEffect(() => {
    setData(mappedData)
  }, [courses])

  return (
    <>
      <PageHeader title='Manage Song Requests'>
        <JukeboxDialog />
        <ProcessSongRequestsDialog />
      </PageHeader>

      <MaterialTable
        title='Song Requests'
        columns={columns}
        data={data}
        localization={{
          body: {
            emptyDataSourceMessage: 'No song requests have been submitted',
            editRow: {
              deleteText: 'Delete song request?',
              saveTooltip: 'Delete song',
            },
          },
        }}
        options={{ actionsColumnIndex: -1, paging: false, grouping: true }}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              const dataDelete = [...data]
              const index = oldData.tableData.id
              dataDelete.splice(index, 1)
              setData([...dataDelete])
              onDelete(oldData._id)
              resolve()
            }),
        }}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherSongs)
