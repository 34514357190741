import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import MaterialTable from 'material-table'
import agent from '../../agent'

import { ADD_FINE, DELETE_FINE, UPDATE_FINE } from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.fines,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (fine) =>
    dispatch({
      payload: agent.Fines.add(fine),
      snackbar: { message: 'Fine added', variant: 'success' },
      type: ADD_FINE,
    }),
  onDelete: (fine) =>
    dispatch({
      payload: agent.Fines.delete(fine),
      snackbar: { message: 'Fine deleted', variant: 'success' },
      type: DELETE_FINE,
    }),
  onUpdate: (fine) =>
    dispatch({
      payload: agent.Fines.update(fine),
      snackbar: { message: 'Fine updated', variant: 'success' },
      type: UPDATE_FINE,
    }),
})

const ManageFinesTable = ({ fines, onAdd, onDelete, onUpdate }) => {
  const [data, setData] = useState([])

  const [columns] = useState([
    { title: 'Description', field: 'description' },
    { title: 'Amount', field: 'amount' },
  ])

  useEffect(() => {
    setData(fines)
  }, [fines])

  return (
    <MaterialTable
      title='Fines'
      columns={columns}
      data={data}
      localization={{
        body: {
          emptyDataSourceMessage: 'No fines have been added',
          editRow: {
            deleteText: 'Delete this fine?',
          },
        },
      }}
      options={{
        actionsColumnIndex: -1,
        addRowPosition: 'first',
        paging: false,
      }}
      editable={{
        onRowAdd: (fine) =>
          new Promise((resolve) => {
            onAdd(fine)
            resolve()
          }),
        onRowUpdate: (fine) =>
          new Promise((resolve) => {
            onUpdate(fine)
            resolve()
          }),
        onRowDelete: (fine) =>
          new Promise((resolve) => {
            onDelete(fine)
            resolve()
          }),
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageFinesTable)
