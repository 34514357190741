import { parseFullName } from 'parse-full-name'

const parser = {
  // Clark County School District (CCSD) - Las Vegas, NV
  // Gradebook Software: Infinite Campus
  ccsd: {
    // Parser for importing student scores from a CCSD Infinite Campus gradebook export csv file
    scores: (data) => {
      // Get student usernames (student numbers)
      const usernames = data.file
        .map(
          (student, index) =>
            index > 1 && student['Assignment Name'].split('#').pop()
        )
        .filter((username) => username !== false)

      // Prepare data to send to server
      const parsed = { course: data.course, tasks: [] }
      for (const taskName in data.tasks) {
        const selected = data.tasks[taskName]
        if (selected) {
          const task = {
            title: taskName,
            totalPoints: data.file[1][taskName],
            scores: {},
          }

          for (const username of usernames) {
            const student = data.file.find((row) =>
              row['Assignment Name'].endsWith(username)
            )
            if (student) task.scores[username] = student[taskName]
          }

          parsed.tasks.push(task)
        }
      }

      return parsed
    },

    // Parser for creating student accounts from a CCSD Infinite Campus gradebook export csv file
    students: (file, password) => {
      const column = Object.keys(file[0])
      return file
        .map((row, index) => {
          if (index < 2) return null
          const student = row[column[0]]
          const user = {
            firstName: parseFullName(student).first,
            lastName: parseFullName(student).last,
            username: student.split('#')[1],
            password,
            role: 'student',
          }
          return user
        })
        .filter((element) => element !== null)
    },
  },

  // Evergreen School District - Vancouver, WA
  // Gradebook Software: Skyward
  evergreen: {
    // Parser for importing student scores from an Evergreen Skyward gradebook export csv file
    // @todo: implement this parser once gradebook file is received
    scores: (data) => {
      alert(
        '[Parser Unavailable] Please email a copy of your gradebook export file to Dr. May so he can setup a parser in the app'
      )
    },

    // Parser for creating student accounts from an Evergreen Skyward gradebook export csv file
    students: (file, password) => {
      const column = Object.keys(file[0])
      return file
        .map((row, index) => {
          if (index < 4) return null
          const student = parseFullName(row[column[0]])
          const studentNumber = row[column[1]]
          const user = {
            firstName: student.first,
            lastName: student.last,
            username: `u${studentNumber.replace(/^0+/, '')}`,
            password,
            role: 'student',
          }
          return user
        })
        .filter((element) => element !== null)
    },
  },

  // Lehi School District - Lehi, UT
  // Gradebook Software: Skyward
  lehi: {
    // Parser for importing student scores from a Lehi Skyward gradebook export csv file
    // @todo: implement this parser once gradebook file is received
    scores: (data) => {
      alert(
        '[Parser Unavailable] Please email a copy of your gradebook export file to Dr. May so he can setup a parser in the app'
      )
    },

    // Parser for creating student accounts from a Lehi Skyward gradebook export csv file
    students: (file, password) => {
      const column = Object.keys(file[0])
      return file
        .map((row, index) => {
          if (index < 4) return null
          const student = parseFullName(row[column[2]])
          const studentNumber = row[column[1]]
          const user = {
            firstName: student.first,
            lastName: student.last,
            username: `red${studentNumber.replace(/^0+/, '')}`,
            password,
            role: 'student',
          }
          return user
        })
        .filter((element) => element !== null)
    },
  },
}

export default parser
