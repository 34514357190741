import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MaterialTable from 'material-table'
import moment from 'moment'

import Points from '../Points'
import PurchaseDetailsList from './PurchaseDetailsList'
import agent from '../../agent'

import { STUDENT_DELETE_PURCHASE } from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  hasPrime: state.common.currentUser.hasPrime,
  prime: state.common.settings.prime,
  purchases: state.purchases.purchases,
  taxRate: state.common.settings.taxRate,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (id) =>
    dispatch({
      type: STUDENT_DELETE_PURCHASE,
      payload: agent.Purchases.delete(id),
      snackbar: { message: 'Purchase deleted', variant: 'success' },
    }),
})

const StudentPurchasesTable = ({
  hasPrime,
  onDelete,
  prime,
  purchases,
  taxRate,
}) => {
  const [data, setData] = useState([])

  const primeRate = prime.storePurchaseDiscount
  const isPrime = prime.enabled && primeRate > 0 && hasPrime

  const mappedData = purchases.map((purchase) => ({
    createdAt: moment(purchase.createdAt),
    note: purchase.note,
    quantities: purchase.quantities,
    products: purchase.products,
    status: purchase.status,
    totalCost: purchase.products.reduce(
      (prev, cur) =>
        prev +
        cur.cost *
          purchase.quantities[cur._id] *
          (taxRate / 100 + 1) *
          (isPrime ? 1 - primeRate / 100 : 1),
      0
    ),
    totalItems: Object.values(purchase.quantities).reduce(
      (prev, cur) => prev + cur,
      0
    ),
    _id: purchase._id,
  }))

  const [columns] = useState([
    {
      title: 'Purchase Date',
      field: 'createdAt',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row'
          ? rowData.createdAt.format('ddd, MMM D, YYYY')
          : rowData.format('ddd, MMM D, YYYY'),
    },
    { title: 'Total Items', field: 'totalItems' },
    {
      title: 'Total Cost',
      field: 'totalCost',
      render: (rowData) => <Points value={rowData.totalCost} />,
    },
    { title: 'Notes', field: 'note' },
    { title: 'Status', field: 'status' },
  ])

  useEffect(() => {
    setData(mappedData)
  }, [purchases])

  return (
    <MaterialTable
      title='Orders'
      columns={columns}
      data={data}
      localization={{
        body: {
          emptyDataSourceMessage: 'No purchases have been made',
          editRow: {
            deleteText: 'Delete purchase? This will cancel your order',
            saveTooltip: 'Delete purchase',
          },
        },
      }}
      options={{ actionsColumnIndex: -1, paging: false, showTitle: false }}
      detailPanel={(rowData) => {
        return (
          <PurchaseDetailsList
            products={rowData.products}
            quantities={rowData.quantities}
          />
        )
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
      editable={{
        isDeletable: (rowData) => rowData.status === 'pending',
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data]
            const index = oldData.tableData.id
            dataDelete.splice(index, 1)
            setData([...dataDelete])
            onDelete(oldData._id)
            resolve()
          }),
      }}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentPurchasesTable)
