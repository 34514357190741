import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import agent from '../../agent'

import { DELETE_COURSE } from '../../constants/actionTypes'

const mapDispatchToProps = (dispatch) => ({
  onDelete: (slug) =>
    dispatch({
      type: DELETE_COURSE,
      payload: agent.Courses.delete(slug),
      snackbar: { message: 'Course deleted', variant: 'success' },
    }),
})

const DeleteCourseAlert = ({ course, onDelete }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    setOpen(false)
    onDelete(course.slug)
  }

  return (
    <>
      <ActionButton action='Delete course' onClick={handleClickOpen}>
        <Delete />
      </ActionButton>

      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(ev) => ev.stopPropagation()}
        onFocus={(ev) => ev.stopPropagation()}
      >
        <DialogTitle>Delete Course</DialogTitle>
        <DialogContent>
          <DialogContentText component='div'>
            Are you sure you want to delete{' '}
            <b>
              {course.name} Period {course.period}
            </b>
            ? This action will permanently delete:
            <ul>
              <li>all student accounts</li>
              <li>all song requests</li>
              <li>all notifications</li>
              <li>all transactions</li>
              <li>all hallpasses</li>
              <li>all purchases</li>
              <li>all scores</li>
            </ul>
            associated with this course. This action <b>cannot be undone</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color='secondary'
            variant='contained'
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleClose} variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(() => ({}), mapDispatchToProps)(DeleteCourseAlert)
