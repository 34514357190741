import React from 'react'
import { connect } from 'react-redux'
import { Tooltip, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlask } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    margin: `0 ${theme.spacing(1)}px`,
  },
}))

const mapStateToProps = (state) => ({
  primeEnabled: state.common.settings.prime.enabled,
})

const PrimeBadge = ({ permanent, primeEnabled, size, title }) => {
  const classes = useStyles()

  return (
    <>
      {(primeEnabled || permanent) && (
        <Tooltip
          title={title || 'Chem Cash Prime'}
          aria-label={title || 'Chem Cash Prime'}
          arrow
        >
          <span>
            <FontAwesomeIcon
              className={classes.root}
              icon={faFlask}
              size={size || 'lg'}
            />
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default connect(mapStateToProps, () => ({}))(PrimeBadge)
