import React from 'react'
import { Grid, Link, Typography, makeStyles } from '@material-ui/core'

import profile from '../images/doc-may-profile.jpg'

const useStyles = makeStyles((theme) => ({
  page: {
    '& p:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
  profile: {
    borderRadius: '100%',
    boxShadow: theme.shadows[10],
    float: 'right',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '120px',
  },
}))

const About = () => {
  const classes = useStyles()

  return (
    <Grid className={classes.page} container spacing={2}>
      <Grid item xs={12}>
        <Typography align='center' gutterBottom variant='h5'>
          What is Chem Cash?
        </Typography>
        <img className={classes.profile} src={profile} />
        <Typography variant='body2'>
          Hi! I'm Joseph May, a high school chemistry teacher from Las Vegas.
          While I love teaching chemistry, I also love programming for the web,
          so why not combine the two.
        </Typography>
        <Typography variant='body2'>
          Chem Cash is a points-based rewards system I use in my classroom. The
          idea for this site came from a colleage who was inspired by one of his
          own high school science teachers.
        </Typography>
        <Typography variant='body2'>
          Students earn points in two ways: (1) through payouts based on their
          assessment scores and (2) with bonus points earned for anything I deam
          worthy of reward such as winning in-class competitions or going above
          and beyond to help me or a student.
        </Typography>
        <Typography variant='body2'>
          These points can be used to "purchase" goods such as school supplies,
          electronics, and anything I can find for cheap or get for free.
        </Typography>
        <Typography variant='body2'>
          However, the hottest-selling item is song requests. During work time
          in my class I play music for my students. Each class period has its
          own playlist and students can only add songs to the playlist by
          spending their Chem Cash points.
        </Typography>
        <Typography variant='body2'>
          I created this program to help manage all of the logistics behind this
          system of incentives. While it can support multiple teachers, I am
          running it on no-cost servers, so it was not intended to be a public
          program. Although, if you are interested in learning more, please
          reach out to me <Link href='mailto:support@chem.cash'>here</Link>.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default About
