import React from 'react'
import { Container, Typography } from '@material-ui/core'
import PageWrapper from './PageWrapper'

const NotFound = (props) => {
  return (
    <PageWrapper>
      <Container>
        <Typography variant='h2'>Error 404: Page Not Found</Typography>
      </Container>
    </PageWrapper>
  )
}

export default NotFound
