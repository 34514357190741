import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import parse from 'csv-parse/lib/sync'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  filedrop: {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.spacing(1),
    color: theme.palette.grey[600],
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    padding: '20px',
    transition: 'all 0.25s ease-in-out',
  },
  filedropActive: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  filedropReject: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
}))

function Filedrop({ onFiledrop }) {
  const classes = useStyles()

  const onDrop = useCallback((acceptedFiles) => {
    // Due to {multiple: false} in useDropzone(), only one file will be received
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const parsedFile = parse(reader.result, {
          columns: true,
          relax_column_count: true,
          skip_empty_lines: true,
        })
        onFiledrop(parsedFile)
      }
      reader.readAsBinaryString(file)
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: 'text/csv, text/plain',
      multiple: false,
      onDropAccepted: onDrop,
    })

  return (
    <div
      {...getRootProps({
        className: `${classes.filedrop} ${
          isDragActive ? classes.filedropActive : ''
        } ${isDragReject ? classes.filedropReject : ''}`,
      })}
    >
      <input {...getInputProps()} />
      {!isDragActive && (
        <Typography>
          Drop a .csv file here or click to select a .csv file
        </Typography>
      )}
      {isDragActive && !isDragReject && (
        <Typography>Drop .csv file here</Typography>
      )}
      {isDragReject && <Typography>File type not accepted</Typography>}
    </div>
  )
}

export default Filedrop
