import React, { useState } from 'react'
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  Assignment,
  ExpandMore,
  Gavel,
  Group,
  LocalAtm,
  Money,
  PlaylistAddCheck,
  ShoppingCart,
  Store,
  Wc,
} from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleHalfStroke,
  faFlask,
  faPersonBooth,
  faUserLargeSlash,
} from '@fortawesome/free-solid-svg-icons'
import { faTurntable } from '@fortawesome/pro-solid-svg-icons'

import DeleteTeacherAlert from './DeleteTeacherAlert'
import JukeboxBadge from '../Jukebox/JukeboxBadge'
import StatCard from '../StatCard'
import TeacherDialog from './TeacherDialog'

const useStyles = makeStyles((theme) => ({
  teacherDetails: {
    display: 'block',
  },
  teacherHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  teacherUsername: {
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(12),
  },
  teacherName: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: '1',
  },
}))

const TeacherStats = ({ teacher }) => {
  const addItems = (items) => (items ? Object.values(items).flat().length : 0)

  const countStudentSetting = (setting, students) =>
    students
      ? Object.values(students)
          .flat()
          .reduce((prev, cur) => prev + cur[setting], 0)
      : 0

  return (
    <div>
      <Typography variant='h6' gutterBottom>
        Teacher Stats
      </Typography>
      <Grid container spacing={2}>
        <StatCard
          icon={<Assignment fontSize='large' />}
          title='Assignments'
          value={addItems(teacher.tasks)}
          round
        />
        <StatCard
          icon={<FontAwesomeIcon icon={faCircleHalfStroke} size='lg' />}
          title='Dark Mode'
          value={countStudentSetting('darkMode', teacher.students)}
          round
        />
        <StatCard
          icon={<FontAwesomeIcon icon={faUserLargeSlash} size='lg' />}
          title='Dropped'
          value={countStudentSetting('dropped', teacher.students)}
          round
        />
        <StatCard
          icon={<Gavel fontSize='large' />}
          title='Fines'
          value={addItems(teacher.fines)}
          round
        />
        <StatCard
          icon={<Wc fontSize='large' />}
          title='Hallpasses'
          value={addItems(teacher.passes)}
          round
        />
        <StatCard
          icon={<FontAwesomeIcon icon={faTurntable} size='lg' />}
          title='Jukebox'
          value={addItems(teacher.jukebox)}
          round
        />
        <StatCard
          icon={<ShoppingCart fontSize='large' />}
          title='Online Purchases'
          value={addItems(teacher.purchases)}
          round
        />
        <StatCard
          icon={<FontAwesomeIcon icon={faFlask} size='lg' />}
          title='Prime Members'
          value={countStudentSetting('hasPrime', teacher.students)}
          round
        />
        <StatCard
          icon={<Store fontSize='large' />}
          title='Products'
          value={teacher.products ? teacher.products.length : 0}
          round
        />
        <StatCard
          icon={<Money fontSize='large' />}
          title='Scores'
          value={addItems(teacher.scores)}
          round
        />
        <StatCard
          icon={<PlaylistAddCheck fontSize='large' />}
          title='Songs'
          value={addItems(teacher.songs)}
          round
        />
        <StatCard
          icon={<Group fontSize='large' />}
          title='Students'
          value={addItems(teacher.students)}
          round
        />
        <StatCard
          icon={<LocalAtm fontSize='large' />}
          title='Transactions'
          value={addItems(teacher.transactions)}
          round
        />
        <StatCard
          icon={<FontAwesomeIcon icon={faPersonBooth} size='lg' />}
          title='Viewed Changes'
          value={countStudentSetting('viewedChanges', teacher.students)}
          round
        />
      </Grid>
    </div>
  )
}

const TeacherCourses = ({ teacher }) => {
  const countSetting = (setting, students) =>
    students.reduce((prev, cur) => prev + cur[setting], 0)

  const StatListItem = ({ icon, primary, secondary }) => (
    <ListItem alignItems='flex-start'>
      <ListItemAvatar>
        <Avatar>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  )

  return (
    <div>
      <Typography variant='h6' gutterBottom>
        Courses
      </Typography>
      <Grid container spacing={2}>
        {teacher.courses && teacher.courses.length > 0 ? (
          teacher.courses.map((course, index) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Card variant='outlined'>
                  <CardContent>
                    <Typography gutterBottom variant='h5'>
                      {course.name}
                    </Typography>
                    <Chip
                      color='secondary'
                      label={`Period ${course.period}`}
                      size='small'
                      style={{ color: 'white', marginRight: '8px' }}
                    />
                    <Chip
                      color='primary'
                      label={`${course.students.length} student${
                        course.students.length === 1 ? '' : 's'
                      }`}
                      size='small'
                      variant='outlined'
                    />
                    <List>
                      <StatListItem
                        icon={<Assignment />}
                        primary='Assignments'
                        secondary={teacher.tasks[course._id].length}
                      />
                      <StatListItem
                        icon={<FontAwesomeIcon icon={faCircleHalfStroke} />}
                        primary='Dark Mode'
                        secondary={countSetting(
                          'darkMode',
                          teacher.students[course._id]
                        )}
                      />
                      <StatListItem
                        icon={<FontAwesomeIcon icon={faUserLargeSlash} />}
                        primary='Dropped'
                        secondary={countSetting(
                          'dropped',
                          teacher.students[course._id]
                        )}
                      />
                      <StatListItem
                        icon={<Gavel />}
                        primary='Fines'
                        secondary={teacher.fines[course._id].length}
                      />
                      <StatListItem
                        icon={<Wc />}
                        primary='Hallpasses'
                        secondary={teacher.passes[course._id].length}
                      />
                      <StatListItem
                        icon={<FontAwesomeIcon icon={faTurntable} />}
                        primary='Jukebox'
                        secondary={teacher.jukebox[course._id].length}
                      />
                      <StatListItem
                        icon={<ShoppingCart />}
                        primary='Online Purchases'
                        secondary={teacher.purchases[course._id].length}
                      />
                      <StatListItem
                        icon={<FontAwesomeIcon icon={faFlask} />}
                        primary='Prime Members'
                        secondary={countSetting(
                          'hasPrime',
                          teacher.students[course._id]
                        )}
                      />
                      <StatListItem
                        icon={<Money />}
                        primary='Scores'
                        secondary={teacher.scores[course._id].length}
                      />
                      <StatListItem
                        icon={<PlaylistAddCheck />}
                        primary='Song Requests'
                        secondary={teacher.songs[course._id].length}
                      />
                      <StatListItem
                        icon={<LocalAtm />}
                        primary='Transactions'
                        secondary={teacher.transactions[course._id].length}
                      />
                      <StatListItem
                        icon={<FontAwesomeIcon icon={faPersonBooth} />}
                        primary='Viewed Changes'
                        secondary={countSetting(
                          'viewedChanges',
                          teacher.students[course._id]
                        )}
                      />
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        ) : (
          <Grid item xs={12}>
            <Typography>No courses have been created</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const TeacherSettings = ({ settings }) => {
  const SettingValue = ({ value }) => {
    const type = typeof value
    const valueString = JSON.stringify(value)
    if (type === 'boolean')
      return (
        <Chip
          color={value ? 'primary' : 'secondary'}
          label={valueString}
          style={{ color: 'white' }}
        />
      )
    if (type === 'object' && value.hasOwnProperty('_id')) return value._id
    return valueString
  }

  return (
    <div>
      <Typography variant='h6' gutterBottom>
        Settings
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Setting</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(settings).map((setting, index) => (
            <TableRow key={index}>
              <TableCell>{setting}</TableCell>
              <TableCell>
                <SettingValue value={settings[setting]} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

const TeacherList = ({ teachers }) => {
  const [expanded, setExpanded] = useState(false)

  const classes = useStyles()

  const handleChange = (panel) => (ev, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      {teachers?.length > 0 ? (
        teachers.map((teacher) => {
          return (
            <ExpansionPanel
              expanded={expanded === teacher.username}
              key={teacher.username}
              onChange={handleChange(teacher.username)}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <div className={classes.teacherHeader}>
                  <Chip
                    color='secondary'
                    label={teacher.username}
                    className={classes.teacherUsername}
                  />
                  <Typography className={classes.teacherName} variant='h5'>
                    {teacher.firstName} {teacher.lastName}
                    <JukeboxBadge
                      alwaysShow={true}
                      enabled={teacher?.permissions?.jukebox || false}
                    />
                  </Typography>
                  <TeacherDialog teacher={teacher} />
                  <DeleteTeacherAlert teacher={teacher} />
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.teacherDetails}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TeacherStats teacher={teacher} />
                  </Grid>
                  <Grid item xs={12}>
                    <TeacherCourses teacher={teacher} />
                  </Grid>
                  <Grid item xs={12}>
                    <TeacherSettings settings={teacher.settings} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <b>Viewed Changes</b>:{' '}
                      {teacher.viewedChanges ? 'yes' : 'no'}
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })
      ) : (
        <Typography>No teachers found</Typography>
      )}
    </>
  )
}

export default TeacherList
