import superagentPromise from 'superagent-promise'
import _superagent from 'superagent'

const superagent = superagentPromise(_superagent, global.Promise)

const API_ROOT =
  process.env.NODE_ENV === 'production'
    ? 'https://chem-cash.herokuapp.com/api'
    : 'http://localhost:4000/api'

const encode = encodeURIComponent
const responseBody = (res) => res.body

let token = null
let tokenPlugin = (req) => {
  if (token) {
    req.set('authorization', `Token ${token}`)
  }
}

const requests = {
  // @todo update superagent-promise from v1.1.0 when available
  // superagent-promise does not allow for body to pass, so it has been manually added to the package file
  // This is a known issue, see: https://github.com/lightsofapollo/superagent-promise/issues/28
  del: (url, body) =>
    superagent
      .del(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  get: (url) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
}

const Auth = {
  current: () => requests.get('/user'),
  login: (username, password) =>
    requests.post('/users/login', { user: { username, password } }),
  register: (user) => requests.post('/users', { user }),
  save: (user) => requests.put('/user', { user }),
}

const Bonuses = {
  add: (bonus) => requests.post('/bonuses', { bonus }),
  delete: (bonus) => requests.del(`/bonuses/${bonus._id}`),
  getAll: () => requests.get('/bonuses'),
  update: (bonus) => requests.put(`/bonuses/${bonus._id}`, { bonus }),
}

const Courses = {
  add: (course) => requests.post('/courses', { course }),
  delete: (slug) => requests.del(`/courses/${slug}`),
  enroll: (course) =>
    requests.post(`/courses/${course.slug}/enroll`, { course }),
  getAll: () => requests.get('/courses'),
  unenroll: (course) =>
    requests.del(`/courses/${course.slug}/enroll`, { course }),
  update: (course) => requests.put(`/courses/${course.slug}`, { course }),
}

const Fines = {
  add: (fine) => requests.post('/fines', { fine }),
  delete: (fine) => requests.del(`/fines/${fine._id}`),
  getAll: () => requests.get('/fines'),
  update: (fine) => requests.put(`/fines/${fine._id}`, { fine }),
}

const Notifications = {
  clearAll: () => requests.del('/notifications'),
  getAll: () => requests.get('/notifications'),
}

const Passes = {
  add: (pass) => requests.post('/passes', { pass }),
  delete: (id) => requests.del(`/passes/${id}`),
  getAll: () => requests.get('/passes'),
  getLastPass: () => requests.get('/passes/last'),
  getStudentPasses: (studentId) => requests.get(`/passes/student/${studentId}`),
  update: (pass) => requests.put(`/passes/${pass._id}`, { pass }),
}

const Products = {
  add: (product) => requests.post('/products', { product }),
  delete: (slug) => requests.del(`/products/${slug}`),
  getAll: () => requests.get('/products'),
  update: (product) => requests.put(`/products/${product.slug}`, { product }),
}

const Purchases = {
  add: (purchase) => requests.post('/purchases', { purchase }),
  delete: (id) => requests.del(`/purchases/${id}`),
  forStudent: () => requests.get('/purchases/student'),
  forTeacher: () => requests.get('/purchases/teacher'),
  update: (purchase) => requests.put(`/purchases/${purchase.id}`, { purchase }),
}

const Scores = {
  getAll: () => requests.get('/scores/all'),
  getStudentScores: (username) => requests.get(`/scores?username=${username}`),
  getTaskScores: (taskId) => requests.get(`/scores/task?taskId=${taskId}`),
  import: (data) => requests.post('/scores/import', { data }),
}

const Settings = {
  // get: () => requests.get('/user/settings'),
  jukebox: (values) => requests.put('/user/settings/jukebox', { values }),
  update: (settings) => requests.put('/user/settings', { settings }),
}

const Songs = {
  delete: (id) => requests.del(`/songs/${id}`),
  forStudent: () => requests.get('/songs/student'),
  forTeacher: () => requests.get('/songs/teacher'),
  request: (song) => requests.post('/songs', { song }),
  update: (song) => requests.put(`/songs/${song.id}`, { song }),
}

const Spotify = {
  addToQueue: (cost, track, tokens) =>
    requests.post('/spotify/queue/add', { cost, track, tokens }),
  createPlaylist: (name, slug, tokens) =>
    requests.post('/spotify/playlist/create', { name, slug, tokens }),
  getAuthUrl: () => requests.get('/spotify/auth'),
  getPlaylist: (id, tokens) =>
    requests.post('/spotify/playlist', { id, tokens }),
  getQueue: (tokens) => requests.post('/spotify/queue', { tokens }),
  removeAuth: () => requests.del('/spotify/auth'),
  removePlaylist: (slug) => requests.del('/spotify/playlist/remove', { slug }),
  // test: (tokens) => requests.put('/spotify/test', { tokens }),
}

const Students = {
  add: (user, courseId) =>
    requests.post(`/users?courseId=${encode(courseId)}`, { user }),
  delete: (user) => requests.del(`/users/student/${user.username}`),
  import: (users, courseId) =>
    requests.post(`/users/import?courseId=${encode(courseId)}`, { users }),
  update: (user) => requests.put(`/users/${user.username}`, { user }),
}

const Tasks = {
  getAll: (courseId) => requests.get(`/tasks?courseId=${courseId}`),
  delete: (task) => requests.del(`/tasks/${task.slug}`),
  update: (task) => requests.put(`/tasks/${task.slug}`, { task }),
}

const Teachers = {
  getAll: () => requests.get('/users?role=teacher'),
  delete: (user) => requests.del(`/users/teacher/${user.username}`),
  resetFlags: (role) => requests.put(`/users/reset?role=${role}`),
  update: (user) => requests.put(`/users/${user.username}`, { user }),
}

const Transactions = {
  add: (transaction) => requests.post('/transactions', { transaction }),
  addMany: (transactions) =>
    requests.post('/transactions/bulk', { transactions }),
  delete: (transaction) => requests.del(`/transactions/${transaction._id}`),
  update: (transaction) =>
    requests.put(`/transactions/${transaction._id}`, { transaction }),
}

export default {
  Auth,
  Bonuses,
  Courses,
  Fines,
  Notifications,
  Passes,
  Products,
  Purchases,
  Scores,
  Settings,
  Songs,
  Spotify,
  Students,
  Tasks,
  Teachers,
  Transactions,
  setToken: (_token) => {
    token = _token
  },
}
