import {
  ASYNC_START,
  ADD_PASS,
  DELETE_PASS,
  GET_LAST_PASS,
  LOAD_STUDENT_PASSES,
  LOAD_TEACHER_PASSES,
  LOAD_TEACHER_PASS_DIALOG,
  UNLOAD_MANAGE_PASSES,
  UPDATE_PASS,
} from '../constants/actionTypes'

const defaultState = {
  courses: [],
  errors: null,
  inProgress: false,
  lastPass: null,
  pageLoaded: false,
  passes: [],
}

export default (state = defaultState, action) => {
  let courses = []
  switch (action.type) {
    case ADD_PASS:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        passes: action.error
          ? state.passes
          : [action.payload.pass, ...state.passes],
      }
    case ASYNC_START:
      if (
        action.subtype === ADD_PASS ||
        action.subtype === LOAD_STUDENT_PASSES ||
        action.subtype === LOAD_TEACHER_PASSES
      ) {
        return { ...state, errors: null, inProgress: true }
      }
      break
    case DELETE_PASS:
      // Changing a deep-nested array requires deep-copying
      // to avoid altering the previous state
      state.courses.forEach((course) => {
        const courseCopy = { ...course }
        courseCopy.passes = courseCopy.passes.filter(
          (pass) => pass._id.toString() !== action.payload.pass._id.toString()
        )
        courses.push(courseCopy)
      })
      return {
        ...state,
        courses: action.error ? state.courses : courses,
      }
    case GET_LAST_PASS:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        lastPass: action.error ? state.lastPass : action.payload.pass,
      }
    case LOAD_STUDENT_PASSES:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        pageLoaded: true,
        passes: action.error ? state.passes : action.payload.passes,
      }
    case LOAD_TEACHER_PASSES:
      return {
        ...state,
        courses: action.error ? state.courses : action.payload.courses,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        pageLoaded: true,
      }
    case LOAD_TEACHER_PASS_DIALOG:
      return {
        ...state,
        passes: action.error ? state.passes : action.payload.passes,
        pageLoaded: true,
      }
    case UNLOAD_MANAGE_PASSES:
      return defaultState
    case UPDATE_PASS:
      // Changing a deep-nested array requires deep-copying
      // to avoid altering the previous state
      !action.error &&
        state.courses.forEach((course) => {
          const courseCopy = { ...course }
          courseCopy.passes = courseCopy.passes.map((pass) =>
            pass._id.toString() === action.payload.pass._id.toString()
              ? action.payload.pass
              : pass
          )
          courses.push(courseCopy)
        })
      return {
        ...state,
        courses: action.error ? state.courses : courses,
      }
    default:
      return state
  }

  return state
}
