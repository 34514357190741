import React, { useEffect } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { PlayArrow, Pause } from '@material-ui/icons'

import { useAudio } from '../contexts/AudioContext'
import { publish } from '../events'
import { UNMOUNT_AUDIO_PLAYER } from '../constants/eventTypes'

const AudioPlayer = ({ src }) => {
  const { isPlaying, toggle } = useAudio()

  const playing = isPlaying(src)

  const handleClick = () => toggle(src)

  // Pause song when audio player unmounts
  useEffect(() => {
    return () => {
      publish(UNMOUNT_AUDIO_PLAYER)
    }
  }, [])

  return (
    <Tooltip title={`${playing ? 'Pause' : 'Play'} song preview`} arrow>
      <IconButton onClick={handleClick}>
        {playing ? <Pause /> : <PlayArrow />}
      </IconButton>
    </Tooltip>
  )
}

export default AudioPlayer
