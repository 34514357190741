import React from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

const BonusSelector = ({ allowAny, bonuses, onChange }) => (
  <Autocomplete
    autoHighlight
    freeSolo={allowAny ?? true}
    getOptionLabel={(bonus) => bonus.description}
    getOptionSelected={(opt, val) => opt._id === val._id}
    noOptionsText='No bonuses have been added'
    onInputChange={(ev, value) => {
      onChange(value)
    }}
    onChange={(ev, value) => {
      onChange(value)
    }}
    options={bonuses}
    renderInput={(params) => (
      <TextField
        {...params}
        label='Description'
        placeholder='Type or select a bonus'
        required
      />
    )}
    renderOption={(option, { inputValue }) => {
      const matches = match(option.description, inputValue)
      const parts = parse(option.description, matches)

      return (
        <div>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      )
    }}
  />
)

export default BonusSelector
