import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Publish } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import Filedrop from '../Filedrop'
import Spinner from '../Spinner'
import agent from '../../agent'
import parser from '../../parsers'

import {
  IMPORT_STUDENTS,
  STUDENT_DIALOG_UNLOADED,
} from '../../constants/actionTypes'
import { SUPPORTED_SCHOOLS } from '../../constants/support'

const mapStateToProps = (state) => ({
  ...state.common.settings,
  ...state.courses,
})

const mapDispatchToProps = (dispatch) => ({
  onDrop: (users, courseId) =>
    dispatch({
      type: IMPORT_STUDENTS,
      payload: agent.Students.import(users, courseId),
      snackbar: { message: 'Students imported', variant: 'success' },
    }),
  onExit: () => dispatch({ type: STUDENT_DIALOG_UNLOADED }),
})

const ImportStudentsDialog = ({
  course,
  defaultStudentPassword,
  errors,
  inProgress,
  onDrop,
  onExit,
  schoolDistrict,
}) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleFiledrop = (file) => {
    try {
      const users = parser[schoolDistrict].students(
        file,
        defaultStudentPassword
      )
      onDrop(users, course._id)
    } catch {
      alert('[ERROR] Invalid file type or improperly formatted file')
    }
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      <ActionButton action='Import students' onClick={handleClickOpen}>
        <Publish />
      </ActionButton>

      <Dialog
        fullScreen={fullScreen}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <DialogTitle>Import Students</DialogTitle>
        {SUPPORTED_SCHOOLS.STUDENTS.includes(schoolDistrict) ? (
          <DialogContent>
            {inProgress ? (
              <Spinner message='Importing...' />
            ) : (
              <>
                <Typography gutterBottom>
                  Students can be imported from a .csv file exported from your
                  gradebook software
                </Typography>
                <Filedrop onFiledrop={handleFiledrop} />
              </>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            {schoolDistrict === 'none' ? (
              <Typography>
                You must select a <em>School District</em> in the{' '}
                <em>Account Details</em> section of the <em>Settings</em> page
                to use the import feature
              </Typography>
            ) : (
              <Typography>
                Please email a copy of your gradebook export file to{' '}
                <Link href='mailto:support@chem.cash'>support@chem.cash</Link>{' '}
                to enable this feature
              </Typography>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            disabled={inProgress}
            onClick={handleClose}
            variant='contained'
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportStudentsDialog)
