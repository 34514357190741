import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'

import BigButton from '../BigButton'
import Logo from '../Logo'

const getGradient = (theme, angle) =>
  `linear-gradient(${angle}, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`

const useStyles = makeStyles((theme) => ({
  banner: {
    alignItems: 'center',
    background: theme.palette.primary.main,
    background: getGradient(theme, '180deg'),
    color: theme.palette.common.white,
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '500px',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      background: getGradient(theme, '90deg'),
      flexFlow: 'row nowrap',
      height: '180px',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
  },
  bannerLogo: {
    height: '45%',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  bannerText: {
    fontFamily: '"Carter One", serif',
  },
}))

const PrimeBanner = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.banner} {...props}>
      <Logo className={classes.bannerLogo} prime={true} />
      <Typography
        align='center'
        className={classes.bannerText}
        color='inherit'
        variant='h4'
      >
        Get access
        <br />
        to exclusive
        <br />
        member benefits!
      </Typography>
      <BigButton>Click to learn more</BigButton>
    </div>
  )
}

export default PrimeBanner
