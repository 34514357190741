import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { PlaylistAdd } from '@material-ui/icons'
import InputBox from '../InputBox'

const CreatePlaylistDialog = ({ course, disabled, onSubmit }) => {
  const [open, setOpen] = useState(false)
  const [playlistName, setPlaylistName] = useState(
    `Period ${course.period} (${course.name})`
  )

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    onSubmit(playlistName)
    setOpen(false)
  }

  return (
    <>
      <Tooltip title='Create playlist' arrow>
        <span>
          <IconButton disabled={disabled} onClick={handleClickOpen}>
            <PlaylistAdd />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth='md'
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <DialogTitle>Create Playlist</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will create a playlist on your Spotify account linked to Period{' '}
            {course.period} on Chem Cash. You can enter a playlist name below or
            use the default name. Be sure to add songs for this period to this
            playlist when processing song requests.
          </DialogContentText>
          <InputBox
            label='Playlist Name'
            name='playlistName'
            onChange={setPlaylistName}
            value={playlistName}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus color='primary' onClick={handleSubmit}>
            Create
          </Button>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreatePlaylistDialog
