import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import PageHeader from './../PageHeader'
import ResetWhatsNewAlert from './ResetWhatsNewAlert'
import Spinner from '../Spinner'
import TeacherDialog from './TeacherDialog'
import TeacherList from './TeacherList'
import agent from '../../agent'

import {
  MANAGE_TEACHERS_PAGE_LOADED,
  MANAGE_TEACHERS_PAGE_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.teachers,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: MANAGE_TEACHERS_PAGE_LOADED,
      payload: agent.Teachers.getAll(),
    }),
  onUnload: () => dispatch({ type: MANAGE_TEACHERS_PAGE_UNLOADED }),
})

const ManageTeachers = ({ onLoad, onUnload, pageLoaded, teachers }) => {
  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title={'Manage Teachers'}>
        <ResetWhatsNewAlert />
        <TeacherDialog />
      </PageHeader>
      {pageLoaded ? (
        <TeacherList teachers={teachers} />
      ) : (
        <Spinner message='Loading...' />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTeachers)
