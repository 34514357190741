import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Collapse, IconButton, makeStyles } from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'
import { Close } from '@material-ui/icons'

import { ALERT_CLOSED } from '../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
}))

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch({ type: ALERT_CLOSED }),
})

const Alert = ({ children, disableClose, onClose, severity, variant }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  return (
    <div className={classes.alert}>
      <Collapse in={open}>
        <MuiAlert
          action={
            !disableClose && (
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false)
                  onClose()
                }}
              >
                <Close fontSize='inherit' />
              </IconButton>
            )
          }
          severity={severity || 'info'}
          variant={variant || 'outlined'}
        >
          {children}
        </MuiAlert>
      </Collapse>
    </div>
  )
}

export default connect(() => ({}), mapDispatchToProps)(Alert)
