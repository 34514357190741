import React from 'react'
import { connect } from 'react-redux'
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core'

import Logo from '../components/Logo'
import LogoutButton from './LogoutButton'

const useStyles = makeStyles((theme) => ({
  logo: {
    height: '65px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    color: 'white',
    flexGrow: 1,
  },
}))

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  navTabValue: state.nav.navTabValue,
  prime: state.common.settings.prime,
})

const Header = ({ currentUser, navTabValue, prime }) => {
  const classes = useStyles()
  const title = navTabValue

  if (currentUser) {
    return (
      <AppBar position='static'>
        <Container>
          <Toolbar>
            <Logo
              className={classes.logo}
              prime={prime.enabled && currentUser.hasPrime}
            />
            <Typography variant='h5' align='center' className={classes.title}>
              {title && title.charAt(0).toUpperCase() + title.slice(1)}
            </Typography>
            <LogoutButton />
          </Toolbar>
        </Container>
      </AppBar>
    )
  } else {
    return null
  }
}

export default connect(mapStateToProps, () => ({}))(Header)
