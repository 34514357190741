import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'

const TaskDataTable = ({ scores, task }) => {
  const [data, setData] = useState([])

  const mappedData = scores.map((score) => ({
    firstName: score.student.firstName,
    lastName: score.student.lastName,
    score: score.value,
    percent: Math.round((score.value / (task.totalPoints || 10)) * 100),
  }))

  const [columns] = useState([
    { title: 'Last Name', field: 'lastName' },
    { title: 'First Name', field: 'firstName' },
    {
      title: 'Score',
      field: 'score',
      render: (rowData, renderType) =>
        `${renderType === 'row' ? rowData.score : rowData} / ${
          task.totalPoints
        }`,
    },
    {
      title: 'Percent',
      field: 'percent',
      render: (rowData, renderType) =>
        `${renderType === 'row' ? rowData.percent : rowData}%`,
    },
  ])

  useEffect(() => {
    setData(mappedData)
  }, [scores])

  return (
    <MaterialTable
      columns={columns}
      data={data}
      localization={{
        body: { emptyDataSourceMessage: 'No scores found for this assignment' },
      }}
      options={{ grouping: true, paging: false }}
      title='Student Scores'
    />
  )
}

export default TaskDataTable
