import {
  ADD_COURSE,
  ADD_STUDENT,
  ALERT_CLOSED,
  ASYNC_START,
  COURSE_DIALOG_UNLOADED,
  COURSES_PAGE_LOADED,
  COURSES_PAGE_UNLOADED,
  CREATE_PLAYLIST,
  DELETE_COURSE,
  DELETE_STUDENT,
  IMPORT_STUDENTS,
  MOVE_STUDENT,
  PLAYLIST_MANAGER_DIALOG_LOADED,
  PLAYLIST_MANAGER_DIALOG_UNLOADED,
  REMOVE_PLAYLIST,
  RESET_STUDENT_PASSWORD,
  STUDENT_DIALOG_UNLOADED,
  STUDENT_SEARCH_LOADED,
  STUDENT_SEARCH_UNLOADED,
  UPDATE_COURSE,
  UPDATE_STUDENT,
} from '../constants/actionTypes'

const defaultState = {
  courses: [],
  errors: null,
  importCount: null,
  importFailed: null,
  inProgress: false,
  pageLoaded: false,
}

const sortByLastName = (a, b) => a.lastName.localeCompare(b.lastName)
const sortByPeriod = (a, b) => a.period - b.period

export default (state = defaultState, action) => {
  let courses = []
  switch (action.type) {
    case ADD_COURSE:
      return {
        ...state,
        courses: action.error
          ? state.courses
          : [...state.courses, action.payload.course].sort((a, b) =>
              sortByPeriod(a, b)
            ),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case ADD_STUDENT:
      // Changing a deep-nested array requires deep-copying
      // to avoid altering the previous state
      courses = []
      state.courses.forEach((course) => {
        const courseCopy = { ...course }
        if (course._id.toString() === action.courseId) {
          courseCopy.students = [...course.students, action.payload.user].sort(
            (a, b) => sortByLastName(a, b)
          )
        }
        courses.push(courseCopy)
      })
      return {
        ...state,
        courses: action.error ? state.courses : courses,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case ALERT_CLOSED:
      return { ...state, importCount: null, importFailed: null }
    case ASYNC_START:
      if (
        action.subtype === ADD_STUDENT ||
        action.subtype === ADD_COURSE ||
        action.subtype === MOVE_STUDENT ||
        action.subtype === UPDATE_COURSE ||
        action.subtype === UPDATE_STUDENT
      ) {
        return { ...state, errors: null, inProgress: true }
      } else if (action.subtype === COURSES_PAGE_LOADED) {
        return { ...state, errors: null, pageLoaded: true }
      } else if (action.subtype === IMPORT_STUDENTS) {
        return {
          ...state,
          errors: null,
          importCount: null,
          importFailed: null,
          inProgress: true,
        }
      }
      break
    case COURSE_DIALOG_UNLOADED:
    case STUDENT_DIALOG_UNLOADED:
      return { ...state, errors: null }
    case COURSES_PAGE_LOADED:
    case PLAYLIST_MANAGER_DIALOG_LOADED:
    case STUDENT_SEARCH_LOADED:
      return {
        ...state,
        courses: action.error ? state.courses : action.payload.courses,
        errors: action.error ? action.payload.errors : null,
        pageLoaded: false,
      }
    case COURSES_PAGE_UNLOADED:
    case PLAYLIST_MANAGER_DIALOG_UNLOADED:
    case STUDENT_SEARCH_UNLOADED:
      return defaultState
    case DELETE_COURSE:
      return {
        ...state,
        courses: action.error
          ? state.courses
          : state.courses.filter(
              (course) => course.slug !== action.payload.course.slug
            ),
      }
    case DELETE_STUDENT:
      // Changing a deep-nested array requires deep-copying
      // to avoid altering the previous state
      courses = []
      state.courses.forEach((course) => {
        const courseCopy = { ...course }
        if (course._id.toString() === action.payload.course._id.toString()) {
          courseCopy.students = course.students.filter(
            (student) => student.username !== action.payload.user.username
          )
        }
        courses.push(courseCopy)
      })
      return {
        ...state,
        courses: action.error ? state.courses : courses,
      }
    case IMPORT_STUDENTS:
      // Students are not sorted when returned from server (@todo: fix this?)
      !action.error &&
        action.payload.course.students.sort((a, b) => sortByLastName(a, b))
      return {
        ...state,
        courses: action.error
          ? state.courses
          : state.courses.map((course) =>
              course._id === action.payload.course._id
                ? action.payload.course
                : course
            ),
        errors: action.error ? action.payload.errors : null,
        importCount: action.error ? null : action.payload.count,
        importFailed: action.error ? null : action.payload.failed,
        inProgress: false,
      }
    case MOVE_STUDENT:
      // Students are not sorted when returned from server (@todo: fix this?)
      !action.error &&
        action.payload[0].course.students.sort((a, b) => sortByLastName(a, b))
      !action.error &&
        action.payload[1].course.students.sort((a, b) => sortByLastName(a, b))
      return {
        ...state,
        courses: action.error
          ? state.courses
          : state.courses.map((course) => {
              if (course._id === action.payload[0].course._id) {
                return action.payload[0].course
              } else if (course._id === action.payload[1].course._id) {
                return action.payload[1].course
              } else {
                return course
              }
            }),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case RESET_STUDENT_PASSWORD:
      return { ...state }
    case CREATE_PLAYLIST:
    case UPDATE_COURSE:
    case REMOVE_PLAYLIST:
      return {
        ...state,
        courses: action.error
          ? state.courses
          : state.courses
              .map((course) =>
                course._id === action.payload.course._id
                  ? action.payload.course
                  : course
              )
              .sort((a, b) => sortByPeriod(a, b)),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case UPDATE_STUDENT:
      // Changing a deep-nested array requires deep-copying
      // to avoid altering the previous state
      courses = []
      const updatedUser = action.payload.user
      state.courses.forEach((course) => {
        const courseCopy = { ...course }
        if (course._id.toString() === action.courseId.toString()) {
          courseCopy.students = course.students
            .map((student) =>
              student._id.toString() === updatedUser._id.toString()
                ? updatedUser
                : student
            )
            .sort((a, b) => sortByLastName(a, b))
        }
        courses.push(courseCopy)
      })
      return {
        ...state,
        courses: action.error ? state.courses : courses,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    default:
      return state
  }

  return state
}
