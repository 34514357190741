import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { PlaylistAddCheck, ThumbDown, ThumbUp } from '@material-ui/icons'

import AccountBalance from '../AccountBalance'
import FabBadge from '../FabBadge'
import InputBox from '../InputBox'
import ListErrors from '../ListErrors'
import PrimeBadge from '../Prime/PrimeBadge'
import agent from '../../agent'

import {
  SONG_REQUESTS_DIALOG_UNLOADED,
  UPDATE_SONG_REQUEST,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& > .MuiDialog-container > .MuiPaper-root': {
      [theme.breakpoints.up('lg')]: {
        minHeight: theme.spacing(54),
        width: theme.spacing(100),
      },
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  panel: {
    minWidth: theme.spacing(40),
    maxWidth: theme.spacing(60),
  },
  removalAlert: {
    backgroundColor: theme.palette.error.light,
    border: `2px solid ${theme.palette.error.dark}`,
    borderRadius: theme.spacing(2),
    color: theme.palette.common.white,
    fontSize: theme.typography.h6.fontSize,
    padding: theme.spacing(1),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  songInfo: {
    marginBottom: theme.spacing(4),
  },
  statusButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  statusDescription: {
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.default
        : theme.palette.grey[100],
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  studentInfo: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      borderTopColor: theme.palette.primary.main,
      borderTopStyle: 'dotted',
      borderTopWidth: '4px',
      paddingTop: theme.spacing(2),
    },
  },
  studentName: {
    'alignItems': 'center',
    'display': 'flex',
    '& > div': {
      marginRight: theme.spacing(1),
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.songs,
  prime: state.common.settings.prime,
  songRemovalPurchaseAmount: state.common.settings.songRemovalPurchaseAmount,
  songRemovalRefundAmount: state.common.settings.songRemovalRefundAmount,
  songRemovalRequestEnabled: state.common.settings.songRemovalRequestEnabled,
  songRequestPurchaseAmount: state.common.settings.songRequestPurchaseAmount,
  // spotifyTokens: state.common.settings.spotifyTokens,
})

const mapDispatchToProps = (dispatch) => ({
  onExit: () => dispatch({ type: SONG_REQUESTS_DIALOG_UNLOADED }),
  onSubmit: (song) =>
    dispatch({
      type: UPDATE_SONG_REQUEST,
      payload: agent.Songs.update(song),
    }),
})

const ProcessSongRequestsDialog = ({
  courses,
  errors,
  inProgress,
  isLoaded,
  onExit,
  onSubmit,
  prime,
  songRemovalPurchaseAmount,
  songRemovalRefundAmount,
  songRemovalRequestEnabled,
  songRequestPurchaseAmount,
  // spotifyTokens,
}) => {
  const [count, setCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [song, setSong] = useState(null)
  const [status, setStatus] = useState(null)
  const [statusDescription, setStatusDescription] = useState(null)
  const [statusNote, setStatusNote] = useState(null)
  const [skippedSongs, setSkippedSongs] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const getLyricSearchUrl = (artist, title) =>
    `http://www.google.com/search?q=${encodeURIComponent(
      `lyrics: ${title} by ${artist}`
    )}`

  const getSpotifySearchUrl = (artist, title) =>
    `https://open.spotify.com/search/${encodeURIComponent(
      `${title} ${artist}`
    )}`

  const getSongCount = () => {
    let songCount = 0
    if (courses.length > 0)
      for (const course of courses)
        songCount += course.songs.filter((song) =>
          song.status.startsWith('pending')
        ).length
    return songCount
  }

  const getNextSong = () => {
    setSong(null)
    if (courses.length > 0) {
      for (const course of courses) {
        if (course.songs.length > 0) {
          const nextSong = course.songs.find(
            (song) =>
              song.status.startsWith('pending') &&
              !skippedSongs.includes(song._id)
          )
          if (nextSong) {
            nextSong.period = course.period
            setSong(nextSong)
            setCount(count + 1)
            break
          }
        }
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
    setTotalCount(getSongCount())
  }

  const handleClose = () => {
    setCount(0)
    setOpen(false)
    setStatus(null)
    setStatusDescription(null)
    setStatusNote(null)
    setSkippedSongs([])
    setTotalCount(getSongCount())
  }

  const handleStatusButtonClick = (status) => {
    setStatus(status)
  }

  const handleStatusDescriptionChange = (ev) => {
    setStatusDescription(ev.target.value)
  }

  const handleSkip = () => {
    setSkippedSongs([...skippedSongs, song._id])
    setStatus(null)
    setStatusDescription(null)
    setStatusNote(null)
  }

  const handleSubmitApproval = async () => {
    onSubmit({
      amount:
        prime.enabled && song.student.hasPrime
          ? (1 - prime.songRequestPurchaseDiscount / 100) *
            songRequestPurchaseAmount
          : songRequestPurchaseAmount,
      id: song._id,
      status,
      statusDescription:
        statusDescription === 'other' && statusNote !== null
          ? statusNote
          : statusDescription,
    })
    setStatus(null)
    setStatusDescription(null)
    setStatusNote(null)
  }

  const refundAllowed = (hasPrime) =>
    (songRemovalRequestEnabled &&
      (!prime.enabled || !prime.songRemovalEnabled)) ||
    (prime.enabled && prime.songRemovalEnabled && hasPrime)

  const handleSubmitRemoval = async () => {
    onSubmit({
      id: song._id,
      purchaseAmount: songRemovalPurchaseAmount,
      refundAmount: refundAllowed(song.student.hasPrime)
        ? songRemovalRefundAmount
        : 0,
      status: 'removed',
      statusDescription: `${song.removedBy.firstName} ${song.removedBy.lastName} paid to remove this song`,
    })
  }

  useEffect(() => {
    setTotalCount(getSongCount())
  }, [isLoaded])

  useEffect(() => {
    getNextSong()
  }, [courses])

  useEffect(() => {
    getNextSong()
  }, [skippedSongs])

  const DroppedStudentAlert = () => (
    <Alert severity='error'>
      This student's account is currently suspended
    </Alert>
  )

  const PrimeAlert = () => (
    <>{prime.enabled && <Alert severity='success'>Prime member</Alert>}</>
  )

  return (
    <>
      <Fab color='primary' onClick={handleClickOpen} variant='extended'>
        <FabBadge value={totalCount}>
          <PlaylistAddCheck className={classes.icon} />
          Process requests
        </FabBadge>
      </Fab>

      <Dialog
        className={classes.dialog}
        fullScreen={fullScreen}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <DialogTitle>Process Song Requests</DialogTitle>
        <DialogContent>
          <ListErrors errors={errors} />
          {song ? (
            song.status !== 'pending removal' ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {song.student.dropped && <DroppedStudentAlert />}
                </Grid>
                <Grid item xs={12}>
                  {song.student.hasPrime && <PrimeAlert />}
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.panel}>
                    <div className={classes.songInfo}>
                      <Typography variant='h6'>{song.title}</Typography>
                      <Typography
                        color='textSecondary'
                        variant='body1'
                        gutterBottom
                      >
                        Song
                      </Typography>
                      <Typography variant='h6'>{song.artist}</Typography>
                      <Typography color='textSecondary' variant='body1'>
                        Artist
                      </Typography>
                    </div>

                    <ButtonGroup>
                      <Button
                        href={getLyricSearchUrl(song.artist, song.title)}
                        target='_blank'
                      >
                        Search Lyrics
                      </Button>
                      <Button
                        href={getSpotifySearchUrl(song.artist, song.title)}
                        target='_blank'
                      >
                        Search Spotify
                      </Button>
                    </ButtonGroup>

                    <div className={classes.studentInfo}>
                      <div className={classes.studentName}>
                        <Chip color='secondary' label={song.period} />
                        <Typography>
                          {song.student.firstName} {song.student.lastName}
                        </Typography>
                        {song.student.hasPrime && <PrimeBadge size='2xl' />}
                      </div>
                      <AccountBalance
                        cost={songRequestPurchaseAmount}
                        count={count}
                        username={song.student.username}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className={classes.panel}>
                    <Typography variant='h6'>Status</Typography>

                    <ButtonGroup fullWidth size='large'>
                      <Tooltip arrow enterTouchDelay={0} title='Approve'>
                        <Button
                          className={`${classes.statusButton} ${
                            status === 'approved' && classes.selected
                          }`}
                          onClick={() => handleStatusButtonClick('approved')}
                        >
                          <ThumbUp />
                        </Button>
                      </Tooltip>
                      <Tooltip arrow enterTouchDelay={0} title='Reject'>
                        <Button
                          className={`${classes.statusButton} ${
                            status === 'rejected' && classes.selected
                          }`}
                          onClick={() => handleStatusButtonClick('rejected')}
                        >
                          <ThumbDown />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                    {status === 'approved' && (
                      <div className={classes.statusDescription}>
                        <Typography gutterBottom>
                          <strong>Condition for Approval</strong> (optional)
                        </Typography>
                        <FormControl component='fieldset' fullWidth>
                          <RadioGroup
                            name='statusDescription'
                            value={statusDescription}
                            onChange={handleStatusDescriptionChange}
                          >
                            <FormControlLabel
                              value='Clean version'
                              control={<Radio />}
                              label='Clean version'
                            />
                            <FormControlLabel
                              value='Instrumental version'
                              control={<Radio />}
                              label='Instrumental version'
                            />
                            <FormControlLabel
                              value='Picked my best guess'
                              control={<Radio />}
                              label='Picked my best guess'
                            />
                            <FormControlLabel
                              value='other'
                              control={<Radio />}
                              label='Other'
                            />
                            {statusDescription === 'other' && (
                              <InputBox
                                label='Status Note'
                                name='statusNote'
                                onChange={setStatusNote}
                                value={statusNote}
                              />
                            )}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                    {status === 'rejected' && (
                      <div className={classes.statusDescription}>
                        <Typography gutterBottom>
                          <strong>Reason for Rejection</strong> (optional)
                        </Typography>
                        <FormControl component='fieldset' fullWidth>
                          <RadioGroup
                            name='statusDescription'
                            value={statusDescription}
                            onChange={handleStatusDescriptionChange}
                          >
                            <FormControlLabel
                              value='Inappropriate content (language, drugs, sex, and/or violence)'
                              control={<Radio />}
                              label='Inappropriate content (language, drugs, sex, and/or violence)'
                            />
                            <FormControlLabel
                              value='Already on playlist'
                              control={<Radio />}
                              label='Already on playlist'
                            />
                            <FormControlLabel
                              value='Not available on Spotify'
                              control={<Radio />}
                              label='Not available on Spotify'
                            />
                            <FormControlLabel
                              value='other'
                              control={<Radio />}
                              label='Other'
                            />
                            {statusDescription === 'other' && (
                              <InputBox
                                label='Status Note'
                                name='statusNote'
                                onChange={setStatusNote}
                                value={statusNote}
                              />
                            )}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {song.removedBy.dropped && <DroppedStudentAlert />}
                </Grid>
                <Grid item xs={12}>
                  {song.removedBy.hasPrime && <PrimeAlert />}
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.panel}>
                    <div className={classes.songInfo}>
                      <Typography variant='h6'>{song.title}</Typography>
                      <Typography
                        color='textSecondary'
                        variant='body1'
                        gutterBottom
                      >
                        Song
                      </Typography>
                      <Typography variant='h6'>{song.artist}</Typography>
                      <Typography color='textSecondary' variant='body1'>
                        Artist
                      </Typography>
                    </div>

                    <div className={classes.removalAlert}>
                      Removal Requested by{' '}
                      {`${song.removedBy.firstName} ${song.removedBy.lastName}`}
                      {song.removedBy.hasPrime && <PrimeBadge />}
                    </div>

                    <div className={classes.studentInfo}>
                      <div className={classes.studentName}>
                        <Chip color='secondary' label={song.period} />
                        <Typography>
                          {song.student.firstName} {song.student.lastName}
                        </Typography>
                        {song.student.hasPrime && <PrimeBadge size='2xl' />}
                      </div>
                      <AccountBalance
                        cost={songRemovalPurchaseAmount}
                        count={count}
                        username={song.removedBy.username}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            )
          ) : (
            <Alert severity='info'>
              <AlertTitle>All caught up!</AlertTitle>
              There are no songs to process at this time
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          {song && (
            <Typography
              style={{ marginRight: 'auto' }}
              variant='subtitle2'
            >{`Song ${count} of ${totalCount}`}</Typography>
          )}
          {song &&
            (song.status !== 'pending removal' ? (
              <Button
                color='primary'
                disabled={inProgress || !status}
                onClick={handleSubmitApproval}
                variant='contained'
              >
                {inProgress ? 'Saving...' : 'Save & Continue'}
              </Button>
            ) : (
              <Button
                color='secondary'
                disabled={inProgress}
                onClick={handleSubmitRemoval}
                variant='contained'
              >
                {inProgress ? 'Removing...' : 'Remove & Continue'}
              </Button>
            ))}
          {song && (
            <Button
              disabled={inProgress}
              onClick={handleSkip}
              variant='contained'
            >
              Skip
            </Button>
          )}
          <Button
            disabled={inProgress}
            onClick={handleClose}
            variant='contained'
          >
            {song ? 'Cancel' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessSongRequestsDialog)
