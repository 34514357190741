import {
  ADD_TEACHER,
  ASYNC_START,
  DELETE_TEACHER,
  MANAGE_TEACHERS_PAGE_LOADED,
  MANAGE_TEACHERS_PAGE_UNLOADED,
  TEACHER_DIALOG_UNLOADED,
  UPDATE_TEACHER,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  inProgress: false,
  pageLoaded: false,
  teachers: [],
}

const sortByLastName = (a, b) => a.lastName.localeCompare(b.lastName)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_TEACHER:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        teachers: action.error
          ? state.teachers
          : [
              ...state.teachers,
              { ...action.payload.user, settings: action.payload.settings },
            ].sort((a, b) => sortByLastName(a, b)),
      }
    case ASYNC_START:
      if (action.subtype === ADD_TEACHER || action.subtype === UPDATE_TEACHER) {
        return { ...state, inProgress: true, errors: null }
      } else if (action.subtype === MANAGE_TEACHERS_PAGE_LOADED) {
        return { ...state, errors: null, inProgress: true, pageLoaded: false }
      }
      break
    case DELETE_TEACHER:
      return {
        ...state,
        teachers: action.error
          ? state.teachers
          : state.teachers.filter(
              (teacher) => teacher.username !== action.payload.user.username
            ),
      }
    case MANAGE_TEACHERS_PAGE_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        pageLoaded: true,
        teachers: action.payload.users,
      }
    case MANAGE_TEACHERS_PAGE_UNLOADED:
      return defaultState
    case TEACHER_DIALOG_UNLOADED:
      return { ...state, errors: null }
    case UPDATE_TEACHER:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        teachers: action.error
          ? state.teachers
          : state.teachers
              .map((user) =>
                user.username === action.payload.user.username
                  ? { ...user, ...action.payload.user }
                  : user
              )
              .sort((a, b) => sortByLastName(a, b)),
      }
    default:
      return state
  }

  return state
}
