import {
  ADD_PRODUCT,
  ASYNC_START,
  PRODUCT_DIALOG_UNLOADED,
  PRODUCT_SELECTOR_LOADED,
  PRODUCT_SELECTOR_UNLOADED,
  PRODUCTS_PAGE_LOADED,
  PRODUCTS_PAGE_UNLOADED,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
} from '../constants/actionTypes'

const defaultState = {
  products: [],
  errors: null,
  inProgress: false,
  pageLoaded: false,
}

const sortByCost = (a, b) => a.cost - b.cost

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        products: action.error
          ? state.products
          : [...state.products, action.payload.product].sort((a, b) =>
              sortByCost(a, b)
            ),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case ASYNC_START:
      if (action.subtype === ADD_PRODUCT || action.subtype === UPDATE_PRODUCT) {
        return { ...state, errors: null, inProgress: true }
      } else if (
        action.subtype === PRODUCT_SELECTOR_LOADED ||
        action.subtype === PRODUCTS_PAGE_LOADED
      ) {
        return { ...state, errors: null, inProgress: true, pageLoaded: false }
      }
      break
    case DELETE_PRODUCT:
      return {
        ...state,
        products: action.error
          ? state.products
          : state.products.filter(
              (product) => product.slug !== action.payload.product.slug
            ),
      }
    case PRODUCT_DIALOG_UNLOADED:
      return { ...state, errors: null }
    case PRODUCT_SELECTOR_LOADED:
    case PRODUCTS_PAGE_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        pageLoaded: true,
        products: action.error ? state.products : action.payload.products,
      }
    case PRODUCT_SELECTOR_UNLOADED:
    case PRODUCTS_PAGE_UNLOADED:
      return defaultState
    case UPDATE_PRODUCT:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        products: action.error
          ? state.products
          : state.products
              .map((product) =>
                product._id === action.payload.product._id
                  ? action.payload.product
                  : product
              )
              .sort((a, b) => sortByCost(a, b)),
      }
    default:
      return state
  }

  return state
}
