import {
  ASYNC_START,
  DELETE_SONG_REQUEST,
  SONG_REQUESTS_DIALOG_UNLOADED,
  SONGS_PAGE_UNLOADED,
  STUDENT_SONGS_PAGE_LOADED,
  SUBMIT_SONG_REQUEST,
  SUBMIT_SONG_REMOVAL_REQUEST,
  TEACHER_SONGS_PAGE_LOADED,
  UPDATE_SONG_REQUEST,
} from '../constants/actionTypes'

const defaultState = {
  course: null,
  courses: [],
  courseSongs: [],
  errors: null,
  inProgress: false,
  isLoaded: false,
  userSongs: [],
}

export default (state = defaultState, action) => {
  let courses = []
  switch (action.type) {
    case ASYNC_START:
      if (action.subtype === UPDATE_SONG_REQUEST) {
        return { ...state, inProgress: true, errors: null }
      }
      break
    case DELETE_SONG_REQUEST:
      // Changing a deep-nested array requires deep-copying
      // to avoid altering the previous state
      state.courses.forEach((course) => {
        const courseCopy = { ...course }
        courseCopy.songs = courseCopy.songs.filter(
          (song) => song._id.toString() !== action.payload.song._id.toString()
        )
        courses.push(courseCopy)
      })
      return {
        ...state,
        courses: action.error ? state.courses : courses,
      }
    case SONG_REQUESTS_DIALOG_UNLOADED:
      return { ...state, errors: null }
    case SONGS_PAGE_UNLOADED:
      return defaultState
    case STUDENT_SONGS_PAGE_LOADED:
      return {
        ...state,
        userSongs: action.payload.songsByUser,
        courseSongs: action.payload.songsByCourse,
        course: action.payload.course,
      }
    case SUBMIT_SONG_REQUEST:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null, // @todo: the student song request dialog does not support rendering server errors at thist time
        inProgress: false,
        userSongs: action.error
          ? state.userSongs
          : [action.payload.song, ...state.userSongs],
        courseSongs: action.error
          ? state.courseSongs
          : [action.payload.song, ...state.courseSongs],
      }
    case SUBMIT_SONG_REMOVAL_REQUEST:
      return {
        ...state,
        userSongs: state.userSongs.map((song) =>
          action.payload.song._id === song._id ? action.payload.song : song
        ),
        courseSongs: state.courseSongs.map((song) =>
          action.payload.song._id === song._id ? action.payload.song : song
        ),
      }
    case TEACHER_SONGS_PAGE_LOADED:
      return { ...state, courses: action.payload.courses, isLoaded: true }
    case UPDATE_SONG_REQUEST:
      // Changing a deep-nested array requires deep-copying
      // to avoid altering the previous state
      !action.error &&
        state.courses.forEach((course) => {
          const courseCopy = { ...course }
          courseCopy.songs = courseCopy.songs.map((song) =>
            song._id.toString() === action.payload.song._id.toString()
              ? action.payload.song
              : song
          )
          courses.push(courseCopy)
        })
      return {
        ...state,
        courses: action.error ? state.courses : courses,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    default:
      return state
  }

  return state
}
