import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Gavel, Grade, ShoppingBasket } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import BonusSelector from './BonusSelector'
import FineSelector from '../Fines/FineSelector'
import ListErrors from '../ListErrors'
import ProductSelector from '../Products/ProductSelector'
import agent from '../../agent'

import {
  ADD_TRANSACTION,
  TRANSACTION_DIALOG_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.scores,
  bonuses: state.bonuses.bonuses,
  fines: state.fines.fines,
  products: state.products.products,
  taxRate: state.common.settings.taxRate,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (transaction) =>
    dispatch({
      type: ADD_TRANSACTION,
      payload: agent.Transactions.add(transaction),
      snackbar: {
        message: `${transaction.typeTitle} added`,
        variant: 'success',
      },
    }),
  onExit: () => dispatch({ type: TRANSACTION_DIALOG_UNLOADED }),
})

const TransactionDialog = ({
  bonuses,
  errors,
  fines,
  inProgress,
  onAdd,
  onExit,
  student,
  taxRate,
  type,
}) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const getCost = (amt) => Math.round(amt * (taxRate / 100 + 1))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values, student, type: type.toLowerCase(), typeTitle: type })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      <ActionButton
        action={`Add ${
          type === 'Purchase' ? 'quick' : ''
        } ${type.toLowerCase()}`}
        onClick={handleClickOpen}
      >
        {type === 'Bonus' && <Grade />}
        {type === 'Fine' && <Gavel />}
        {type === 'Purchase' && <ShoppingBasket />}
      </ActionButton>

      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <Formik
          initialValues={{
            amount: '',
            description: '',
            productId: '',
          }}
          validationSchema={Yup.object({
            amount: Yup.number().typeError('Numbers only').required('Required'),
            description: Yup.string().required('Required'),
            productId: Yup.string(),
          })}
          onSubmit={async (values) => {
            handleSubmitAdd(values)
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>
                Add {type === 'Purchase' && 'Quick'} {type}
              </DialogTitle>
              <DialogContent>
                <ListErrors errors={errors} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {type === 'Bonus' ? (
                      <BonusSelector
                        bonuses={bonuses}
                        onChange={(bonus) => {
                          if (typeof bonus === 'string') {
                            formik.setFieldValue('description', bonus)
                          } else if (bonus && typeof bonus === 'object') {
                            formik.setFieldValue('amount', bonus.amount)
                            formik.setFieldValue(
                              'description',
                              bonus.description
                            )
                          } else {
                            formik.setFieldValue('amount', '')
                            formik.setFieldValue('description', '')
                          }
                        }}
                      />
                    ) : type === 'Fine' ? (
                      <FineSelector
                        fines={fines}
                        onChange={(fine) => {
                          if (typeof fine === 'string') {
                            formik.setFieldValue('description', fine)
                          } else if (fine && typeof fine === 'object') {
                            formik.setFieldValue('amount', fine.amount)
                            formik.setFieldValue(
                              'description',
                              fine.description
                            )
                          } else {
                            formik.setFieldValue('amount', '')
                            formik.setFieldValue('description', '')
                          }
                        }}
                      />
                    ) : (
                      <ProductSelector
                        onChange={(product) => {
                          if (typeof product === 'string') {
                            formik.setFieldValue('description', product)
                          } else if (product && typeof product === 'object') {
                            formik.setFieldValue(
                              'amount',
                              getCost(product.cost)
                            )
                            formik.setFieldValue('description', product.name)
                            formik.setFieldValue('productId', product._id)
                          } else {
                            formik.setFieldValue('amount', '')
                            formik.setFieldValue('description', '')
                          }
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Amount'
                      name='amount'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={inProgress}
                  color='primary'
                  type='submit'
                  variant='contained'
                >
                  {inProgress ? 'Adding...' : 'Add'}
                </Button>
                <Button
                  disabled={inProgress}
                  onClick={handleClose}
                  variant='contained'
                >
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDialog)
