import {
  ADD_TO_QUEUE,
  ALERT_CLOSED,
  ASYNC_START,
  CREATE_PLAYLIST,
  GET_PLAYLIST,
  GET_QUEUE,
  JUKEBOX_DIALOG_UNLOADED,
  JUKEBOX_STUDENT_DIALOG_LOADED,
  JUKEBOX_TEACHER_DIALOG_LOADED,
  PLAYLIST_MANAGER_DIALOG_UNLOADED,
  REMOVE_PLAYLIST,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  inProgress: false,
  playlists: [],
  queue: { currently_playing: null, queue: null },
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_TO_QUEUE:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
      }
    case ALERT_CLOSED:
      return { ...state, errors: null }
    case ASYNC_START:
      if (
        action.subtype === GET_PLAYLIST ||
        action.subtype === GET_QUEUE ||
        action.subtype === JUKEBOX_STUDENT_DIALOG_LOADED ||
        action.subtype === JUKEBOX_TEACHER_DIALOG_LOADED
      ) {
        return { ...state, errors: null, inProgress: true }
      } else if (action.subtype === ADD_TO_QUEUE) {
        return { ...state, errors: null }
      }
      break
    case CREATE_PLAYLIST:
    case GET_PLAYLIST:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        playlists: action.error
          ? state.playlists
          : [...state.playlists, action.payload.playlist],
      }
    case GET_QUEUE:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        queue: action.error ? state.queue : action.payload.queue,
      }
    case JUKEBOX_DIALOG_UNLOADED:
    case PLAYLIST_MANAGER_DIALOG_UNLOADED:
      return defaultState
    case JUKEBOX_STUDENT_DIALOG_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        playlists: action.error
          ? state.playlists
          : [action.payload[0].playlist],
      }
    case JUKEBOX_TEACHER_DIALOG_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        playlists: action.error ? state.playlists : [action.payload.playlist],
      }
    case REMOVE_PLAYLIST:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        playlists: action.error
          ? state.playlists
          : state.playlists.filter(
              (playlist) => playlist.id !== action.payload.playlistId
            ),
      }
    default:
      return state
  }

  return state
}
