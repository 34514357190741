import React from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'

import { LOGOUT } from '../constants/actionTypes'

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch({ type: LOGOUT }),
})

const LogoutButton = ({ onLogout }) => (
  <Button color='inherit' onClick={onLogout}>
    Logout
  </Button>
)

export default connect(() => ({}), mapDispatchToProps)(LogoutButton)
