import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { Switch, TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import moment from 'moment'
import { titleCase } from 'title-case'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Add, Wc } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'

import ActionButton from '../ActionButton'
import AutocompleteSelector from '../AutocompleteSelector'
import ListErrors from '../ListErrors'
import agent from '../../agent'

import {
  ADD_PASS,
  GET_LAST_PASS,
  LOAD_STUDENT_PASSES,
  LOAD_TEACHER_PASS_DIALOG,
} from '../../constants/actionTypes'

const DESTINATIONS = [
  'classroom',
  'counselor',
  'gym',
  'health office',
  'library',
  'office',
  'restroom',
  'social worker',
]

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  ...state.passes,
  ...state.common.settings,
  accountBalance: state.scores.total?.balance,
  role: state.common.currentUser.role,
  teacher: state.common.settings.user,
})

const mapDispatchToProps = (dispatch) => ({
  getLastPass: () =>
    dispatch({ type: GET_LAST_PASS, payload: agent.Passes.getLastPass() }),
  onAdd: (pass) =>
    dispatch({
      type: ADD_PASS,
      payload: agent.Passes.add(pass),
      snackbar: {
        message: 'Pass created',
        variant: 'success',
      },
    }),
  onLoadStudent: () =>
    dispatch({
      type: LOAD_STUDENT_PASSES,
      payload: agent.Passes.getAll(),
    }),
  onLoadTeacher: (studentId) =>
    dispatch({
      type: LOAD_TEACHER_PASS_DIALOG,
      payload: agent.Passes.getStudentPasses(studentId),
    }),
})

const ActivePassAlert = ({ activePassExists, lastPass, role }) => (
  <>
    {activePassExists && (
      <Grid item xs={12}>
        <Alert severity='error'>
          <AlertTitle>Active Hallpass</AlertTitle>
          {role === 'student'
            ? `An active hallpass already exists for another student. Please try again in ${lastPass?.duration} minutes. If this is an emergency, please see your teacher.`
            : `An active hallpass already exists for ${
                lastPass?.student.firstName
              } ${lastPass?.student.lastName} to go to the ${
                lastPass?.destination
              } and was created at ${moment(lastPass?.createdAt).format(
                'LT'
              )} with a duration of ${lastPass?.duration} minutes`}
        </Alert>
      </Grid>
    )}
  </>
)

const InsufficientFundsAlert = ({ accountBalance, canAfford, role }) => (
  <>
    {!canAfford && (
      <Grid item xs={12}>
        <Alert severity='error'>
          <AlertTitle>Insufficient Funds</AlertTitle>
          {role === 'student'
            ? `You do not have enough points to purchase a hallpass. If this is an emergency, please see your teacher.`
            : `This student has ${accountBalance} pts which is less than the cost of a hallpass`}
        </Alert>
      </Grid>
    )}
  </>
)

const PassLimitExceededAlert = ({ passes, passLimitExceeded, role }) => (
  <>
    {passLimitExceeded && (
      <Grid item xs={12}>
        <Alert severity='error'>
          <AlertTitle>Hallpass Limit Reached</AlertTitle>
          {role === 'student'
            ? `You have reached your pass limit. If this is an emergency, please see your teacher.`
            : `This student has ${passes.length} passes and has exceeded their pass limit`}
        </Alert>
      </Grid>
    )}
  </>
)

const StudentGeneratedPassAlert = ({ allow, role }) => (
  <>
    {!allow && role === 'student' && (
      <Grid item xs={12}>
        <Alert severity='info'>Please see your teacher for a hallpass</Alert>
      </Grid>
    )}
  </>
)

const PassDisplay = ({ onClose, pass, roomNumber, status, teacher }) => {
  const PassItemRow = ({ title, value }) => (
    <>
      <Grid item xs={3}>
        <Typography>
          <b>{title}:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{value}</Typography>
      </Grid>
    </>
  )

  return (
    <>
      <DialogTitle>Hallpass</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {status === 'expired' ? (
            <Grid item xs={12}>
              <Alert severity='error'>
                <AlertTitle>Hallpass Expired</AlertTitle>
                Your hallpass for today is expired. If you need a new hallpass,
                please see your teacher.
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Alert severity='info'>
                  This hallpass is good for {pass.duration} minutes and expires
                  at{' '}
                  {moment(pass.createdAt)
                    .add(pass.duration, 'minutes')
                    .format('LT')}
                </Alert>
              </Grid>
              <Grid container spacing={1} item xs={12}>
                <PassItemRow
                  title={'Student'}
                  value={`${pass.student.firstName} ${pass.student.lastName}`}
                />
                <PassItemRow title={'From'} value={roomNumber} />
                <PassItemRow title={'To'} value={titleCase(pass.destination)} />
                <PassItemRow
                  title={'Date'}
                  value={moment(pass.createdAt).format('ll')}
                />
                <PassItemRow
                  title={'Time'}
                  value={moment(pass.createdAt).format('LT')}
                />
                <PassItemRow
                  title={'Teacher'}
                  value={`${teacher.title} ${teacher.lastName}`}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='contained'>
          Close
        </Button>
      </DialogActions>
    </>
  )
}

const PassForm = ({
  accountBalance,
  activePassExists,
  cost,
  errors,
  hallpass,
  inProgress,
  lastPass,
  onClose,
  onSubmit,
  pageLoaded,
  passes,
  passLimitExceeded,
  role,
}) => {
  const canAfford =
    (!hallpass.allowPurchaseWithInsufficientFunds && accountBalance >= cost) ||
    hallpass.allowPurchaseWithInsufficientFunds

  const showForm =
    (role === 'student' &&
      !activePassExists &&
      hallpass.allowStudentCreation &&
      !passLimitExceeded &&
      canAfford) ||
    role === 'teacher'

  return (
    <Formik
      initialValues={{
        createdBy: role,
        destination: 'restroom',
        doCharge: true,
        duration: hallpass.defaultDuration,
      }}
      validationSchema={Yup.object({
        createdBy: Yup.string().required('Required'),
        destination: Yup.string().required('Required'),
        doCharge: Yup.boolean(),
        duration: Yup.number().typeError('Numbers only').required('Required'),
      })}
      onSubmit={async (values) => {
        onSubmit(values)
      }}
    >
      {(formik) => (
        <Form>
          <DialogTitle>
            {role === 'student' ? 'Purchase Hallpass' : 'Create Hallpass'}
          </DialogTitle>
          <DialogContent>
            <ListErrors errors={errors} />

            <Grid container spacing={2}>
              <ActivePassAlert
                activePassExists={activePassExists}
                lastPass={lastPass}
                role={role}
              />
              <InsufficientFundsAlert
                accountBalance={accountBalance}
                canAfford={canAfford}
                role={role}
              />
              <PassLimitExceededAlert
                passes={passes}
                passLimitExceeded={passLimitExceeded}
                role={role}
              />
              <StudentGeneratedPassAlert
                allow={hallpass.allowStudentCreation}
                role={role}
              />
              {showForm && role === 'student' && (
                <Grid item xs={12}>
                  <Typography variant='body1' gutterBottom>
                    This will charge your account <b>{cost} pts</b>
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    <em>
                      If you need a pass to a different destination or for a
                      longer duration, please see your teacher
                    </em>
                  </Typography>
                </Grid>
              )}
              {showForm && (
                <Grid item xs={12} md={6}>
                  <AutocompleteSelector
                    allowAny
                    defaultValue='restroom'
                    disabled={inProgress || role === 'student'}
                    label='Destination'
                    onChange={(value) => {
                      formik.setFieldValue('destination', value)
                    }}
                    options={DESTINATIONS}
                  />
                </Grid>
              )}
              {showForm && (
                <Grid item xs={12} md={6}>
                  <Field
                    color='primary'
                    component={TextField}
                    disabled={inProgress || role === 'student'}
                    label='Duration (minutes)'
                    name='duration'
                    type='text'
                    fullWidth
                  />
                </Grid>
              )}
              {role === 'teacher' && (
                <Grid item xs={12} md={6}>
                  <label>
                    <Field component={Switch} name='doCharge' type='checkbox' />
                    <b>Cost: </b>
                    {formik.values.doCharge ? `${cost} pts` : 'Free'}
                  </label>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {showForm && (
              <Button
                disabled={inProgress || !pageLoaded}
                color='primary'
                type='submit'
                variant='contained'
              >
                {inProgress ? 'Creating...' : 'Create'}
              </Button>
            )}
            <Button disabled={inProgress} onClick={onClose} variant='contained'>
              {showForm ? 'Cancel' : 'Close'}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

const PassDialog = ({
  accountBalance,
  buttonType,
  errors,
  hallpass,
  getLastPass,
  inProgress,
  lastPass,
  onAdd,
  onLoadStudent,
  onLoadTeacher,
  pageLoaded,
  passes,
  prime,
  role,
  roomNumber,
  student,
  teacher,
}) => {
  const [open, setOpen] = useState(false)
  const [passStatus, setPassStatus] = useState(null)
  const [activePassExists, setActivePassExists] = useState(false)
  const [passLimitExceeded, setPassLimitExceeded] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getHallpassAmount = () =>
    prime.enabled && student.hasPrime
      ? (1 - prime.hallpassDiscount / 100) * hallpass.purchaseAmount
      : hallpass.purchaseAmount

  const handleSubmitAdd = (values) => {
    onAdd({
      cost: values.doCharge || role === 'student' ? getHallpassAmount() : 0,
      createdBy: values.createdBy,
      destination: values.destination,
      duration: values.duration,
      student,
    })
  }

  const getPassStatus = (passes) => {
    if (passes.length > 0) {
      const pass = passes[0]
      const curTime = moment()
      const passTime = moment(pass.createdAt)
      const isSameDay = passTime.isSame(curTime, 'day')
      const elapsedTime = moment.duration(curTime.diff(passTime)).as('minutes')
      if (!isSameDay) return null
      if (elapsedTime > pass.duration) return 'expired'
      return 'active'
    }
    return null
  }

  const getPassLimitExceeded = (passes) => {
    if (hallpass.limit === 0) return false
    return passes.length >= hallpass.limit
  }

  useEffect(() => {
    // Close the dialog for teachers only if there are no errors
    if (role === 'teacher' && !inProgress && !errors) setOpen(false)
  }, [inProgress])

  useEffect(() => {
    // Load pass data when dialog is opened by a student user
    if (open && role === 'student') onLoadStudent()

    // Load pass data for the selected student when dialog is opened by a teacher user
    if (open && role === 'teacher') onLoadTeacher(student._id)

    // Load last pass data for all users
    if (open) getLastPass()
  }, [open])

  useEffect(() => {
    if (role === 'student') setPassStatus(getPassStatus(passes))
    setPassLimitExceeded(getPassLimitExceeded(passes))
  }, [passes])

  useEffect(() => {
    const lastPassStatus = lastPass ? getPassStatus([lastPass]) : null
    setActivePassExists(lastPassStatus === 'active' ? true : false)
  }, [lastPass])

  return (
    <>
      {hallpass.enabled && buttonType === 'icon' && (
        <ActionButton
          action={role === 'teacher' ? 'Create hallpass' : 'Purchase hallpass'}
          onClick={handleClickOpen}
        >
          <Wc />
        </ActionButton>
      )}

      {hallpass.enabled && buttonType === 'fab' && (
        <Fab color='primary' onClick={handleClickOpen} variant='extended'>
          <Add className={classes.icon} />
          Buy a pass
        </Fab>
      )}

      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
        onClick={(ev) => ev.stopPropagation()}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        {passStatus && passes.length > 0 ? (
          <PassDisplay
            onClose={handleClose}
            pass={passes[0]}
            roomNumber={roomNumber}
            status={passStatus}
            teacher={teacher}
          />
        ) : (
          <PassForm
            accountBalance={accountBalance}
            activePassExists={activePassExists}
            cost={getHallpassAmount()}
            errors={errors}
            hallpass={hallpass}
            inProgress={inProgress}
            lastPass={lastPass}
            onClose={handleClose}
            onSubmit={handleSubmitAdd}
            pageLoaded={pageLoaded}
            passes={passes}
            passLimitExceeded={passLimitExceeded}
            role={role}
          />
        )}
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PassDialog)
