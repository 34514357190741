import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Dialog,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Assessment } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import DialogAppBar from '../DialogAppBar'
import ListErrors from '../ListErrors'
import PageHeader from '../PageHeader'
import PageWrapper from '../PageWrapper'
import Spinner from '../Spinner'
import TaskDataChart from './TaskDataChart'
import TaskDataDashboard from './TaskDataDashboard'
import TaskDataTable from './TaskDataTable'
import { SlideUpTransition } from '../../transitions'
import agent from '../../agent'

import {
  LOAD_TASK_SCORES,
  UNLOAD_TASK_SCORES,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.primary,
  },
}))

const mapStateToProps = (state) => ({
  ...state.tasks,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: (taskID) =>
    dispatch({
      type: LOAD_TASK_SCORES,
      payload: agent.Scores.getTaskScores(taskID),
    }),
  onUnload: () => dispatch({ type: UNLOAD_TASK_SCORES }),
})

const TaskDataDialog = ({
  course,
  errors,
  inProgress,
  onLoad,
  onUnload,
  scores,
  task,
}) => {
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const handleClickOpen = () => {
    onLoad(task.id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    onUnload()
  }

  return (
    <>
      <ActionButton
        action='View data'
        className={classes.icon}
        onClick={handleClickOpen}
      >
        <Assessment />
      </ActionButton>

      <Dialog
        fullScreen
        open={open}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        TransitionComponent={SlideUpTransition}
      >
        <DialogAppBar onClose={handleClose} title='Assignment Data Viewer' />
        <PageWrapper>
          <Container>
            <PageHeader title={task.title}>
              <Typography variant='h5'>
                Period {course.period} | {course.name}
              </Typography>
            </PageHeader>
            <ListErrors errors={errors} />
            {inProgress ? (
              <Spinner message='Loading...' />
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TaskDataDashboard scores={scores} task={task} />
                </Grid>
                <Grid item xs={12}>
                  <TaskDataChart course={course} scores={scores} task={task} />
                </Grid>
                <Grid item xs={12}>
                  <TaskDataTable scores={scores} task={task} />
                </Grid>
              </Grid>
            )}
          </Container>
        </PageWrapper>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDataDialog)
