import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import ListErrors from '../ListErrors'

import agent from '../../agent'

import {
  NOTIFICATION_DIALOG_LOADED,
  NOTIFICATION_DIALOG_UNLOADED,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  alert: {
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.notifications,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: NOTIFICATION_DIALOG_LOADED,
      payload: agent.Notifications.getAll(),
    }),
  onUnload: () =>
    dispatch({
      type: NOTIFICATION_DIALOG_UNLOADED,
      payload: agent.Notifications.clearAll(),
    }),
})

const NotificationDialog = ({ errors, notifications, onLoad, onUnload }) => {
  const [open, setOpen] = useState(false)

  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    setOpen(false)
    onUnload()
  }

  useEffect(() => {
    onLoad()
  }, [])

  // Automatically show the notification center if there are notifications
  useEffect(() => {
    if (notifications.length > 0) setOpen(true)
  }, [notifications])

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      onClick={(ev) => ev.stopPropagation()}
      onFocus={(ev) => ev.stopPropagation()}
    >
      <DialogTitle>Notification Center</DialogTitle>
      <DialogContent>
        <ListErrors errors={errors} />
        {notifications.length > 0 ? (
          notifications.map((notification, index) => (
            <Alert
              className={classes.alert}
              key={index}
              severity={notification.type}
            >
              <AlertTitle>{notification.title}</AlertTitle>
              {notification.content}
            </Alert>
          ))
        ) : (
          <Alert severity='info'>
            <AlertTitle>All caught up!</AlertTitle>
            You have no notifications at this time
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDialog)
