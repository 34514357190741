import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import agent from '../../agent'

import { DELETE_TEACHER } from '../../constants/actionTypes'

const mapDispatchToProps = (dispatch) => ({
  onDelete: (user) =>
    dispatch({
      type: DELETE_TEACHER,
      payload: agent.Teachers.delete(user),
      snackbar: { message: 'Teacher deleted', variant: 'success' },
    }),
})

const DeleteTeacherAlert = ({ onDelete, teacher }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    setOpen(false)
    onDelete(teacher)
  }

  return (
    <>
      <ActionButton action='Delete teacher' onClick={handleClickOpen}>
        <Delete />
      </ActionButton>
      <Dialog
        open={open}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
      >
        <DialogTitle>Delete Teacher</DialogTitle>
        <DialogContent>
          <DialogContentText component='div'>
            Are you sure you want to delete{' '}
            <b>
              {teacher.firstName} {teacher.lastName}
            </b>
            ? This action will permanently delete:
            <ul>
              <li>all student accounts</li>
              <li>all song requests</li>
              <li>all notifications</li>
              <li>all transactions</li>
              <li>all hallpasses</li>
              <li>all purchases</li>
              <li>all products</li>
              <li>all bonuses</li>
              <li>all scores</li>
            </ul>
            associated with this teacher. This action <b>cannot be undone</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color='secondary'
            variant='contained'
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleClose} variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(() => ({}), mapDispatchToProps)(DeleteTeacherAlert)
