import {
  ASYNC_START,
  JUKEBOX_REQUEST_SUBMIT,
  SETTINGS_PAGE_UNLOADED,
  SETTINGS_SAVED,
  SPOTIFY_LINKER_LOADED,
  SPOTIFY_LINKER_UNLOADED,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  inProgress: false,
  spotifyAuthUrl: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        action.subtype === JUKEBOX_REQUEST_SUBMIT ||
        action.subtype === SETTINGS_SAVED
      ) {
        return {
          ...state,
          inProgress: true,
        }
      }
      break
    case JUKEBOX_REQUEST_SUBMIT:
    case SETTINGS_SAVED:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null,
      }
    case SPOTIFY_LINKER_LOADED:
      return { ...state, spotifyAuthUrl: action.payload.spotifyAuthUrl }
    case SETTINGS_PAGE_UNLOADED:
    case SPOTIFY_LINKER_UNLOADED:
      return defaultState
    default:
      return state
  }

  return state
}
