import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import agent from '../../agent'

import { JUKEBOX_REQUEST_SUBMIT } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2),
  },
}))

const mapStateToProps = (state) => ({
  darkMode: state.common.currentUser.darkMode,
})

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) =>
    dispatch({
      type: JUKEBOX_REQUEST_SUBMIT,
      payload: agent.Settings.jukebox(values),
      snackbar: { message: 'Jukebox access requested', variant: 'success' },
    }),
})

const JukeboxRequestDialog = ({ darkMode, onSubmit, requested }) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        className={classes.button}
        color='secondary'
        disabled={requested}
        onClick={handleClickOpen}
        variant={darkMode ? 'outlined' : 'contained'}
      >
        {requested ? 'Request has been sent' : 'Request Access to Jukebox'}
      </Button>

      <Dialog
        fullScreen={fullScreen}
        maxWidth='md'
        onClose={handleClose}
        open={open}
      >
        <Formik
          initialValues={{
            name: '',
            email: '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email().required('Required'),
          })}
          onSubmit={async (values) => {
            onSubmit(values)
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>Jukebox Request</DialogTitle>
              {requested ? (
                <DialogContent>
                  <DialogContentText>
                    Your request to enable Jukebox for your account has been
                    received. Please allow 24 to 48 hours for a response to
                    inform you that Jukebox has been enabled for your account.
                  </DialogContentText>
                </DialogContent>
              ) : (
                <DialogContent>
                  <DialogContentText>
                    Jukebox makes use of the{' '}
                    <Link
                      href='https://developer.spotify.com/documentation/web-api'
                      target='_blank'
                    >
                      Spotify Web API
                    </Link>{' '}
                    to connect your Spotify account to Chem Cash. Currently the
                    Chem Cash app is in{' '}
                    <Link
                      href='https://developer.spotify.com/documentation/web-api/concepts/quota-modes'
                      target='_blank'
                    >
                      Development mode
                    </Link>{' '}
                    on Spotify, which requires users of the app to be added to
                    an allowlist. Please provide your name and email address
                    used on your Spotify account below. Once you have been added
                    to the allowlist, you will be able to return here to setup
                    Jukebox.
                  </DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Field
                        autoFocus
                        component={TextField}
                        label='Spotify Name'
                        name='name'
                        type='text'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TextField}
                        label='Spotify Email'
                        name='email'
                        type='email'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              )}
              <DialogActions>
                {!requested && (
                  <Button
                    color='primary'
                    disabled={!(formik.isValid && formik.dirty)}
                    type='submit'
                    variant='contained'
                  >
                    Submit
                  </Button>
                )}
                <Button onClick={handleClose} variant='contained'>
                  {requested ? 'Close' : 'Cancel'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JukeboxRequestDialog)
