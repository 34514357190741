import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import LogoutButton from '../LogoutButton'

const AccountSuspendedDialog = ({ user }) => {
  return (
    <Dialog open={user.dropped}>
      <DialogTitle>Account Suspended</DialogTitle>
      <DialogContent>
        Your account has been suspended. Please speak with your teacher if you
        have any questions regarding your account suspension.
      </DialogContent>
      <DialogActions>
        <LogoutButton />
      </DialogActions>
    </Dialog>
  )
}

export default AccountSuspendedDialog
