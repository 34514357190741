import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Assignment } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import ListErrors from '../ListErrors'
import TasksTable from './TasksTable'

import { TASKS_DIALOG_UNLOADED } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& > .MuiDialog-container > .MuiPaper-root': {
      [theme.breakpoints.up('lg')]: {
        minHeight: theme.spacing(54),
        width: theme.spacing(100),
      },
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.tasks,
})

const mapDispatchToProps = (dispatch) => ({
  onExit: () => dispatch({ type: TASKS_DIALOG_UNLOADED }),
})

const TasksDialog = ({ course, errors, inProgress, onExit }) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <ActionButton action='Manage assignments' onClick={handleClickOpen}>
        <Assignment />
      </ActionButton>

      <Dialog
        className={classes.dialog}
        fullScreen={fullScreen}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <DialogTitle>Manage Assignments</DialogTitle>
        <DialogContent>
          <ListErrors errors={errors} />
          <TasksTable course={course} />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={inProgress}
            onClick={handleClose}
            variant='contained'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksDialog)
