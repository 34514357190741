import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Chip, Tooltip } from '@material-ui/core'
import MaterialTable from 'material-table'

import Points from '../Points'
import PrimeBadge from '../Prime/PrimeBadge'
import PurchaseDetailsList from './PurchaseDetailsList'
import agent from '../../agent'

import { DELETE_PURCHASE } from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  courses: state.purchases.courses,
  prime: state.common.settings.prime,
  taxRate: state.common.settings.taxRate,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (id) =>
    dispatch({
      type: DELETE_PURCHASE,
      payload: agent.Purchases.delete(id),
      snackbar: { message: 'Purchase deleted', variant: 'success' },
    }),
})

const ManagePurchasesTable = ({ courses, onDelete, prime, taxRate }) => {
  const [data, setData] = useState([])

  const primeRate = prime.storePurchaseDiscount
  const isPrime = prime.enabled && primeRate > 0

  const mappedData = courses
    .map((course) =>
      course.purchases.map((purchase) => ({
        createdAt: moment(purchase.createdAt),
        hasPrime: purchase.student.hasPrime,
        note: purchase.note,
        period: course.period,
        products: purchase.products,
        purchasedBy: `${purchase.student.firstName} ${
          purchase.student.lastName
        } ${purchase.student.dropped ? '(dropped)' : ''}`,
        quantities: purchase.quantities,
        status: purchase.status,
        totalCost: purchase.products.reduce(
          (prev, cur) =>
            prev +
            cur.cost *
              purchase.quantities[cur._id] *
              (taxRate / 100 + 1) *
              (isPrime && purchase.student.hasPrime ? 1 - primeRate / 100 : 1),
          0
        ),
        totalItems: Object.values(purchase.quantities).reduce(
          (prev, cur) => prev + cur,
          0
        ),
        _id: purchase._id,
      }))
    )
    .flat()

  const [columns] = useState([
    {
      title: 'Purchase Date',
      field: 'createdAt',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Tooltip
            arrow
            color='primary'
            enterTouchDelay={0}
            title={rowData.createdAt.format('h:mm:ss a')}
          >
            <span>{rowData.createdAt.format('ddd, MMM D, YYYY')}</span>
          </Tooltip>
        ) : (
          rowData.format('ddd, MMM D, YYYY, h:mm:ss a')
        ),
    },
    { title: 'Total Items', field: 'totalItems' },
    {
      title: 'Total Cost',
      field: 'totalCost',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Points value={rowData.totalCost} />
        ) : (
          <Points value={rowData} />
        ),
    },
    { title: 'Purchased by', field: 'purchasedBy' },
    {
      title: 'Prime',
      field: 'hasPrime',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          rowData.hasPrime ? (
            <PrimeBadge />
          ) : (
            ''
          )
        ) : rowData ? (
          <PrimeBadge />
        ) : (
          'No Prime'
        ),
    },
    {
      title: 'Period',
      field: 'period',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Chip label={rowData.period} color='secondary' />
        ) : (
          <Chip label={rowData} color='secondary' />
        ),
    },
    { title: 'Notes', field: 'note' },
    { title: 'Status', field: 'status' },
  ])

  useEffect(() => {
    setData(mappedData)
  }, [courses])

  return (
    <MaterialTable
      title='Purchases'
      columns={columns}
      data={data}
      localization={{
        body: {
          emptyDataSourceMessage: 'No purchases have been made',
          editRow: {
            deleteText: 'Delete purchase?',
            saveTooltip: 'Delete purchase',
          },
        },
      }}
      options={{ actionsColumnIndex: -1, paging: false, grouping: true }}
      detailPanel={(rowData) => {
        return (
          <PurchaseDetailsList
            hasPrime={rowData.hasPrime}
            products={rowData.products}
            quantities={rowData.quantities}
          />
        )
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
      editable={{
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data]
            const index = oldData.tableData.id
            dataDelete.splice(index, 1)
            setData([...dataDelete])
            onDelete(oldData._id)
            resolve()
          }),
      }}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePurchasesTable)
