import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Container,
  Dialog,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTurntable } from '@fortawesome/pro-solid-svg-icons'

import Alert from '../Alert'
import DialogAppBar from '../DialogAppBar'
import ListErrors from '../ListErrors'
import PageHeader from '../PageHeader'
import PageWrapper from '../PageWrapper'
import QueueDialog from './QueueDialog'
import Spinner from '../Spinner'
import TrackCard from './TrackCard'
import { SlideUpTransition } from '../../transitions'

import agent from '../../agent'

import {
  JUKEBOX_DIALOG_UNLOADED,
  JUKEBOX_STUDENT_DIALOG_LOADED,
  JUKEBOX_TEACHER_DIALOG_LOADED,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  buttonSpan: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: '0 auto',
    minWidth: '40%',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  course: state.songs.course,
  courses: state.songs.courses,
  currentUser: state.common.currentUser,
  errors: state.jukebox.errors,
  inProgress: state.jukebox.inProgress,
  playlist: state.jukebox.playlists[0],
  settings: state.common.settings,
})

const mapDispatchToProps = (dispatch) => ({
  onLoadStudent: (id, tokens, username) =>
    dispatch({
      type: JUKEBOX_STUDENT_DIALOG_LOADED,
      payload: Promise.all([
        agent.Spotify.getPlaylist(id, tokens),
        agent.Scores.getStudentScores(username),
      ]),
    }),
  onLoadTeacher: (id, tokens) =>
    dispatch({
      type: JUKEBOX_TEACHER_DIALOG_LOADED,
      payload: agent.Spotify.getPlaylist(id, tokens),
    }),
  onUnload: () => dispatch({ type: JUKEBOX_DIALOG_UNLOADED }),
})

const JukeboxDialog = ({
  course,
  courses,
  currentUser,
  errors,
  inProgress,
  onLoadStudent,
  onLoadTeacher,
  onUnload,
  playlist,
  settings,
}) => {
  const [open, setOpen] = useState(false)
  const [playlistId, setPlaylistId] = useState(course?.playlistId || '')

  const classes = useStyles()

  const showJukebox =
    settings.spotifyTokens &&
    settings.jukebox?.enabled &&
    settings.enableBetaFeatures &&
    settings.user.permissions?.jukebox &&
    (playlistId || currentUser.role === 'teacher')

  const disableJukebox =
    settings.prime.enabled &&
    settings.prime.jukeboxEnabled &&
    currentUser.role === 'student' &&
    !currentUser.hasPrime

  const tracks = playlist?.tracks?.items || []

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCourseSelectChange = (ev) => {
    setPlaylistId(ev.target.value)
  }

  // Update the playlistId when the course information changes
  useEffect(() => {
    setPlaylistId(course?.playlistId || '')
  }, [course])

  // Load the playlist information when playlistID changes
  useEffect(() => {
    if (settings.spotifyTokens && playlistId)
      if (currentUser.role === 'student') {
        onLoadStudent(playlistId, settings.spotifyTokens, currentUser.username)
      } else {
        onLoadTeacher(playlistId, settings.spotifyTokens)
      }
  }, [playlistId])

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      {showJukebox && (
        <Tooltip title={disableJukebox ? 'Available with Prime' : ''} arrow>
          <span className={classes.buttonSpan}>
            <Fab
              color='secondary'
              disabled={disableJukebox}
              onClick={handleClickOpen}
              variant='extended'
            >
              <FontAwesomeIcon
                className={classes.icon}
                icon={faTurntable}
                size='xl'
              />
              Jukebox
            </Fab>
          </span>
        </Tooltip>
      )}

      <Dialog
        fullScreen
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
        TransitionComponent={SlideUpTransition}
      >
        <DialogAppBar onClose={handleClose} title='Songs' />
        <PageWrapper>
          <Container>
            <PageHeader title='Jukebox' beta>
              {playlistId && (
                <Tooltip title='View playlist on Spotify' arrow>
                  <Button
                    href={playlist?.external_urls?.spotify}
                    size='large'
                    target='_blank'
                  >
                    View Playlist
                  </Button>
                </Tooltip>
              )}
              <QueueDialog />
            </PageHeader>

            <Grid container spacing={6}>
              {errors &&
                (errors?.message.includes('No active device found') ? (
                  <Grid item xs={12}>
                    <Alert severity='error' variant='filled'>
                      Sorry your purchase could not be completed because there
                      is no music playing in class right now. Try again when the
                      music is playing.
                    </Alert>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <ListErrors errors={errors} />
                  </Grid>
                ))}

              <Grid item xs={12}>
                <Typography color='secondary' variant='h5' gutterBottom>
                  <em>Play your song...now!</em>
                </Typography>
                <Typography variant='body1'>
                  With Jukebox you can pay to play your song next in the Spotify
                  queue. Note that Jukebox purchases can only be made when music
                  is playing in class.
                </Typography>
              </Grid>

              {currentUser.role === 'teacher' && (
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    variant='outlined'
                  >
                    <InputLabel id='course-select-label'>Course</InputLabel>
                    <Select
                      id='course-select'
                      label='Course'
                      labelId='course-select-label'
                      onChange={handleCourseSelectChange}
                      value={playlistId}
                    >
                      {courses
                        .filter((course) => !!course.playlistId)
                        .map((course) => (
                          <MenuItem
                            key={course.playlistId}
                            value={course.playlistId}
                          >
                            Period {course.period} | {course.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid container item spacing={2} xs={12}>
                {inProgress ? (
                  <Grid item xs={12}>
                    <Spinner message='Loading...' />
                  </Grid>
                ) : (
                  <>
                    {tracks.length > 0 ? (
                      tracks.map((t) => {
                        const track = t.track
                        return (
                          <Grid item xs={12} md={6} key={track.id}>
                            <TrackCard track={track} />
                          </Grid>
                        )
                      })
                    ) : (
                      <Grid item xs={12}>
                        <Alert disableClose>
                          {playlistId
                            ? 'No tracks have been added to this playlist'
                            : "Select a course to view it's playlist"}
                        </Alert>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Container>
        </PageWrapper>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(JukeboxDialog)
