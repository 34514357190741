import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'

import AccountView from '../AccountView'
import Alert from '../Alert'
import EconomyDialog from './EconomyDialog'
import PageHeader from '../PageHeader'
import StudentSearch from './StudentSearch'
import UpdateAccountsMenu from './UpdateAccountsMenu'

import { ACCOUNTS_PAGE_UNLOADED } from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  importSummary: state.scores.importSummary,
  settings: state.common.settings,
})

const mapDispatchToProps = (dispatch) => ({
  onUnload: () => dispatch({ type: ACCOUNTS_PAGE_UNLOADED }),
})

const TeacherAccounts = ({ importSummary, onUnload, settings }) => {
  const [student, setStudent] = useState(null)

  const plural = (qty, unit) => `${qty} ${unit}${qty !== 1 ? 's' : ''}`

  const handleStudentSelect = (student) => {
    setStudent(student)
  }

  useEffect(() => {
    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title='Manage Accounts'>
        <EconomyDialog />
        <UpdateAccountsMenu curStudent={student} settings={settings} />
      </PageHeader>

      {importSummary && (
        <Alert>
          Created {plural(importSummary.tasksCreated, 'assignment')}, imported{' '}
          {plural(importSummary.scoresImported, 'score')}, and updated{' '}
          {plural(importSummary.scoresUpdated, 'score')}
        </Alert>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StudentSearch onChange={handleStudentSelect} />
        </Grid>
        <Grid item xs={12}>
          {student && <AccountView student={student} />}
        </Grid>
      </Grid>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherAccounts)
