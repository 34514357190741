import {
  ASYNC_START,
  ECONOMY_DIALOG_LOADED,
  ECONOMY_DIALOG_UNLOADED,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  inProgress: false,
  students: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.subtype === ECONOMY_DIALOG_LOADED) {
        return {
          ...state,
          errors: null,
          inProgress: true,
        }
      }
      break
    case ECONOMY_DIALOG_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        students: action.error ? state.students : action.payload.students,
      }
    case ECONOMY_DIALOG_UNLOADED:
      return { ...defaultState }
    default:
      return state
  }

  return state
}
