import {
  APP_LOAD,
  JUKEBOX_REQUEST_SUBMIT,
  LOGIN,
  LOGIN_PAGE_UNLOADED,
  LOGOUT,
  REDIRECT,
  REGISTER,
  REGISTER_PAGE_UNLOADED,
  SETTINGS_SAVED,
  SETTINGS_PAGE_UNLOADED,
  SPOTIFY_REMOVE_AUTH,
  SUBMIT_PRIME_PURCHASE,
  NAV_LOADED,
  NAV_TAB_CHANGE,
  WHATS_NEW_DIALOG_LOADED,
  WHATS_NEW_DIALOG_UNLOADED,
} from '../constants/actionTypes'

const defaultState = {
  appName: 'Chem Cash',
  token: null,
  viewChangeCounter: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null,
        settings: action.payload ? action.payload.settings : null,
      }
    case JUKEBOX_REQUEST_SUBMIT:
      return {
        ...state,
        settings: action.error ? state.settings : action.payload.settings,
      }
    case LOGOUT:
      return { ...state, redirectTo: '/login', token: null, currentUser: null }
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        token: action.error ? null : action.payload.user.token,
        currentUser: action.error ? null : action.payload.user,
        settings: action.error ? null : action.payload.settings,
      }
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 }
    case NAV_LOADED:
    case NAV_TAB_CHANGE:
      return { ...state, redirectTo: action.redirectTo }
    case REDIRECT:
      return { ...state, redirectTo: null }
    case SETTINGS_SAVED:
      return {
        ...state,
        currentUser: action.error ? state.currentUser : action.payload[0].user,
        // settings: action.error ? state.settings : action.payload[1].settings,
        // @todo: the sharing of teacher settings with student accounts needs some cleanup to avoid messes like this
        settings:
          action.error || state.currentUser.role === 'student'
            ? state.settings
            : action.payload[1].settings,
      }
    case SPOTIFY_REMOVE_AUTH:
      return {
        ...state,
        settings: action.error
          ? state.settings
          : { ...state.settings, spotifyTokens: undefined },
      }
    case SUBMIT_PRIME_PURCHASE:
      return {
        ...state,
        currentUser: action.error
          ? state.currentUser
          : { ...state.currentUser, hasPrime: true },
      }
    case WHATS_NEW_DIALOG_LOADED:
    case WHATS_NEW_DIALOG_UNLOADED:
      return {
        ...state,
        currentUser: action.payload ? action.payload.user : null,
      }
    default:
      return state
  }
}
