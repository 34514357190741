// Convert the given Google Drive photo link into a hosted version
export const convertGoogleLink = (link) => {
  if (link.includes('drive.google.com')) {
    const regex = /file\/d\/(.*)\/view*/
    const fileIdMatch = link.match(regex)
    if (fileIdMatch !== null)
      // Current fix for issue with Google [01.10.2024]
      //  * Google Issue Tracker: https://issuetracker.google.com/issues/319531488?pli=1
      //  * StackOverflow Discussion: https://stackoverflow.com/questions/77803187/having-trouble-displaying-an-image-from-google-drive
      //  - Current fix uses url for thumbnail rather than the original image file; a possible fix from Google may be on the way
      // return `https://drive.google.com/uc?export=view&id=${fileIdMatch[1]}`
      return `https://drive.google.com/thumbnail?id=${fileIdMatch[1]}&sz=w1000`
  }
  return link
}

// Convert the given score to points using the given conversions
export const convertScore = (score, conversions) => {
  const total = score.task.totalPoints
  const multiplier = score.task.multiplier
  const nonZeroTotal = total === 0 ? 1 : total
  const percent = Math.round((score.value / nonZeroTotal) * 100)
  const conversionKey = Math.floor((percent > 100 ? 100 : percent) / 10) * 10
  return conversions[conversionKey] * multiplier
}
