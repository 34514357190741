import { unionBy } from 'lodash'
import {
  ACCOUNT_BALANCE_LOADED,
  ACCOUNT_BALANCE_UNLOADED,
  ACCOUNT_VIEW_LOADED,
  ACCOUNT_VIEW_UNLOADED,
  ACCOUNTS_PAGE_UNLOADED,
  ADD_PASS,
  ADD_PURCHASE,
  ADD_TO_QUEUE,
  ADD_TRANSACTION,
  ADD_TRANSACTIONS,
  ALERT_CLOSED,
  ASYNC_START,
  DELETE_PASS,
  DELETE_TRANSACTION,
  JUKEBOX_DIALOG_UNLOADED,
  JUKEBOX_STUDENT_DIALOG_LOADED,
  STUDENT_CART_LOADED,
  STUDENT_CART_UNLOADED,
  STUDENT_PASSES_LOADED,
  STUDENT_PASSES_UNLOADED,
  IMPORT_SCORES,
  IMPORT_SCORES_DIALOG_UNLOADED,
  TRANSACTION_DIALOG_UNLOADED,
  TRANSACTIONS_DIALOG_UNLOADED,
  UNLOAD_MANAGE_PASSES,
  UPDATE_PASS,
  UPDATE_TRANSACTION,
} from '../constants/actionTypes'
import { convertScore } from '../converter'

const defaultState = {
  balance: null,
  conversions: null,
  errors: null,
  importSummary: null,
  inProgress: false,
  total: null,
  transactions: null,
}

const getTotals = (transactions, conversions) => {
  const sumTransactions = (total, transaction) => total + transaction.amount
  const sumScores = (total, score) => total + convertScore(score, conversions)

  const bonuses = transactions.bonuses.reduce(sumTransactions, 0)
  const fines = transactions.fines.reduce(sumTransactions, 0)
  const purchases = transactions.purchases.reduce(sumTransactions, 0)
  const scores = transactions.scores.reduce(sumScores, 0)

  const balance = bonuses + fines + purchases + scores

  return { balance, bonuses, fines, purchases, scores }
}

const addScores = (added, transactions, curUser) => {
  if (curUser) {
    const curUserId = curUser._id.toString()
    const usersAdded = added.filter(
      (score) => score.student._id.toString() === curUserId
    )

    if (usersAdded) {
      transactions.scores = unionBy(usersAdded, [...transactions.scores], '_id')
    }

    return transactions
  }

  return null
}

const addTransaction = (added, transactions) => {
  const types = Object.keys(transactions)
  for (const type of types) {
    if (type.startsWith(added.type)) {
      transactions[type] = [added, ...transactions[type]]
    }
  }
  return transactions
}

const addBulkTransaction = (added, transactions, curUser) => {
  if (curUser) {
    const curUserId = curUser._id.toString()
    const usersAdded = added.find(
      (transaction) => transaction.student._id.toString() === curUserId
    )

    if (usersAdded) return addTransaction(usersAdded, transactions)
  }

  return null
}

const deleteTransaction = (deleted, transactions) => {
  const types = Object.keys(transactions)
  for (const type of types) {
    if (type.startsWith(deleted.type)) {
      transactions[type] = transactions[type].filter(
        (transaction) => transaction._id !== deleted._id
      )
    }
  }
  return transactions
}

const updateTransaction = (updated, transactions) => {
  const types = Object.keys(transactions)
  for (const type of types) {
    if (type.startsWith(updated.type)) {
      transactions[type] = transactions[type].map((transaction) =>
        transaction._id === updated._id ? updated : transaction
      )
    }
  }
  return transactions
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ACCOUNT_BALANCE_LOADED:
      return {
        ...state,
        balance: action.error
          ? null
          : getTotals(
              action.payload.transactions,
              action.payload.scoreConverter
            ),
      }
    case ACCOUNT_BALANCE_UNLOADED:
    case JUKEBOX_DIALOG_UNLOADED:
      return { ...state, balance: null }
    case ADD_TO_QUEUE:
      return {
        ...state,
        balance: action.error
          ? state.balance
          : state.balance !== null
          ? {
              ...state.balance,
              balance:
                state.balance.balance + action.payload.transaction.amount,
            }
          : null,
      }
    case JUKEBOX_STUDENT_DIALOG_LOADED:
      return {
        ...state,
        balance: action.error
          ? null
          : getTotals(
              action.payload[1].transactions,
              action.payload[1].scoreConverter
            ),
      }
    case STUDENT_CART_LOADED:
      return {
        ...state,
        total: action.error
          ? null
          : getTotals(
              action.payload.transactions,
              action.payload.scoreConverter
            ),
      }
    case STUDENT_CART_UNLOADED:
      return { ...state, total: null }
    case ACCOUNT_VIEW_LOADED:
    case STUDENT_PASSES_LOADED:
      return {
        ...state,
        conversions: action.error ? null : action.payload.scoreConverter,
        total: action.error
          ? null
          : getTotals(
              action.payload.transactions,
              action.payload.scoreConverter
            ),
        transactions: action.error ? null : action.payload.transactions,
      }
    case ACCOUNT_VIEW_UNLOADED:
      return { ...state, conversions: null, total: null, transactions: null }
    case ACCOUNTS_PAGE_UNLOADED:
    case ALERT_CLOSED:
      return { ...state, importSummary: null }
    case ADD_PASS:
    case ADD_TRANSACTION:
      const addedTransactions = !action.error
        ? addTransaction(action.payload.transaction, {
            ...state.transactions,
          })
        : {}
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        transactions: action.error ? state.transactions : addedTransactions,
        total: action.error
          ? state.total
          : getTotals(addedTransactions, state.conversions),
      }
    case ADD_PURCHASE:
      const addedTranasctions = !action.error
        ? addTransaction(action.payload.purchase.purchaseTransaction, {
            ...state.transactions,
          })
        : {}
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        transactions: action.error ? state.transactions : addedTranasctions,
        total: action.error
          ? state.total
          : getTotals(addedTranasctions, state.conversions),
      }
    case ADD_TRANSACTIONS:
      const addedBulkTransactions = !action.error
        ? addBulkTransaction(
            action.payload.transactions,
            { ...state.transactions },
            action.curStudent
          )
        : null
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        transactions:
          action.error || !state.transactions
            ? state.transactions
            : addedBulkTransactions,
        total:
          action.error || !state.transactions
            ? state.total
            : getTotals(addedBulkTransactions, state.conversions),
      }
    case ASYNC_START:
      if (
        action.subtype === ADD_TRANSACTION ||
        action.subtype === ADD_TRANSACTIONS ||
        action.subtype === DELETE_TRANSACTION ||
        action.subtype === UPDATE_TRANSACTION
      ) {
        return { ...state, errors: null, inProgress: true }
      } else if (action.subtype === IMPORT_SCORES) {
        return { ...state, errors: null, importSummary: null, inProgress: true }
      }
      break
    case DELETE_PASS:
    case DELETE_TRANSACTION:
      const deletedTransactions = !action.error
        ? deleteTransaction(action.payload.transaction, {
            ...state.transactions,
          })
        : {}
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        transactions:
          action.error || !state.transactions
            ? state.transactions
            : deletedTransactions,
        total:
          action.error || !state.transactions
            ? state.total
            : getTotals(deletedTransactions, state.conversions),
      }
    case IMPORT_SCORES:
      const addedScores = !action.error
        ? addScores(
            action.payload.scores,
            { ...state.transactions },
            action.curStudent
          )
        : null
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        importSummary: action.error ? null : action.payload.results,
        inProgress: false,
        transactions: action.error ? state.transactions : addedScores,
        total:
          action.error || !state.transactions
            ? state.total
            : getTotals(addedScores, state.conversions),
      }
    case IMPORT_SCORES_DIALOG_UNLOADED:
    case TRANSACTION_DIALOG_UNLOADED:
    case TRANSACTIONS_DIALOG_UNLOADED:
      return { ...state, errors: null }
    case UPDATE_PASS:
    case UPDATE_TRANSACTION:
      const updatedTransactions = !action.error
        ? updateTransaction(action.payload.transaction, {
            ...state.transactions,
          })
        : {}
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        transactions:
          action.error || !state.transactions
            ? state.transactions
            : updatedTransactions,
        total:
          action.error || !state.transactions
            ? state.total
            : getTotals(updatedTransactions, state.conversions),
      }
    default:
      return state
  }

  return state
}
