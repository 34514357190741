import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'

import agent from '../../agent'

import { WHATS_NEW_FLAGS_RESET } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
}))

const mapDispatchToProps = (dispatch) => ({
  onReset: (role) =>
    dispatch({
      type: WHATS_NEW_FLAGS_RESET,
      payload: agent.Teachers.resetFlags(role),
      snackbar: {
        message: `${role.charAt(0).toUpperCase() + role.slice(1)} flags reset`,
        variant: 'success',
      },
    }),
})

const ResetWhatsNewAlert = ({ onReset }) => {
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (role) => {
    setOpen(false)
    onReset(role)
  }

  return (
    <>
      <Button className={classes.button} onClick={handleClickOpen} size='small'>
        Reset Viewed Changes
      </Button>
      <Dialog
        open={open}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
      >
        <DialogTitle>Reset Viewed Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reset viewed changes flag for all student or teacher accounts?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleSubmit('student')}
            color='secondary'
            variant='contained'
            autoFocus
          >
            Reset Students
          </Button>
          <Button
            onClick={() => handleSubmit('teacher')}
            color='secondary'
            variant='contained'
            autoFocus
          >
            Reset Teachers
          </Button>
          <Button onClick={handleClose} variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(() => ({}), mapDispatchToProps)(ResetWhatsNewAlert)
