import React from 'react'
import { CircularProgress, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  message: {
    fontStyle: 'italic',
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minWidth: theme.spacing(40),
  },
  spinner: {
    marginRight: theme.spacing(2),
  },
}))

const Spinner = ({ message }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.spinner} />
      <Typography className={classes.message}>
        {message || 'Working...'}
      </Typography>
    </div>
  )
}

export default Spinner
