import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Dialog } from '@material-ui/core'

import DialogAppBar from '../DialogAppBar'
import ListErrors from '../ListErrors'
import ManagePassesTable from './ManagePassesTable'
import PageHeader from '../PageHeader'
import PageWrapper from '../PageWrapper'
import Spinner from '../Spinner'
import { SlideUpTransition } from '../../transitions'

import agent from '../../agent'

import {
  LOAD_TEACHER_PASSES,
  UNLOAD_MANAGE_PASSES,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.passes,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({ type: LOAD_TEACHER_PASSES, payload: agent.Passes.getAll() }),
  onUnload: () => dispatch({ type: UNLOAD_MANAGE_PASSES }),
})

const ManagePassesDialog = ({
  courses,
  errors,
  inProgress,
  isOpen,
  onClose,
  onLoad,
  onUnload,
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
    onLoad()
  }

  const handleClose = () => {
    setOpen(false)
    onUnload()
    onClose()
  }

  // Allow for external component control of displaying the dialog
  useEffect(() => {
    if (isOpen) handleOpen()
  }, [isOpen])

  return (
    <Dialog
      fullScreen
      open={open}
      onClick={(ev) => ev.stopPropagation()}
      onClose={handleClose}
      onFocus={(ev) => ev.stopPropagation()}
      TransitionComponent={SlideUpTransition}
    >
      <DialogAppBar onClose={handleClose} title='Accounts' />
      <PageWrapper>
        <Container>
          <PageHeader title='Manage Hallpasses' />
          <ListErrors errors={errors} />
          {inProgress ? (
            <Spinner message='Loading...' />
          ) : (
            <ManagePassesTable courses={courses} />
          )}
        </Container>
      </PageWrapper>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePassesDialog)
