import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Container, Dialog } from '@material-ui/core'

import DialogAppBar from '../DialogAppBar'
import ListErrors from '../ListErrors'
import PageHeader from '../PageHeader'
import PageWrapper from '../PageWrapper'
import Spinner from '../Spinner'
import StudentPurchasesTable from './StudentPurchasesTable'
import { SlideUpTransition } from './../../transitions'

import agent from '../../agent'

import {
  STUDENT_PURCHASES_LOADED,
  STUDENT_PURCHASES_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.purchases,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: STUDENT_PURCHASES_LOADED,
      payload: agent.Purchases.forStudent(),
    }),
  onUnload: () =>
    dispatch({
      type: STUDENT_PURCHASES_UNLOADED,
    }),
})

const StudentPurchasesDialog = ({ errors, inProgress, onLoad, onUnload }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
    onLoad()
  }

  const handleClose = () => {
    setOpen(false)
    onUnload()
  }

  return (
    <>
      <Button onClick={handleClickOpen} size='small'>
        Your orders
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        TransitionComponent={SlideUpTransition}
      >
        <DialogAppBar onClose={handleClose} title='Store' />
        <PageWrapper>
          <Container>
            <PageHeader title='Your Orders' />
            <ListErrors errors={errors} />
            {inProgress ? (
              <Spinner message='Loading...' />
            ) : (
              <StudentPurchasesTable />
            )}
          </Container>
        </PageWrapper>
      </Dialog>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentPurchasesDialog)
