import React, { useState } from 'react'
import {
  Avatar,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import CourseDialog from './CourseDialog'
import CourseStudentList from './CourseStudentList'
import DeleteCourseAlert from './DeleteCourseAlert'
import GrouperDialog from './GrouperDialog'
import ImportStudentsDialog from './ImportStudentsDialog'
import JukeboxBadge from '../Jukebox/JukeboxBadge'
import StudentDialog from './StudentDialog'
import TasksDialog from './TasksDialog'

const useStyles = makeStyles((theme) => ({
  courseDetails: {
    display: 'block',
  },
  courseHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  coursePeriod: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
  },
  courseTitle: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: '1',
  },
}))

const CourseList = ({ courses }) => {
  const [expanded, setExpanded] = useState(false)

  const classes = useStyles()

  const handleChange = (panel) => (ev, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      {courses?.length > 0 ? (
        courses.map((course) => {
          return (
            <ExpansionPanel
              expanded={expanded === course._id}
              key={course._id}
              onChange={handleChange(course._id)}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <div className={classes.courseHeader}>
                  <Avatar className={classes.coursePeriod}>
                    {course.period}
                  </Avatar>
                  <Typography className={classes.courseTitle} variant='h5'>
                    {course.name}
                    <JukeboxBadge enabled={course.playlistId ? true : false} />
                  </Typography>
                  <TasksDialog course={course} />
                  <GrouperDialog course={course} />
                  <StudentDialog course={course} />
                  <ImportStudentsDialog course={course} />
                  <CourseDialog course={course} />
                  <DeleteCourseAlert course={course} />
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.courseDetails}>
                <CourseStudentList course={course} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })
      ) : (
        <Typography>You have no courses</Typography>
      )}
    </>
  )
}

export default CourseList
