import React from 'react'
import logo from '../images/chem-cash-logo.png'
import logoPrime from '../images/chem-cash-prime-logo.png'

const Logo = ({ prime, ...props }) => {
  return (
    <img
      alt={`Chem Cash ${prime && 'Prime '}Logo`}
      src={prime ? logoPrime : logo}
      {...props}
    />
  )
}

export default Logo
