import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { Container } from '@material-ui/core'

import AccountSuspendedDialog from './Student/AccountSuspendedDialog'
import Header from './Header'
import Navigation from './Navigation'
import NotFound from './NotFound'
import PageWrapper from './PageWrapper'
import ProtectedRoute from './ProtectedRoute'
import Settings from './Settings'
import StudentAccount from './Student/StudentAccount'
import StudentPasses from './Passes/StudentPasses'
import StudentSongs from './Student/StudentSongs'
import TeacherAccounts from './Teacher/TeacherAccounts'
import TeacherCourses from './Teacher/TeacherCourses'
import TeacherSongs from './Teacher/TeacherSongs'
import ManagePurchases from './Purchases/ManagePurchases'
import ManageTeachers from './Admin/ManageTeachers'
import Products from './Products/Products'

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
})

const Home = (props) => {
  const { currentUser } = props

  if (!currentUser) {
    return <Redirect to='/login' />
  }

  return (
    <>
      <AccountSuspendedDialog user={currentUser} />
      <Header />
      <PageWrapper>
        <Container>
          <Switch>
            <ProtectedRoute
              path='/student/account'
              component={StudentAccount}
            />
            <ProtectedRoute path='/student/passes' component={StudentPasses} />
            <ProtectedRoute path='/student/settings' component={Settings} />
            <ProtectedRoute path='/student/songs' component={StudentSongs} />
            <ProtectedRoute path='/student/store' component={Products} />

            <ProtectedRoute
              path='/teacher/accounts'
              component={TeacherAccounts}
            />
            <ProtectedRoute
              path='/teacher/courses'
              component={TeacherCourses}
            />
            <ProtectedRoute path='/teacher/products' component={Products} />
            <ProtectedRoute path='/teacher/settings' component={Settings} />
            <ProtectedRoute path='/teacher/songs' component={TeacherSongs} />
            <ProtectedRoute path='/teacher/store' component={ManagePurchases} />

            <ProtectedRoute path='/admin/settings' component={Settings} />
            <ProtectedRoute path='/admin/teachers' component={ManageTeachers} />

            <Route component={NotFound} />
          </Switch>
        </Container>
        <Navigation />
      </PageWrapper>
    </>
  )
}

export default connect(mapStateToProps, () => ({}))(Home)
