import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MaterialTable from 'material-table'
import moment from 'moment'
import { Grid, Paper, Tooltip, Typography, makeStyles } from '@material-ui/core'
import { Close, Edit } from '@material-ui/icons'

import ActionButton from './ActionButton'
import AddPurchaseDialog from './Purchases/AddPurchaseDialog'
import DroppedChip from './DroppedChip'
import NotificationDialog from './Notifications/NotificationDialog'
import ResetStudentPasswordAlert from './Teacher/ResetStudentPasswordAlert'
import PassDialog from './Passes/PassDialog'
import Points from './Points'
import PrimeBadge from './Prime/PrimeBadge'
import TransactionDialog from './Teacher/TransactionDialog'
import agent from '../agent'
import { convertScore } from '../converter'

import {
  ACCOUNT_VIEW_LOADED,
  ACCOUNT_VIEW_UNLOADED,
  DELETE_TRANSACTION,
  UPDATE_TRANSACTION,
} from '../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  actions: {
    alignItems: 'flex-start',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  balance: {
    'textAlign': 'center',
    '& > h6': {
      borderTop: '1px solid #ccc',
      fontStyle: 'italic',
      margin: '0 auto',
      textTransform: 'lowercase',
      width: '80%',
    },
  },
  centerOnMobile: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  edit: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
  },
  subtotal: {
    'position': 'relative',
    'textAlign': 'center',
    '& > p': {
      position: 'relative',
      bottom: -theme.spacing(1),
    },
    '& > h6': {
      fontSize: theme.typography.pxToRem(12),
      fontStyle: 'italic',
      textTransform: 'lowercase',
    },
  },
  unit: {
    fontSize: theme.typography.pxToRem(12),
  },
}))

const mapStateToProps = (state) => ({
  conversions: state.scores.conversions,
  finesEnabled: state.common.settings.fines.enabled,
  storeEnabled: state.common.settings.storeEnabled,
  total: state.scores.total,
  transactions: state.scores.transactions,
  userRole: state.common.currentUser.role,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (transaction) =>
    dispatch({
      type: DELETE_TRANSACTION,
      payload: agent.Transactions.delete(transaction),
      snackbar: { message: 'Transaction deleted', variant: 'success' },
    }),
  onLoad: (username) =>
    dispatch({
      type: ACCOUNT_VIEW_LOADED,
      payload: agent.Scores.getStudentScores(username),
    }),
  onUnload: () => dispatch({ type: ACCOUNT_VIEW_UNLOADED }),
  onUpdate: (transaction) =>
    dispatch({
      type: UPDATE_TRANSACTION,
      payload: agent.Transactions.update(transaction),
      snackbar: { message: 'Transaction updated', variant: 'success' },
    }),
})

const AccountHeader = ({
  doEdit,
  finesEnabled,
  onEditClick,
  storeEnabled,
  student,
  total,
  userRole,
}) => {
  const classes = useStyles()

  const balanceGridSize = finesEnabled ? 3 : 4

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} className={classes.centerOnMobile}>
          <Typography gutterBottom variant='h5'>
            {student.firstName} {student.lastName}{' '}
            {student.hasPrime && <PrimeBadge />}
            {student.dropped && <DroppedChip />}
          </Typography>
          {student.course && (
            <Typography gutterBottom color='textSecondary' variant='subtitle1'>
              {student.course} &middot; Period {student.period}
            </Typography>
          )}
          <Typography color='textSecondary' variant='subtitle2'>
            {student.username}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} container>
          <Grid item xs={12} className={classes.balance}>
            <Typography
              color={total?.balance >= 0 ? 'primary' : 'secondary'}
              variant='h4'
            >
              <Points suffix=' ' value={total?.balance} />
              <span className={classes.unit}>pts</span>
            </Typography>
            <Typography color='textSecondary' variant='subtitle2'>
              Balance
            </Typography>
          </Grid>
          <Grid item xs={balanceGridSize} className={classes.subtotal}>
            <Typography color='primary' variant='body1'>
              <Points suffix=' ' value={total?.scores} />
              <span className={classes.unit}>pts</span>
            </Typography>
            <Typography color='primary' variant='subtitle2'>
              Assessments
            </Typography>
          </Grid>
          <Grid item xs={balanceGridSize} className={classes.subtotal}>
            <Typography color='primary' variant='body1'>
              <Points suffix=' ' value={total?.bonuses} />
              <span className={classes.unit}>pts</span>
            </Typography>
            <Typography color='primary' variant='subtitle2'>
              Bonuses
            </Typography>
          </Grid>
          {finesEnabled && (
            <Grid item xs={balanceGridSize} className={classes.subtotal}>
              <Typography color='secondary' variant='body1'>
                <Points suffix=' ' value={total?.fines} />
                <span className={classes.unit}>pts</span>
              </Typography>
              <Typography color='secondary' variant='subtitle2'>
                Fines
              </Typography>
            </Grid>
          )}
          <Grid item xs={balanceGridSize} className={classes.subtotal}>
            <Typography color='secondary' variant='body1'>
              <Points suffix=' ' value={total?.purchases} />
              <span className={classes.unit}>pts</span>
            </Typography>
            <Typography color='secondary' variant='subtitle2'>
              Purchases
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className={`${classes.actions} ${classes.centerOnMobile}`}
        >
          <PassDialog buttonType='icon' student={student} />
          {userRole === 'teacher' ? (
            <>
              <TransactionDialog student={student} type='Bonus' />
              {finesEnabled && (
                <TransactionDialog student={student} type='Fine' />
              )}
              <TransactionDialog student={student} type='Purchase' />
              {storeEnabled && <AddPurchaseDialog student={student} />}
              <ActionButton
                action={doEdit ? 'Finish editing' : 'Edit transactions'}
                className={doEdit && classes.edit}
                onClick={onEditClick}
              >
                {doEdit ? <Close /> : <Edit />}
              </ActionButton>
              <ResetStudentPasswordAlert btnSize='medium' student={student} />
            </>
          ) : (
            <NotificationDialog />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

const TransactionList = ({
  conversions,
  doEdit,
  onDelete,
  onUpdate,
  transactions,
}) => {
  const [data, setData] = useState([])

  const mapTransaction = (transaction) => ({
    amount: transaction.amount,
    createdAt: moment(transaction.createdAt),
    createdAtString: transaction.createdAt,
    description: transaction.description,
    type: transaction.type,
    _id: transaction._id,
  })

  const mapScore = (score) => ({
    amount: convertScore(score, conversions),
    createdAt: moment(score.createdAt),
    createdAtString: score.createdAt,
    description: score.task.title,
    type: 'assessment',
  })

  const mappedData = (transactions) => {
    const { bonuses, fines, purchases, scores } = transactions
    const mapped = [
      ...bonuses.map((bonus) => mapTransaction(bonus)),
      ...fines.map((fine) => mapTransaction(fine)),
      ...purchases.map((purchase) => mapTransaction(purchase)),
    ]
    if (doEdit) {
      return mapped
    } else {
      return [...mapped, ...scores.map((score) => mapScore(score))]
    }
  }

  const [columns] = useState([
    {
      title: 'Date',
      field: 'createdAt',
      editable: 'never',
      defaultSort: 'desc',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Tooltip
            arrow
            color='primary'
            enterTouchDelay={0}
            title={rowData.createdAt.format('h:mm:ss a')}
          >
            <span>{rowData.createdAt.format('ddd, MMM D, YYYY')}</span>
          </Tooltip>
        ) : (
          rowData.format('ddd, MMM D, YYYY, h:mm:ss a')
        ),
    },
    { title: 'Description', field: 'description' },
    {
      title: 'Amount',
      field: 'amount',
      render: (rowData) => <Points value={rowData.amount} />,
    },
    { title: 'Type', field: 'type', editable: 'never' },
  ])

  useEffect(() => {
    transactions && setData(mappedData(transactions))
  }, [doEdit, transactions])

  return (
    <MaterialTable
      columns={columns}
      data={data}
      localization={{
        body: {
          emptyDataSourceMessage: doEdit
            ? 'No editable transactions available'
            : 'No transactions have posted',
          editRow: {
            deleteText: 'Delete this transaction?',
            saveTooltip: 'Confirm',
          },
        },
      }}
      options={{ actionsColumnIndex: -1, paging: false, toolbar: false }}
      editable={
        doEdit
          ? {
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...data]
                  const index = oldData.tableData.id
                  dataUpdate[index] = {
                    ...newData,
                    createdAt: moment(newData.createdAtString),
                  }
                  setData([...dataUpdate])
                  onUpdate(newData)
                  resolve()
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  const newData = [...data]
                  const index = oldData.tableData.id
                  newData.splice(index, 1)
                  setData([...newData])
                  onDelete(oldData)
                  resolve()
                }),
            }
          : {}
      }
    />
  )
}

const AccountView = ({
  conversions,
  finesEnabled,
  onDelete,
  onLoad,
  onUnload,
  onUpdate,
  storeEnabled,
  student,
  total,
  transactions,
  userRole,
}) => {
  const [doEdit, setDoEdit] = useState(false)

  const handleEditClick = () => {
    setDoEdit(!doEdit)
  }

  useEffect(() => {
    return () => {
      onUnload()
    }
  }, [])

  useEffect(() => {
    onLoad(student.username)
  }, [student])

  return (
    <>
      {transactions && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AccountHeader
              doEdit={doEdit}
              finesEnabled={finesEnabled}
              onEditClick={handleEditClick}
              storeEnabled={storeEnabled}
              student={student}
              total={total}
              userRole={userRole}
            />
          </Grid>
          <Grid item xs={12}>
            <TransactionList
              conversions={conversions}
              doEdit={doEdit}
              onDelete={onDelete}
              onUpdate={onUpdate}
              transactions={transactions}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountView)
