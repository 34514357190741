import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { AddShoppingCart } from '@material-ui/icons'

import AccountBalance from '../AccountBalance'
import ActionButton from '../ActionButton'
import InputBox from '../InputBox'
import ListErrors from '../ListErrors'
import PurchaseDetailsList from './PurchaseDetailsList'
import agent from '../../agent'

import {
  ADD_PURCHASE,
  ADD_PURCHASE_DIALOG_UNLOADED,
  ADD_TO_CART,
  DELETE_CART_PRODUCT,
  UPDATE_CART_PRODUCT_QUANTITY,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.purchases,
})

const mapDispatchToProps = (dispatch) => ({
  onAddItem: (product) => dispatch({ type: ADD_TO_CART, product }),
  onDeleteItem: (productId) =>
    dispatch({ type: DELETE_CART_PRODUCT, productId }),
  onExit: () => dispatch({ type: ADD_PURCHASE_DIALOG_UNLOADED }),
  onSubmit: (purchase) =>
    dispatch({
      type: ADD_PURCHASE,
      payload: agent.Purchases.add(purchase),
      snackbar: { message: 'Purchase added', variant: 'success' },
    }),
  onUpdateItem: (productId, qty) =>
    dispatch({ type: UPDATE_CART_PRODUCT_QUANTITY, productId, qty }),
})

const AddPurchaseDialog = ({
  cart,
  errors,
  inProgress,
  onAddItem,
  onDeleteItem,
  onExit,
  onSubmit,
  onUpdateItem,
  student,
}) => {
  const [open, setOpen] = useState(false)
  const [invoice, setInvoice] = useState({})
  const [purchaseNote, setPurchaseNote] = useState('')

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => {
    setOpen(false)
    setInvoice({})
    setPurchaseNote('')
  }

  const handleSubmit = () => {
    onSubmit({
      note: purchaseNote,
      products: cart.products,
      quantities: cart.quantities,
      status: 'approved',
      student,
    })
    handleClose()
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      <ActionButton action={'Add purchase'} onClick={handleClickOpen}>
        <AddShoppingCart />
      </ActionButton>

      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <DialogTitle>Add Purchase</DialogTitle>
        <DialogContent>
          <ListErrors errors={errors} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PurchaseDetailsList
                actions={{
                  add: onAddItem,
                  delete: onDeleteItem,
                  setInvoice: (invoice) => setInvoice(invoice),
                  update: onUpdateItem,
                }}
                hasPrime={student.hasPrime}
                products={cart.products}
                quantities={cart.quantities}
              />
              <InputBox
                label='Notes (optional)'
                name='purchaseNote'
                onChange={setPurchaseNote}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div style={{ marginRight: 'auto' }}>
            <AccountBalance cost={invoice.total} username={student.username} />
          </div>
          <Button
            disabled={inProgress || cart.products.length === 0}
            color='primary'
            onClick={handleSubmit}
            type='submit'
            variant='contained'
          >
            {inProgress ? 'Adding...' : 'Add'}
          </Button>
          <Button
            disabled={inProgress}
            onClick={handleClose}
            variant='contained'
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPurchaseDialog)
