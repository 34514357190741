import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'

import ManagePurchasesTable from './ManagePurchasesTable'
import PageHeader from '../PageHeader'
import ProcessPurchasesDialog from './ProcessPurchasesDialog'
import agent from '../../agent'

import {
  NAV_TAB_CHANGE,
  TEACHER_PURCHASES_LOADED,
  TEACHER_PURCHASES_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.common.currentUser,
  ...state.purchases,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: TEACHER_PURCHASES_LOADED,
      payload: agent.Purchases.forTeacher(),
    }),
  onPageChange: (value, redirectTo) =>
    dispatch({ type: NAV_TAB_CHANGE, value, redirectTo }),
  onUnload: () => dispatch({ type: TEACHER_PURCHASES_UNLOADED }),
})

const ManagePurchases = ({ onLoad, onPageChange, onUnload, role }) => {
  const handlePageChange = () => onPageChange('store', `/${role}/products`)

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title='Manage Purchases'>
        <Button onClick={handlePageChange} size='small'>
          Manage Products
        </Button>
        <ProcessPurchasesDialog />
      </PageHeader>
      <ManagePurchasesTable />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePurchases)
