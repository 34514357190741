import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { ShoppingCart, ThumbDown, ThumbUp } from '@material-ui/icons'

import AccountBalance from '../AccountBalance'
import FabBadge from '../FabBadge'
import InputBox from '../InputBox'
import ListErrors from '../ListErrors'
import PrimeBadge from '../Prime/PrimeBadge'
import PurchaseDetailsList from '../Purchases/PurchaseDetailsList'
import agent from '../../agent'

import {
  PURCHASES_DIALOG_UNLOADED,
  UPDATE_PURCHASE,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginBottom: theme.spacing(2),
  },
  dialog: {
    '& > .MuiDialog-container > .MuiPaper-root': {
      [theme.breakpoints.up('lg')]: {
        minHeight: theme.spacing(54),
        width: theme.spacing(100),
      },
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  inputBox: {
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.default
        : theme.palette.grey[100],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  panel: {
    minWidth: theme.spacing(40),
    maxWidth: theme.spacing(60),
  },
  purchaseNote: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  removalAlert: {
    backgroundColor: theme.palette.error.light,
    border: `2px solid ${theme.palette.error.dark}`,
    borderRadius: theme.spacing(2),
    color: theme.palette.common.white,
    fontSize: theme.typography.h6.fontSize,
    padding: theme.spacing(1),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  songInfo: {
    marginBottom: theme.spacing(4),
  },
  statusButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  studentInfo: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      borderTopColor: theme.palette.primary.main,
      borderTopStyle: 'dotted',
      borderTopWidth: '4px',
      paddingTop: theme.spacing(2),
    },
  },
  studentName: {
    'alignItems': 'center',
    'display': 'flex',
    '& > div': {
      marginRight: theme.spacing(1),
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.common.settings,
  ...state.purchases,
})

const mapDispatchToProps = (dispatch) => ({
  onExit: () => dispatch({ type: PURCHASES_DIALOG_UNLOADED }),
  onUpdate: (purchase) =>
    dispatch({
      type: UPDATE_PURCHASE,
      payload: agent.Purchases.update(purchase),
    }),
})

const ProcessPurchasesDialog = ({
  courses,
  errors,
  inProgress,
  isLoaded,
  onExit,
  onUpdate,
  prime,
}) => {
  const [count, setCount] = useState(0)
  const [invoice, setInvoice] = useState({})
  const [open, setOpen] = useState(false)
  const [purchase, setPurchase] = useState(null)
  const [purchaseNote, setPurchaseNote] = useState('')
  const [skippedPurchases, setSkippedPurchases] = useState([])
  const [status, setStatus] = useState(null)
  const [totalCount, setTotalCount] = useState(0)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const getPurchaseCount = () => {
    let count = 0
    if (courses.length > 0)
      for (const course of courses)
        count += course.purchases.filter((purchase) =>
          purchase.status.startsWith('pending')
        ).length
    return count
  }

  const getNextPurchase = () => {
    const prevPurchaseId = purchase?._id
    setPurchase(null)
    if (courses.length > 0) {
      for (const course of courses) {
        if (course.purchases.length > 0) {
          const nextPurchase = course.purchases.find(
            (purchase) =>
              purchase.status.startsWith('pending') &&
              !skippedPurchases.includes(purchase._id)
          )
          if (nextPurchase) {
            nextPurchase.period = course.period
            setPurchase(nextPurchase)
            if (prevPurchaseId !== nextPurchase._id) setCount(count + 1)
            break
          }
        }
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
    setTotalCount(getPurchaseCount())
  }

  const handleClose = () => {
    setCount(0)
    setOpen(false)
    setStatus(null)
    setPurchaseNote('')
    setSkippedPurchases([])
    setTotalCount(getPurchaseCount())
  }

  const handleAddPurchaseItemClick = (product) => {
    const updatedPurchase = {
      ...purchase,
      products:
        purchase.products.findIndex((prod) => prod._id === product._id) > -1
          ? purchase.products
          : [...purchase.products, product],
      quantities: { ...purchase.quantities, [product._id]: 1 },
      id: purchase._id,
    }
    onUpdate(updatedPurchase)
  }

  const handleDeletePurchaseItemClick = (productId, purchase) => {
    const products = purchase.products.filter(
      (product) => product._id !== productId
    )
    const quantities = { ...purchase.quantities }
    delete quantities[productId]
    onUpdate({
      ...purchase,
      products,
      quantities,
      id: purchase._id,
    })
  }

  const handleUpdatePurchaseItemQuantity = (productId, quantity) => {
    const quantities = { ...purchase.quantities, [productId]: quantity }
    onUpdate({ ...purchase, quantities, id: purchase._id })
  }

  const handleSaveButtonClick = async () => {
    onUpdate({
      ...purchase,
      id: purchase._id,
      note: purchaseNote,
      status,
    })
    setStatus(null)
    setPurchaseNote('')
  }

  const handleStatusButtonClick = (status) => {
    setStatus(status)
  }

  const handleSkip = () => {
    setSkippedPurchases([...skippedPurchases, purchase._id])
    setStatus(null)
    setPurchaseNote('')
  }

  useEffect(() => {
    setTotalCount(getPurchaseCount())
  }, [isLoaded])

  useEffect(() => {
    getNextPurchase()
  }, [courses, skippedPurchases])

  const DroppedStudentAlert = () => (
    <Alert severity='error'>
      This student's account is currently suspended
    </Alert>
  )

  const PrimeAlert = () => (
    <>{prime.enabled && <Alert severity='success'>Prime member</Alert>}</>
  )

  return (
    <>
      <Fab color='primary' onClick={handleClickOpen} variant='extended'>
        <FabBadge value={totalCount}>
          <ShoppingCart className={classes.icon} />
          Process purchases
        </FabBadge>
      </Fab>

      <Dialog
        className={classes.dialog}
        fullScreen={fullScreen}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <DialogTitle>Process Purchases</DialogTitle>
        <DialogContent>
          <ListErrors errors={errors} />
          {purchase ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {purchase.student.dropped && <DroppedStudentAlert />}
              </Grid>
              <Grid item xs={12}>
                {purchase.student.hasPrime && <PrimeAlert />}
              </Grid>
              <Grid item xs={12}>
                <PurchaseDetailsList
                  actions={{
                    add: (product) => handleAddPurchaseItemClick(product),
                    delete: (productId) =>
                      handleDeletePurchaseItemClick(productId, purchase),
                    setInvoice: (invoice) => setInvoice(invoice),
                    update: (productId, quantity) =>
                      handleUpdatePurchaseItemQuantity(productId, quantity),
                  }}
                  hasPrime={purchase.student.hasPrime}
                  products={purchase.products}
                  quantities={purchase.quantities}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.panel}>
                  <div className={classes.studentInfo}>
                    <div className={classes.studentName}>
                      <Chip color='secondary' label={purchase.period} />
                      <Typography>
                        {purchase.student.firstName} {purchase.student.lastName}
                      </Typography>
                      {purchase.student.hasPrime && <PrimeBadge size='2xl' />}
                    </div>
                    <AccountBalance
                      cost={invoice.total}
                      count={count}
                      username={purchase.student.username}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className={classes.panel}>
                  <Typography variant='h6'>Status</Typography>

                  <ButtonGroup
                    fullWidth
                    size='large'
                    className={classes.buttonGroup}
                  >
                    <Tooltip arrow enterTouchDelay={0} title='Approve'>
                      <Button
                        className={`${classes.statusButton} ${
                          status === 'approved' && classes.selected
                        }`}
                        onClick={() => handleStatusButtonClick('approved')}
                      >
                        <ThumbUp />
                      </Button>
                    </Tooltip>
                    <Tooltip arrow enterTouchDelay={0} title='Decline'>
                      <Button
                        className={`${classes.statusButton} ${
                          status === 'declined' && classes.selected
                        }`}
                        onClick={() => handleStatusButtonClick('declined')}
                      >
                        <ThumbDown />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                  <div className={classes.inputBox}>
                    <InputBox
                      label='Notes (optional)'
                      name='purchaseNote'
                      onChange={setPurchaseNote}
                      value={purchaseNote}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Alert severity='info'>
              <AlertTitle>All caught up!</AlertTitle>
              There are no purchases to process at this time
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          {purchase && (
            <>
              <Typography
                style={{ marginRight: 'auto' }}
                variant='subtitle2'
              >{`Purchase ${count} of ${totalCount}`}</Typography>
              <Button
                color='primary'
                disabled={inProgress || !status}
                onClick={handleSaveButtonClick}
                variant='contained'
              >
                {inProgress ? 'Saving...' : 'Save & Continue'}
              </Button>
              <Button
                disabled={inProgress}
                onClick={handleSkip}
                variant='contained'
              >
                Skip
              </Button>
            </>
          )}
          <Button
            disabled={inProgress}
            onClick={handleClose}
            variant='contained'
          >
            {purchase ? 'Cancel' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessPurchasesDialog)
