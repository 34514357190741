import React from 'react'
import { connect } from 'react-redux'
import { Typography, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  courses: {
    '& div': {
      'backgroundColor': theme.palette.background.default,
      'boxShadow': theme.shadows[4],
      'cursor': 'pointer',
      'marginBottom': theme.spacing(1),
      'padding': `${theme.spacing(3)}px ${theme.spacing(4)}px`,
      'transition': 'all 0.15s ease-in-out',
      '&:focus, &:hover': {
        backgroundColor: theme.palette.primary.light,
        boxShadow: theme.shadows[1],
      },
    },
  },
}))

const mapStateToProps = (state) => ({
  courses: state.courses.courses,
})

const CourseSelector = ({ courses, onSelect }) => {
  const classes = useStyles()

  return (
    <div>
      <Typography gutterBottom variant='h6'>
        Select a course
      </Typography>
      {courses.length > 0 ? (
        <div className={classes.courses}>
          {courses.map((course) => (
            <div
              key={course.slug}
              onClick={() => {
                onSelect(course)
              }}
              tabIndex='0'
            >
              <Typography variant='body1'>
                <b>{course.name}</b> &middot; Period {course.period}
              </Typography>
            </div>
          ))}
        </div>
      ) : (
        <Alert severity='info'>
          You must create a course before continuing
        </Alert>
      )}
    </div>
  )
}

export default connect(mapStateToProps, () => ({}))(CourseSelector)
