import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(11),
  },
}))

const PageWrapper = (props) => {
  const classes = useStyles()

  return <div className={classes.wrapper}>{props.children}</div>
}

export default PageWrapper
