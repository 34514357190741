import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import CourseSelector from './CourseSelector'
import Filedrop from '../Filedrop'
import ListErrors from '../ListErrors'
import Spinner from '../Spinner'
import agent from '../../agent'
import parser from '../../parsers'

import {
  IMPORT_SCORES,
  IMPORT_SCORES_DIALOG_UNLOADED,
} from '../../constants/actionTypes'
import { SUPPORTED_SCHOOLS } from '../../constants/support'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& > .MuiDialog-container > .MuiPaper-root': {
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(100),
      },
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.common.settings,
  ...state.scores,
})

const mapDispatchToProps = (dispatch) => ({
  onImport: (data, curStudent) =>
    dispatch({
      type: IMPORT_SCORES,
      payload: agent.Scores.import(data),
      curStudent,
      snackbar: { message: 'Scores imported', variant: 'success' },
    }),
  onExit: () => dispatch({ type: IMPORT_SCORES_DIALOG_UNLOADED }),
})

const ScoreImportStepper = ({ onDataChange, onShowImport }) => {
  const [file, setFile] = useState(null)
  const [course, setCourse] = useState(null)
  const [tasks, setTasks] = useState(null)

  const handleCourseSelect = (course) => {
    setCourse(course)
  }

  const handleFiledrop = (file) => {
    const tasks = {}
    Object.keys(file[0])
      .slice(1)
      .forEach((task) => {
        tasks[task] = false
      })
    setFile(file)
    setTasks(tasks)
  }

  const handleTaskChange = (ev) => {
    setTasks({ ...tasks, [ev.target.name]: ev.target.checked })
  }

  // Determine if any tasks have been checked/selected
  useEffect(() => {
    const tasksSelected = tasks
      ? Object.values(tasks).some((el) => el === true)
      : false
    onShowImport(tasksSelected)
  }, [tasks])

  // Keep the imported data object current
  useEffect(() => {
    onDataChange({ file, course, tasks })
  }, [file, course, tasks])

  return (
    <>
      {!file && !course && !tasks && (
        <CourseSelector onSelect={handleCourseSelect} />
      )}

      {!file && course && !tasks && (
        <div>
          <Typography gutterBottom>
            Scores must be imported from a .csv file exported from your
            gradebook software
          </Typography>
          <Filedrop onFiledrop={handleFiledrop} />
        </div>
      )}

      {file &&
        course &&
        tasks && ( // @todo: use material-table here?
          <FormControl component='fieldset'>
            <Typography gutterBottom variant='body1'>
              Select the assignments to import
            </Typography>
            <FormGroup>
              {Object.keys(tasks).map((task) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tasks[task]}
                      onChange={handleTaskChange}
                      name={task}
                    />
                  }
                  key={task}
                  label={task}
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
    </>
  )
}

const ImportScoresDialog = ({
  curStudent,
  errors,
  inProgress,
  isClosed,
  isOpen,
  onExit,
  onImport,
  schoolDistrict,
}) => {
  const [open, setOpen] = useState(false)
  const [ready, setReady] = useState(false)
  const [data, setData] = useState(null)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
    isClosed()
  }

  const handleDataChange = (importData) => {
    setData(importData)
  }

  const handleShowImport = (selected) => {
    setReady(selected)
  }

  const handleClickImport = () => {
    const parsed = parser[schoolDistrict].scores(data)
    onImport(parsed, curStudent)
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) handleClose()
  }, [inProgress])

  // Allow for external component control of displaying the dialog
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Dialog
      className={classes.dialog}
      fullScreen={fullScreen}
      onClick={(ev) => ev.stopPropagation()}
      onClose={handleClose}
      onExit={onExit}
      onFocus={(ev) => ev.stopPropagation()}
      open={open}
    >
      <DialogTitle>Import Scores</DialogTitle>
      {SUPPORTED_SCHOOLS.SCORES.includes(schoolDistrict) ? (
        <DialogContent>
          <ListErrors errors={errors} />
          {inProgress ? (
            <Spinner message='Importing...' />
          ) : (
            <ScoreImportStepper
              onDataChange={handleDataChange}
              onShowImport={handleShowImport}
            />
          )}
        </DialogContent>
      ) : (
        <DialogContent>
          {schoolDistrict === 'none' ? (
            <Typography>
              You must select a <em>School District</em> in the{' '}
              <em>Account Details</em> section of the <em>Settings</em> page to
              use the import feature
            </Typography>
          ) : (
            <Typography>
              Please email a copy of your gradebook export file to{' '}
              <Link href='mailto:support@chem.cash'>support@chem.cash</Link> to
              enable this feature
            </Typography>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          color='primary'
          disabled={!ready || inProgress}
          onClick={handleClickImport}
          variant='contained'
        >
          {inProgress ? 'Importing...' : 'Import'}
        </Button>
        <Button disabled={inProgress} onClick={handleClose} variant='contained'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportScoresDialog)
