import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import { Add, Check, Close, Delete } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import Points from '../Points'
import PrimeBadge from '../Prime/PrimeBadge'
import ProductSelector from '../Products/ProductSelector'
import { useGetInvoice } from '../../hooks'
import { convertGoogleLink } from '../../converter'

const useStyles = makeStyles((theme) => ({
  emptyCart: {
    textAlign: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  tableCellImg: {
    'alignItems': 'center',
    'display': 'flex',
    '& img': {
      height: '50px',
      marginRight: theme.spacing(2),
    },
  },
}))

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  prime: state.common.settings.prime,
  taxRate: state.common.settings.taxRate,
})

const getOptions = (max) => {
  const maxOptions = max === 0 ? 100 : max
  let options = []
  for (let i = 1; i <= maxOptions; i++) {
    options.push(
      <MenuItem value={i} key={i}>
        {i}
      </MenuItem>
    )
  }
  return options
}

const PurchaseDetailsList = ({
  actions,
  currentUser,
  hasPrime,
  prime,
  products,
  quantities,
  taxRate,
}) => {
  const classes = useStyles()

  const isPrime =
    prime.enabled &&
    prime.storePurchaseDiscount > 0 &&
    (hasPrime || currentUser.hasPrime)

  const { items, invoice } = useGetInvoice(
    products,
    quantities,
    taxRate,
    isPrime ? prime.storePurchaseDiscount : 0
  )
  const [showAddRow, setShowAddRow] = useState(false)
  const [newItem, setNewItem] = useState({})

  const handleAddItemClick = () => {
    actions.add(newItem)
    handleAddRowClick()
  }

  const handleAddRowClick = () => {
    setShowAddRow(!showAddRow)
    setNewItem({})
  }

  const handleDeleteProduct = (productId) => actions.delete(productId)

  const handleQtyUpdate = (event) =>
    actions.update(event.target.name, event.target.value)

  useEffect(() => {
    if (actions && actions.hasOwnProperty('setInvoice'))
      actions.setInvoice(invoice)
  }, [products, quantities, taxRate])

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center' colSpan={3}>
              Purchase Details
            </TableCell>
            <TableCell align='right'>Cost</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Items</TableCell>
            <TableCell align='right'>Quantity</TableCell>
            <TableCell align='right'>Item Cost</TableCell>
            <TableCell align='right'>Total Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length > 0 ? (
            items.map((row) => (
              <TableRow key={row.desc}>
                <TableCell className={classes.tableCellImg}>
                  <img height='50px' src={convertGoogleLink(row.imgUrl)} />
                  {row.desc}
                </TableCell>
                <TableCell align='right'>
                  {actions ? (
                    <>
                      <ActionButton
                        action='Remove from cart'
                        onClick={() => handleDeleteProduct(row._id)}
                      >
                        <Delete />
                      </ActionButton>
                      <Select
                        name={row._id}
                        onChange={handleQtyUpdate}
                        value={row.qty}
                      >
                        {getOptions(
                          (row.onHand < row.max && row.onHand > 0) ||
                            row.max === 0
                            ? row.onHand
                            : row.max
                        )}
                      </Select>
                    </>
                  ) : (
                    row.qty
                  )}
                </TableCell>
                <TableCell align='right'>
                  <Points value={row.unit} />
                </TableCell>
                <TableCell align='right'>
                  <Points value={row.price} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} className={classes.emptyCart}>
                Your cart is empty
              </TableCell>
            </TableRow>
          )}

          {showAddRow && (
            <TableRow>
              <TableCell colSpan={3}>
                <ProductSelector
                  allowAny={false}
                  onChange={(product) =>
                    product ? setNewItem(product) : setNewItem({})
                  }
                />
              </TableCell>
              <TableCell align='right'>
                <ActionButton
                  action='Add to cart'
                  disabled={!newItem.hasOwnProperty('name')}
                  onClick={handleAddItemClick}
                >
                  <Check />
                </ActionButton>
                <ActionButton action='Cancel' onClick={handleAddRowClick}>
                  <Close />
                </ActionButton>
              </TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell rowSpan={isPrime ? 4 : 3}>
              {actions && actions.hasOwnProperty('add') && (
                <Button
                  color='primary'
                  disabled={showAddRow}
                  onClick={handleAddRowClick}
                  size='small'
                  variant='outlined'
                >
                  <Add className={classes.icon} />
                  Add item
                </Button>
              )}
            </TableCell>
            <TableCell colSpan={2}>Subtotal</TableCell>
            <TableCell align='right'>
              <Points value={invoice.subtotal} />
            </TableCell>
          </TableRow>
          {isPrime && (
            <TableRow>
              <TableCell>
                Prime Discount
                <PrimeBadge />
              </TableCell>
              <TableCell align='right'>{`${prime.storePurchaseDiscount}%`}</TableCell>
              <TableCell align='right'>
                <Points value={-invoice.prime} />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Taxes & Fees</TableCell>
            <TableCell align='right'>{`${taxRate}%`}</TableCell>
            <TableCell align='right'>
              <Points prefix='+' value={invoice.taxes} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell align='right'>
              <Points value={invoice.total} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default connect(mapStateToProps, {})(PurchaseDetailsList)
