import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.info.main,
    borderRadius: '100px',
    color: theme.palette.common.white,
    cursor: 'pointer',
    fontFamily: '"Carter One", serif',
    fontSize: theme.typography.h5.fontSize,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}))

const BigButton = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root} {...props}>
      {props.children}
    </div>
  )
}

export default BigButton
