import {
  ADD_BONUS,
  DELETE_BONUS,
  LOAD_BONUSES,
  UNLOAD_BONUSES,
  UPDATE_BONUS,
} from '../constants/actionTypes'

const defaultState = {
  bonuses: [],
  errors: null,
}

const sortByDescription = (a, b) => a.description.localeCompare(b.description)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_BONUS:
      return {
        ...state,
        bonuses: action.error
          ? state.bonuses
          : [...state.bonuses, action.payload.bonus].sort((a, b) =>
              sortByDescription(a, b)
            ),
        errors: action.error ? action.payload.errors : null,
      }
    case DELETE_BONUS:
      return {
        ...state,
        bonuses: action.error
          ? state.bonuses
          : state.bonuses.filter(
              (bonus) => bonus._id !== action.payload.bonus._id
            ),
        errors: action.error ? action.payload.errors : null,
      }
    case LOAD_BONUSES:
      return {
        ...state,
        bonuses: action.error ? state.bonuses : action.payload.bonuses,
        errors: action.error ? action.payload.errors : null,
      }
    case UNLOAD_BONUSES:
      return defaultState
    case UPDATE_BONUS:
      return {
        ...state,
        bonuses: action.error
          ? state.bonuses
          : state.bonuses
              .map((bonus) =>
                bonus._id === action.payload.bonus._id
                  ? action.payload.bonus
                  : bonus
              )
              .sort((a, b) => sortByDescription(a, b)),
        errors: action.error ? action.payload.errors : null,
      }
    default:
      return state
  }
}
