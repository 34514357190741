import React from 'react'
import { TextField } from '@material-ui/core'

const TextFieldLimit = (props) => {
  return (
    <TextField
      error={Boolean(props.errors)}
      inputProps={{ maxLength: props.limit }}
      helperText={
        props.errors
          ? `${props.errors} | ${props.value.length}/${props.limit}`
          : `${props.value.length}/${props.limit}`
      }
      {...props}
    />
  )
}

export default TextFieldLimit
