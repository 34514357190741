import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MaterialTable from 'material-table'
import moment from 'moment'
import { titleCase } from 'title-case'
import {
  Avatar,
  Chip,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { AlarmOn, AlarmOff, ExpandMore } from '@material-ui/icons'

import PrimeBadge from '../Prime/PrimeBadge'

import { DELETE_PASS, UPDATE_PASS } from '../../constants/actionTypes'
import agent from '../../agent'

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  expired: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const mapStateToProps = (state) => ({
  // ...state,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (id) =>
    dispatch({
      type: DELETE_PASS,
      payload: agent.Passes.delete(id),
      snackbar: { message: 'Hallpass deleted', variant: 'success' },
    }),
  onUpdate: (pass) =>
    dispatch({
      type: UPDATE_PASS,
      payload: agent.Passes.update(pass),
      snackbar: { message: 'Hallpass updated', variant: 'success' },
    }),
})

const PassTable = ({
  courses,
  expanded,
  icon,
  onChange,
  onDelete,
  onUpdate,
  status,
}) => {
  const classes = useStyles()

  const [data, setData] = useState([])

  const mappedData = courses
    .map((course) =>
      course.passes.map((pass) => ({
        createdAt: moment(pass.createdAt),
        createdAtString: pass.createdAt,
        createdBy: pass.createdBy,
        destination: pass.destination,
        duration: pass.duration,
        hasPrime: pass.student.hasPrime,
        period: course.period,
        status:
          moment.duration(moment().diff(moment(pass.createdAt))).as('minutes') >
          pass.duration
            ? 'expired'
            : 'active',
        student: `${pass.student.firstName} ${pass.student.lastName} ${
          pass.student.dropped ? '(dropped)' : ''
        }`,
        _id: pass._id,
      }))
    )
    .flat()

  const [columns] = useState([
    {
      title: 'Date',
      field: 'createdAt',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row'
          ? rowData.createdAt.format('ddd, MMM D, YYYY')
          : rowData.format('ddd, MMM D, YYYY'),
    },
    {
      title: 'Time',
      field: 'createdAt',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row'
          ? rowData.createdAt.format('h:mm A')
          : rowData.format('h:mm A'),
    },
    { title: 'Student', field: 'student', editable: 'never' },
    {
      title: 'Prime',
      field: 'hasPrime',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          rowData.hasPrime ? (
            <PrimeBadge />
          ) : (
            ''
          )
        ) : rowData ? (
          <PrimeBadge />
        ) : (
          'No Prime'
        ),
    },
    {
      title: 'Period',
      field: 'period',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <Chip label={rowData.period} color='secondary' />
        ) : (
          <Chip label={rowData} color='secondary' />
        ),
    },
    { title: 'Destination', field: 'destination' },
    { title: 'Duration (min)', field: 'duration' },
    { title: 'Created by', field: 'createdBy' },
  ])

  useEffect(() => {
    setData(mappedData)
  }, [courses])

  return (
    <ExpansionPanel expanded={expanded === status} onChange={onChange(status)}>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <div className={classes.summary}>
          <Avatar className={classes[status]}>{icon}</Avatar>
          <Typography variant='h5'>{titleCase(status)} Hallpasses</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <MaterialTable
          columns={columns}
          data={data.filter((pass) => pass.status === status)}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                const dataDelete = [...data]
                const index = oldData.tableData.id
                dataDelete.splice(index, 1)
                setData([...dataDelete])
                onDelete(oldData._id)
                resolve()
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const dataUpdate = [...data]
                const index = oldData.tableData.id
                dataUpdate[index] = {
                  ...newData,
                  createdAt: moment(newData.createdAtString),
                }
                setData([...dataUpdate])
                onUpdate(newData)
                resolve()
              }),
          }}
          localization={{
            body: {
              emptyDataSourceMessage:
                status === 'active'
                  ? 'No active passes at this time'
                  : 'No hallpasses have been created',
              editRow: {
                deleteText: `Delete ${status} hallpass?`,
              },
            },
          }}
          options={{
            actionsColumnIndex: -1,
            grouping: status !== 'active',
            paging: false,
            showTitle: false,
            toolbar: status !== 'active',
          }}
          style={{
            boxShadow: 'none',
            width: '100%',
          }}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const ManagePassesTable = ({ courses, onDelete, onUpdate }) => {
  const [expanded, setExpanded] = useState('active')

  const handleExpandedChange = (panel) => (ev, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      <PassTable
        courses={courses}
        expanded={expanded}
        icon={<AlarmOn />}
        onChange={handleExpandedChange}
        onDelete={onDelete}
        onUpdate={onUpdate}
        status='active'
      />
      <PassTable
        courses={courses}
        expanded={expanded}
        icon={<AlarmOff />}
        onChange={handleExpandedChange}
        onDelete={onDelete}
        onUpdate={onUpdate}
        status='expired'
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePassesTable)
