import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch } from 'react-router-dom'

import App from './components/App'
import { store, history } from './store'
import { AudioProvider } from './contexts/AudioContext'

ReactDOM.render(
  <Provider store={store}>
    <AudioProvider>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path='/' component={App} />
        </Switch>
      </ConnectedRouter>
    </AudioProvider>
  </Provider>,

  document.getElementById('root')
)
