import React from 'react'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: theme.palette.text.disabled,
  },
}))

const ActionButton = ({
  action,
  children,
  className,
  disabled,
  onClick,
  size,
}) => {
  const classes = useStyles()

  const handleClick = (ev) => {
    ev.stopPropagation()
    onClick()
  }

  return (
    <Tooltip arrow enterTouchDelay={0} title={action}>
      <span>
        <IconButton
          aria-label={action}
          className={`${classes.actionButton} ${className || ''}`}
          disabled={disabled}
          onClick={handleClick}
          onFocus={(ev) => ev.stopPropagation()}
          size={size}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default ActionButton
