import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'

import { convertGoogleLink } from '../converter'

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'absolute',
    top: '-18px',
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
    minWidth: '20px',
  },
  window: {
    alignItems: 'center',
    display: 'flex',
    borderColor: theme.palette.secondary.main,
    borderStyle: 'solid',
    borderWidth: '4px',
    height: '300px',
    justifyContent: 'center',
    padding: theme.spacing(2),
    position: 'relative',
  },
}))

const ImagePreview = ({ src }) => {
  const classes = useStyles()

  return (
    <div className={classes.window}>
      <Typography variant='h6' className={classes.header}>
        Image Preview
      </Typography>
      {src ? (
        <img className={classes.img} src={convertGoogleLink(src)} />
      ) : (
        <Typography>No photo url provided</Typography>
      )}
    </div>
  )
}

export default ImagePreview
