import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { LockOpen } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import agent from '../../agent'

import { RESET_STUDENT_PASSWORD } from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  defaultStudentPassword: state.common.settings.defaultStudentPassword,
})

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (user) =>
    dispatch({
      type: RESET_STUDENT_PASSWORD,
      payload: agent.Students.update(user),
      snackbar: { message: 'Password reset', variant: 'success' },
    }),
})

const ResetStudentPasswordAlert = ({
  btnSize,
  defaultStudentPassword,
  onSubmit,
  student,
}) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    const user = { ...student, password: defaultStudentPassword }
    onSubmit(user)
    setOpen(false)
  }

  return (
    <>
      <ActionButton
        action='Reset password'
        onClick={handleClickOpen}
        size={btnSize ?? 'small'}
      >
        <LockOpen fontSize='inherit' />
      </ActionButton>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(ev) => ev.stopPropagation()}
        onFocus={(ev) => ev.stopPropagation()}
      >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to reset{' '}
            <b>
              {student.firstName} {student.lastName}
            </b>
            's password to <strong>{defaultStudentPassword}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            autoFocus
          >
            Reset
          </Button>
          <Button onClick={handleClose} variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetStudentPasswordAlert)
