import {
  ADD_PURCHASE,
  ADD_PURCHASE_DIALOG_UNLOADED,
  ADD_TO_CART,
  ASYNC_START,
  DELETE_CART_PRODUCT,
  DELETE_PURCHASE,
  PRIME_PURCHASE_DIALOG_UNLOADED,
  PURCHASES_DIALOG_UNLOADED,
  STUDENT_DELETE_PURCHASE,
  STUDENT_PURCHASES_LOADED,
  STUDENT_PURCHASES_UNLOADED,
  SUBMIT_PURCHASE,
  SUBMIT_PRIME_PURCHASE,
  TEACHER_PURCHASES_LOADED,
  TEACHER_PURCHASES_UNLOADED,
  UPDATE_CART_PRODUCT_QUANTITY,
  UPDATE_PURCHASE,
} from '../constants/actionTypes'

const defaultState = {
  cart: { products: [], quantities: {} },
  courses: [],
  errors: null,
  inProgress: false,
  isLoaded: false,
  purchases: [],
}

export default (state = defaultState, action) => {
  let courses = []
  switch (action.type) {
    case ADD_PURCHASE:
    case SUBMIT_PURCHASE:
    case SUBMIT_PRIME_PURCHASE:
      return {
        ...state,
        cart: action.error ? state.cart : defaultState.cart,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case ADD_PURCHASE_DIALOG_UNLOADED:
    case PRIME_PURCHASE_DIALOG_UNLOADED:
      return defaultState
    case ADD_TO_CART:
      const productIndex = state.cart.products.findIndex(
        (product) => product._id === action.product._id
      )
      const isAdded = productIndex > -1
      const updatedQuantity = isAdded
        ? state.cart.quantities[action.product._id] + 1
        : 1
      const allowed = action.product.maxPurchaseQuantity
      const onHand = action.product.quantity
      const max = allowed !== 0 ? Math.min(allowed, onHand) : onHand
      return {
        ...state,
        cart: {
          products: isAdded
            ? state.cart.products
            : [...state.cart.products, action.product],
          quantities: {
            ...state.cart.quantities,
            [action.product._id]:
              updatedQuantity <= max ? updatedQuantity : max,
          },
        },
      }
    case ASYNC_START:
      if (
        action.subtype === STUDENT_PURCHASES_LOADED ||
        action.subtype === TEACHER_PURCHASES_LOADED ||
        action.subtype === SUBMIT_PURCHASE ||
        action.subtype === SUBMIT_PRIME_PURCHASE
      ) {
        return { ...state, errors: null, inProgress: true }
      }
      break
    case DELETE_CART_PRODUCT:
      const { [action.productId]: value, ...withoutDeleted } =
        state.cart.quantities
      return {
        ...state,
        cart: {
          products: state.cart.products.filter(
            (product) => product._id !== action.productId
          ),
          quantities: withoutDeleted,
        },
      }
    case DELETE_PURCHASE:
      // Changing a deep-nested array requires deep-copying
      // to avoid altering the previous state
      state.courses.forEach((course) => {
        const courseCopy = { ...course }
        courseCopy.purchases = courseCopy.purchases.filter(
          (purchase) =>
            purchase._id.toString() !== action.payload.purchase._id.toString()
        )
        courses.push(courseCopy)
      })
      return {
        ...state,
        courses: action.error ? state.courses : courses,
      }
    case PURCHASES_DIALOG_UNLOADED:
      return { ...state, errors: null }
    case STUDENT_DELETE_PURCHASE:
      return {
        ...state,
        purchases: action.error
          ? state.purchases
          : state.purchases.filter(
              (purchase) => purchase._id !== action.payload.purchase._id
            ),
      }
    case STUDENT_PURCHASES_LOADED:
      return {
        ...state,
        purchases: action.error ? state.purchases : action.payload.purchases,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case STUDENT_PURCHASES_UNLOADED:
      return { ...defaultState, cart: state.cart }
    case TEACHER_PURCHASES_LOADED:
      return {
        ...state,
        courses: action.error ? state.courses : action.payload.courses,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        isLoaded: true,
      }
    case TEACHER_PURCHASES_UNLOADED:
      return { ...defaultState }
    case UPDATE_CART_PRODUCT_QUANTITY:
      return {
        ...state,
        cart: {
          ...state.cart,
          quantities: {
            ...state.cart.quantities,
            [action.productId]: action.qty,
          },
        },
      }
    case UPDATE_PURCHASE:
      // Changing a deep-nested array requires deep-copying
      // to avoid altering the previous state
      !action.error &&
        state.courses.forEach((course) => {
          const courseCopy = { ...course }
          courseCopy.purchases = courseCopy.purchases.map((purchase) =>
            purchase._id.toString() === action.payload.purchase._id.toString()
              ? action.payload.purchase
              : purchase
          )
          courses.push(courseCopy)
        })
      return {
        ...state,
        courses: action.error ? state.courses : courses,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    default:
      return state
  }

  return state
}
