import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import ListErrors from '../ListErrors'
import agent from '../../agent'

import {
  ADD_COURSE,
  COURSE_DIALOG_UNLOADED,
  UPDATE_COURSE,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  ...state.courses,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (course) =>
    dispatch({
      type: ADD_COURSE,
      payload: agent.Courses.add(course),
      snackbar: { message: 'Course added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: COURSE_DIALOG_UNLOADED }),
  onUpdate: (course) =>
    dispatch({
      type: UPDATE_COURSE,
      payload: agent.Courses.update(course),
      snackbar: { message: 'Course updated', variant: 'success' },
    }),
})

const CourseDialog = ({
  course,
  errors,
  inProgress,
  onAdd,
  onExit,
  onUpdate,
}) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values })
  }

  const handleSubmitUpdate = (values) => {
    onUpdate({ ...course, ...values })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      {course ? (
        <ActionButton action='Edit course' onClick={handleClickOpen}>
          <Edit />
        </ActionButton>
      ) : (
        <Fab color='primary' onClick={handleClickOpen} variant='extended'>
          <Add className={classes.icon} />
          Create course
        </Fab>
      )}

      <Dialog
        fullScreen={fullScreen}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <Formik
          initialValues={{
            name: course?.name || '',
            period: course?.period || '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            period: Yup.number().typeError('Numbers only').required('Required'),
          })}
          onSubmit={async (values) => {
            course ? handleSubmitUpdate(values) : handleSubmitAdd(values)
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>
                {course ? 'Edit Course' : 'Create Course'}
              </DialogTitle>
              <DialogContent>
                <ListErrors errors={errors} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      autoFocus={course ? false : true}
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Course Name'
                      name='name'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Period'
                      name='period'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={inProgress || !(formik.isValid && formik.dirty)}
                  color='primary'
                  type='submit'
                  variant='contained'
                >
                  {course
                    ? inProgress
                      ? 'Updating...'
                      : 'Update'
                    : inProgress
                    ? 'Adding...'
                    : 'Add'}
                </Button>
                <Button
                  disabled={inProgress}
                  onClick={handleClose}
                  variant='contained'
                >
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDialog)
